import moment from 'moment';

export const enumerateDaysBetweenDates = (startDate, endDate) => {
  let dates = [];

  let currDate = moment(startDate).startOf('day');
  let lastDate = moment(endDate).startOf('day');

  while (currDate.diff(lastDate) <= 0) {
    dates.push(currDate.clone().format('YYYY-MM-DD'));
    currDate.add(1, 'days');
  }

  return dates;
};
