import React, { useState } from 'react';
import Filter from 'components/Filter/Filter';
import { useLocation, useNavigate } from 'react-router-dom';
import DefaultFooter from 'navigatiors/DefaultFooter/DefaultFooter';
import DefaultHeader from 'navigatiors/DefaultHeader/DefaultHeader';
import { useCheckAuthentication } from 'Hooks/useCheckAuthentication';
import Content from 'navigatiors/Content';

import './style.scss';

export default function DefaultLayout() {
  const navigate = useNavigate();
  const path = useLocation().pathname;
  const [filterActive, setFilterActive] = useState(false);
  const ignoreFilterPath = ['/help', '/calendar'];
  const isAuthenticated = useCheckAuthentication();
  if (!isAuthenticated) {
    navigate('/login', { replace: true });
  }
  return (
    <div className="app-layout">
      <div className="app-header">
        <DefaultHeader />
      </div>
      {ignoreFilterPath.includes(path) ? (
        <></>
      ) : (
        <Filter filterActive={filterActive} setFilterActive={setFilterActive} />
      )}

      <div
        className={`app-body ${ignoreFilterPath.includes(path) ? 'ignore-filter' : ''} ${
          filterActive ? 'filter-active' : ''
        }`}
      >
        <Content />
      </div>
      <footer className="app-footer">
        <DefaultFooter />
      </footer>
    </div>
  );
}
