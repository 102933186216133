import CustomTooltip from 'components/CustomTooltip/CustomTooltip';
import { useClickOutside } from 'helper/useClickOutSide';
import React, { useRef, useState } from 'react';
import StatusTable from './StatusTable';
const Status = ({ check, dataDefault, employeeId, type, available, level, isActive }) => {
  const [activeTooltip, setActiveTooltip] = useState(false);
  const statusTableRef = useRef(null);
  useClickOutside(statusTableRef, () => {
    if (activeTooltip) setActiveTooltip(false);
  });
  const data = dataDefault?.filter((shift) => +shift.employeeId === +employeeId);
  return (
    <td ref={statusTableRef}>
      <div
        className="status__wrap"
        onClick={() => {
          setActiveTooltip(!activeTooltip);
        }}
      >
        <div
          className={`status ${level === 'survey' && !isActive ? 'not-active' : ''} ${
            check ? `${level === 'hard' ? 'wrong' : 'warning'}` : ''
          }`}
        >
          {check && activeTooltip && data.length ? (
            <CustomTooltip>
              <StatusTable statusData={data} type={type} />
            </CustomTooltip>
          ) : (
            <></>
          )}
        </div>
      </div>
    </td>
  );
};

export default Status;
