import { CloseOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import React, { useState } from 'react';
import CalendarPlanRoleRow from '../CalendarPlanRole/CalendarPlanRoleRow';
import CalendarPlanRoleRowNew from '../CalendarPlanRole/CalendarPlanRoleRowNew';
import { CalendarPlanGroupRow } from './CalendarPlanGroupRow';
import CalendarPlanGroupRowNew from './CalendarPlanGroupRowNew';

const RenderGroupRow = ({ groupList, isUpdate, setIsUpdate }) => {
  return groupList.map((group) => {
    return (
      <CalendarPlanGroupRow
        key={group.id}
        defaultGroup={group}
        setIsUpdate={setIsUpdate}
        isUpdate={isUpdate}
      />
    );
  });
};

const CalendarPlanGroup = ({ groupList, isUpdate, setIsUpdate, setActive, unitSelected }) => {
  const [isCreate, setIsCreate] = useState(false);

  return (
    <div className="calendar-plan-create-role__wrap">
      <div className="calendar-plan-create-role__content">
        <div className="calendar-plan-create-role__header">
          <h4>{t('group-management')}</h4>
          <CloseOutlined
            onClick={() => {
              setActive(false);
            }}
          />
        </div>
        <div className="calendar-plan-create-role__list">
          <table className="calendar-plan-create-role__table">
            <thead>
              <th className="calendar-plan-create-role__item-label">{t('name')}</th>
              <th className="calendar-plan-create-role__item-label">{t('key')}</th>
              <th className="calendar-plan-create-role__item-label">{t('description')}</th>
              <th className="calendar-plan-create-role__item-label">{t('action')}</th>
            </thead>
            <tbody>
              <RenderGroupRow groupList={groupList} isUpdate={isUpdate} setIsUpdate={setIsUpdate} />
              {isCreate ? (
                <CalendarPlanGroupRowNew
                  setActive={setIsCreate}
                  setIsUpdate={setIsUpdate}
                  isUpdate={isUpdate}
                  unitSelected={unitSelected}
                />
              ) : null}
            </tbody>
          </table>
        </div>
        <div className="calendar-plan-create-role__action">
          <button
            className="custom-btn square-btn"
            onClick={() => {
              setIsCreate(true);
            }}
          >
            {t('create-new-group')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CalendarPlanGroup;
