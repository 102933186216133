import { CheckOutlined, EditOutlined, RollbackOutlined } from '@ant-design/icons';
import axiosClient from 'axios/axiosClient';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

const RenderIntro = ({ language, introRequest, setIntroRequest, employeeId }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [intro, setIntro] = useState({ surveyIntro: '', surveyIntroNo: '' });
  const introData = useQuery(`${employeeId}-introData-genneral`, () =>
    axiosClient.post('calendar-plan/survey-intro', {}).then((res) => res),
  );

  const empIntroData = useQuery(`${employeeId}-introData`, () =>
    axiosClient.post('calendar-plan/survey-intro-employee', { employeeId }).then((res) => res),
  );
  useEffect(() => {
    if (empIntroData.data && introData.data) {
      const { content, content_no, id } = empIntroData.data;
      if (id) {
        setIntro({ surveyIntro: content, surveyIntroNo: content_no, id });
        setIntroRequest({ surveyIntro: content, surveyIntroNo: content_no, id });
      } else {
        const { content, content_no, id } = introData.data;
        setIntro({ surveyIntro: content, surveyIntroNo: content_no, id });
        setIntroRequest({ surveyIntro: content, surveyIntroNo: content_no, id });
      }
    }
  }, [introData.data, empIntroData.data]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setIntro({ ...intro, [name]: value });
    setIntroRequest({ ...introRequest, [name]: value });
  };

  if (intro) {
    const introContent =
      language === 'NO'
        ? intro.surveyIntroNo?.replace(/\\n/g, '\n')
        : intro.surveyIntro?.replace(/\\n/g, '\n');
    const introRow = introContent?.split('\n');
    return (
      <div className="intro__wrap">
        <div className="intro__action">
          <div
            className="intro-roll-back__wrap"
            title="Roll back to general intro"
            onClick={() => {
              setIsEditing(false);
              const { content, content_no, id } = introData.data;
              setIntro({ surveyIntro: content, surveyIntroNo: content_no, id });
              setIntroRequest({ surveyIntro: content, surveyIntroNo: content_no, id });
            }}
          >
            <RollbackOutlined />
          </div>
          <div className="intro-edit__wrap" onClick={() => setIsEditing(!isEditing)}>
            {isEditing ? <CheckOutlined /> : <EditOutlined />}
          </div>
        </div>

        {!isEditing ? (
          introRow?.map((intro) => {
            return <p key={intro}>{intro}</p>;
          })
        ) : (
          <div className="intro-edit__group">
            <div className="intro-edit__item">
              <label htmlFor="">En-ver</label>
              <textarea
                value={intro.surveyIntro}
                name="surveyIntro"
                id=""
                cols="30"
                rows="10"
                onChange={handleOnChange}
              ></textarea>
            </div>
            <div className="intro-edit__item">
              <label htmlFor="">No-ver</label>
              <textarea
                value={intro.surveyIntroNo}
                name="surveyIntroNo"
                id=""
                cols="30"
                rows="10"
                onChange={handleOnChange}
              ></textarea>
            </div>
          </div>
        )}
      </div>
    );
  }
  return <></>;
};

export default RenderIntro;
