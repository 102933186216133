import { useClickOutside } from 'helper/useClickOutSide';
import React, { useCallback, useRef } from 'react';
import './AddTask.scss';

export default function AddTask({ taskList, handleAddTask, listGroup }) {
  const renderTask = (arr) => {
    return arr.map((task) => {
      const groupFound = listGroup.find((group) => group.id === task.group_id);
      if (groupFound) task['group'] = groupFound.name;
      return (
        <tr
          className="add-task__item-row"
          key={task.uuid}
          onClick={useCallback(() => {
            handleAddTask(task);
          }, [])}
        >
          <td>
            <div className="add-task__item">{task.task_name}</div>
          </td>
          <td>
            <div className="add-task__item">{task.role}</div>
          </td>
          <td>
            <div className="add-task__item">{task.group}</div>
          </td>
          <td>
            <div className="add-task__item">{task.description}</div>
          </td>
        </tr>
      );
    });
  };

  return (
    <div className="add-task__wrap" id="addTaskWrap">
      <table className="add-task__table">
        <thead>
          <th>Task Name</th>
          <th>Role</th>
          <th>Group</th>
          <th>Description</th>
        </thead>
        <tbody>{renderTask(taskList)}</tbody>
      </table>
    </div>
  );
}
