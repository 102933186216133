import SwitchButton from 'components/SwitchButton/SwitchButton';
import { t } from 'i18next';
import React, { useState } from 'react';
import PlanListContent from './Plan/PlanContent/PlanListContent';
import TaskListContent from './Task/TaskContent/TaskListContent';
import './CalendarStaff.scss';
export default function CalendarStaff({ unitSelected }) {
  const [activeContent, setActiveContent] = useState('plan');

  const planListObj = {
    name: `${t('staff-plan-list')}`,
    value: 'plan',
  };
  const taskListObj = {
    name: `${t('staff-task-list')}`,
    value: 'task',
  };
  const renderContent = () => {
    switch (activeContent) {
      case 'plan':
        return <PlanListContent unitSelected={unitSelected} />;
      case 'task':
        return <TaskListContent unitSelected={unitSelected} />;

      default:
        return <PlanListContent unitSelected={unitSelected} />;
    }
  };
  return (
    <div className="calendar-staff__wrap">
      <SwitchButton
        obj1={planListObj}
        obj2={taskListObj}
        value={activeContent}
        setValue={setActiveContent}
      />
      <div className="calendar-staff__content">{renderContent()}</div>
    </div>
  );
}
