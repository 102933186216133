import { CloseOutlined } from '@ant-design/icons';
import axiosClient from 'axios/axiosClient';
import DatePickerCustom from 'components/DatePicker/DatePickerCustom';
import { Toast } from 'helper/alert';
import { t } from 'i18next';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectGlobal } from 'redux/global/globalSlice';
import './CalendarPlanSurveyForm.scss';
import Loading from 'components/Loading/Loading';
import RenderIntro from './ItemInSurvey/RenderIntro';
import CommentBox from './ItemInSurvey/CommentBox';
import ExpanedQuestion from './ItemInSurvey/ExpanedQuestion';
import QuestionItem from './ItemInSurvey/QuestionItem';

const SurveyForm = ({
  listBlockedQuestion,
  listExpandedQuestionActive,
  listTopic,
  listQuestionRender,
  language,
  surveyRecord,
  setSurveyRecord,
  handleOnChange,
  emp,
  setListBlockedQuestion,
  listHardRuleQuestion,
  setListHardRuleQuestion,
}) => {
  let activeTopic = listTopic?.filter((topic) => topic.topic !== 'Extended question');

  return activeTopic.map((topic) => {
    let i = 0;
    return (
      <div key={topic.uuid} className="topic__wrap">
        <h2 className="topic-title">#{language === 'NO' ? topic.no_ver : topic.topic}</h2>
        <div className="topic-group__question">
          {listQuestionRender.map((parentQuestion, index) => {
            if (parentQuestion.parent_question_topic_id === topic.uuid) {
              i++;
              return (
                <QuestionItem
                  key={parentQuestion.parent_question_id}
                  parentQuestionData={parentQuestion}
                  language={language}
                  index={i}
                  surveyRecord={surveyRecord}
                  setSurveyRecord={setSurveyRecord}
                  handleOnChange={handleOnChange}
                  emp={emp}
                  listBlockedQuestion={listBlockedQuestion}
                  setListBlockedQuestion={setListBlockedQuestion}
                  listExpandedQuestionActive={listExpandedQuestionActive}
                  listHardRuleQuestion={listHardRuleQuestion}
                  setListHardRuleQuestion={setListHardRuleQuestion}
                />
              );
            }
          })}
        </div>
      </div>
    );
  });
};
export default function CalendarPlanSurveyForm({
  listQuestion,
  emp,
  setActive,
  page,
  setEmployees,
  unitSelected,
}) {
  const [surveyRecord, setSurveyRecord] = useState({});
  const [listExtenedQuestion, setListExtendedQuestion] = useState([]);
  const [listQuestionRender, setListQuestionRender] = useState([]);
  const [listExpandedQuestionActive, setListExpandedQuestionActive] = useState([]);
  const [listHardRuleQuestion, setListHardRuleQuestion] = useState([]);
  const [listBlockedQuestion, setListBlockedQuestion] = useState([]);
  const [listTopic, setListTopic] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [empComment, setEmpComment] = useState('');
  const [intro, setIntro] = useState({ surveyIntro: '', surveyIntroNo: '' });
  const { language } = useSelector(selectGlobal);

  const handleOnChange = (e) => {
    if (e.target.type === 'checkbox') {
      if (surveyRecord[[e.target.name]]) {
        if (surveyRecord[[e.target.name]]?.includes(e.target.value)) {
          setSurveyRecord({
            ...surveyRecord,
            [e.target.name]: surveyRecord[[e.target.name]].filter(
              (item) => item !== e.target.value,
            ),
          });
        } else {
          setSurveyRecord({
            ...surveyRecord,
            [e.target.name]: [...surveyRecord[[e.target.name]], e.target.value],
          });
        }
      } else {
        setSurveyRecord({
          ...surveyRecord,
          [e.target.name]: [e.target.value],
        });
      }
    } else {
      setSurveyRecord({ ...surveyRecord, [e.target.name]: e.target.value });
    }
  };

  const handleSendMail = async () => {
    try {
      setIsLoading(true);
      await axiosClient
        .post('email/survey', { empIds: [emp.uuid] })
        .then((result) => {
          setActive(false);
          Toast.fire({ icon: 'success', title: `${t('send-survey')} ${t('success')}` });
        })
        .catch((error) => Toast.fire({ icon: 'fail', title: `${t('send-survey')} ${t('fail')}` }));
      setIsLoading(false);
    } catch (error) {
      Toast.fire({ icon: 'fail', title: `${t('send-survey')} ${t('fail')}` });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const questionId = Object.keys(surveyRecord);
    const empRecord = questionId.map((key) => {
      if (typeof surveyRecord[key] === 'string') {
        return {
          questionUUID: key,
          answerID: surveyRecord[key].id || '',
          empID: emp.id,
          empUUID: emp.uuid,
          answer: surveyRecord[key].en || surveyRecord[key],
          answer_no: surveyRecord[key].no || surveyRecord[key],
          type: 'text',
        };
      } else if (Array.isArray(surveyRecord[key])) {
        if (surveyRecord[key][0]?.type === 'date' || surveyRecord[key][0]?.type === 'time') {
          return {
            questionUUID: key,
            answerID: surveyRecord[key].id || '',
            empID: emp.id,
            empUUID: emp.uuid,
            answer: JSON.stringify(surveyRecord[key]) || surveyRecord[key],
            answer_no: JSON.stringify(surveyRecord[key]) || surveyRecord[key],
            type: surveyRecord[key][0]?.type || surveyRecord[key][1].type,
          };
        } else {
          return {
            questionUUID: key,
            answerID: surveyRecord[key].id || '',
            empID: emp.id,
            empUUID: emp.uuid,
            answer: surveyRecord[key].en || surveyRecord[key],
            answer_no: surveyRecord[key].no || surveyRecord[key],
            type: surveyRecord[key][0]?.type,
          };
        }
      } else if (surveyRecord[key].type === 'single-date') {
        return {
          questionUUID: key,
          answerID: surveyRecord[key].id || '',
          empID: emp.id,
          empUUID: emp.uuid,
          answer: JSON.stringify(surveyRecord[key]) || surveyRecord[key],
          answer_no: JSON.stringify(surveyRecord[key]) || surveyRecord[key],
          type: 'single-date',
        };
      } else if (surveyRecord[key].type === 'checkbox-text') {
        return {
          questionUUID: key,
          answerID: surveyRecord[key].id || '',
          empID: emp.id,
          empUUID: emp.uuid,
          answer: JSON.stringify(surveyRecord[key]) || surveyRecord[key],
          answer_no: JSON.stringify(surveyRecord[key]) || surveyRecord[key],
          type: 'checkbox-text',
        };
      } else if (surveyRecord[key].type === 'date-only') {
        return {
          questionUUID: key,
          answerID: surveyRecord[key].id || '',
          empID: emp.id,
          empUUID: emp.uuid,
          answer: JSON.stringify(surveyRecord[key]) || surveyRecord[key],
          answer_no: JSON.stringify(surveyRecord[key]) || surveyRecord[key],
          type: 'date-only',
        };
      } else if (surveyRecord[key].type === 'select-week') {
        return {
          questionUUID: key,
          answerID: surveyRecord[key].id || '',
          empID: emp.id,
          empUUID: emp.uuid,
          answer: JSON.stringify(surveyRecord[key]) || surveyRecord[key],
          answer_no: JSON.stringify(surveyRecord[key]) || surveyRecord[key],
          type: 'select-week',
        };
      } else {
        return {
          questionUUID: key,
          answerID: surveyRecord[key].id || '',
          empID: emp.id,
          empUUID: emp.uuid,
          answer: surveyRecord[key].en || surveyRecord[key],
          answer_no: surveyRecord[key].no || surveyRecord[key],
          type: surveyRecord[key]?.type,
        };
      }
    });
    Promise.all([
      axiosClient.post('calendar-plan/survey-intro-employee/update', {
        intro,
        employeeId: emp.uuid,
      }),
      axiosClient.post('calendar-plan/survey/comment/update', {
        employeeUUID: emp.uuid,
        commentUpdate: empComment,
      }),
      axiosClient.post('calendar-plan/survey/block-question/update', {
        listQuestionId: listBlockedQuestion,
        empId: emp.uuid,
      }),
      axiosClient.post('calendar-plan/survey/property/hard-rule/update', {
        employee: emp,
        listHardRuleQuestion,
      }),
      axiosClient.post('calendar-plan/survey-record/create', { empRecord }),
    ])
      .then((_) => {
        axiosClient
          .post('calendar-plan/survey/expaned-question/update', {
            listQuestion: listExpandedQuestionActive,
            empUUID: emp.uuid,
          })
          .then((_) => {
            axiosClient
              .post('calendar-plan/employee-pagination', { unitCode: unitSelected, page })
              .then((result) => {
                setEmployees(result);
              });
            Toast.fire({ icon: 'success', title: `${t('save')} ${t('survey')} ${t('success')}` });
          })
          .catch((err) => {
            Toast.fire({ icon: 'error', title: `${t('save')} ${t('survey')} ${t('unsuccess')}` });
          });
      })
      .catch((err) => {
        Toast.fire({ icon: 'error', title: `${t('save')} ${t('survey')} ${t('unsuccess')}` });
      });
  };

  useEffect(() => {
    setListExtendedQuestion(listQuestion?.filter((item) => item.type === 'expaned'));
    setListQuestionRender(listQuestion?.filter((item) => item.type !== 'expaned'));
  }, [listQuestion]);

  useEffect(() => {
    axiosClient.post('calendar-plan/survey/block-question', { empId: emp.uuid }).then((res) => {
      if (res) {
        setListBlockedQuestion(res);
      }
    });
    axiosClient
      .post('calendar-plan/survey/expaned-question-active', { empUUID: emp.uuid })
      .then((res) => {
        if (res) {
          setListExpandedQuestionActive(res);
        }
      });
    axiosClient
      .post('calendar-plan/survey/property/hard-rule', { employeeUUID: emp.uuid })
      .then((res) => {
        if (res) {
          setListHardRuleQuestion(res);
        }
      });
    axiosClient.post('calendar-plan/survey/default-value').then((res) => {
      if (res?.length) {
        setSurveyRecord((prev) => {
          let surveyRecordUpdate = { ...prev };

          for (const question of res) {
            if (
              !surveyRecordUpdate[question.questionUUID] ||
              !surveyRecordUpdate[question.questionUUID].length
            ) {
              surveyRecordUpdate[question.questionUUID] = question.listValue;
            }
          }
          return surveyRecordUpdate;
        });
      }
    });
  }, [emp.uuid]);

  useEffect(() => {
    axiosClient.post('calendar-plan/survey-record', { empID: emp.uuid }).then((result) => {
      if (result?.length) {
        const arr = {};
        result.forEach((record) => {
          if (record.type === 'multiple') {
            const enAnswer = record.answer.split(',');
            const noAnswer = record.answer_no.split(',');
            arr[record.question_uuid] = enAnswer.map((item, index) => ({
              en: item,
              no: noAnswer[index],
              id: record.answer_id,
              type: record.type,
            }));
          } else if (record.type === 'text') {
            arr[record.question_uuid] = record.answer;
          } else if (record.type === 'single-date') {
            arr[record.question_uuid] = JSON.parse(record.answer);
          } else if (record.type === 'checkbox-text') {
            arr[record.question_uuid] = JSON.parse(record.answer);
          } else if (record.type === 'date-only') {
            arr[record.question_uuid] = JSON.parse(record.answer);
          } else if (record.type === 'select-week') {
            arr[record.question_uuid] = JSON.parse(record.answer);
          } else if (record.type === 'date') {
            arr[record.question_uuid] = JSON.parse(record.answer);
            arr[record.question_uuid] = arr[record.question_uuid].map((item) => {
              return { ...item, fromDate: new Date(item.fromDate), toDate: new Date(item.toDate) };
            });
          } else if (record.type === 'time') {
            arr[record.question_uuid] = JSON.parse(record.answer);
            arr[record.question_uuid] = arr[record.question_uuid].map((item) => {
              return { ...item, time: item.time };
            });
          } else {
            arr[record.question_uuid] = {
              en: record.answer,
              no: record.answer_no,
              id: record.answer_id,
              type: record.type,
            };
          }
        });
        setSurveyRecord(arr);
      }
    });
    axiosClient.post('calendar-plan/survey-topic').then((result) => {
      setListTopic(result);
    });
  }, []);

  useEffect(() => {
    axiosClient.post('calendar-plan/survey/comment', { employeeUUID: emp.uuid }).then((res) => {
      if (res) {
        setEmpComment(res.comment);
      }
    });
  }, [emp.uuid]);

  return (
    <div className="calendar-plan-survey-form__wrap">
      <div className="calendar-plan-survey-form__header">
        <h1>
          {t('survey')} {t('form')}
        </h1>
        <CloseOutlined
          onClick={() => {
            setActive(false);
          }}
        />
      </div>
      <form action="" onSubmit={handleSubmit} className="calendar-plan-survey-form">
        <div className="survey-intro">
          <RenderIntro
            introRequest={intro}
            language={language}
            setIntroRequest={setIntro}
            employeeId={emp.uuid}
          />
        </div>

        {listQuestion.length ? (
          <SurveyForm
            emp={emp}
            handleOnChange={handleOnChange}
            language={language}
            listBlockedQuestion={listBlockedQuestion}
            listExpandedQuestionActive={listExpandedQuestionActive}
            listQuestionRender={listQuestionRender}
            listTopic={listTopic}
            setListBlockedQuestion={setListBlockedQuestion}
            setSurveyRecord={setSurveyRecord}
            surveyRecord={surveyRecord}
            listHardRuleQuestion={listHardRuleQuestion}
            setListHardRuleQuestion={setListHardRuleQuestion}
          />
        ) : (
          <></>
        )}

        <h2 className="topic-title">#{t('extended-question-management')}</h2>
        {listExtenedQuestion.map((questionData, index) => (
          <ExpanedQuestion
            key={questionData.key}
            parentQuestionData={questionData}
            index={index + 1}
            language={language}
            listExpandedQuestionActive={listExpandedQuestionActive}
            setListExpandedQuestionActive={setListExpandedQuestionActive}
            surveyRecord={surveyRecord}
            setSurveyRecord={setSurveyRecord}
            handleOnChange={handleOnChange}
            listHardRuleQuestion={listHardRuleQuestion}
            setListHardRuleQuestion={setListHardRuleQuestion}
            emp={emp}
          />
        ))}
        <CommentBox employee={emp} empComment={empComment} setEmpComment={setEmpComment} />
        <div className="calendar-plan-survey-form__submit">
          {isLoading && <Loading />}
          <button type="submit" className="custom-btn">
            {t('save')}
          </button>
          <button
            disabled={isLoading}
            type="button"
            className="custom-btn"
            onClick={handleSendMail}
          >
            {t('Send-to-email')}
          </button>
        </div>
      </form>
    </div>
  );
}
