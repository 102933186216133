import { CaretDownOutlined, CloseOutlined, FilterOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import React, { useState } from 'react';
import './CalendarFilter.scss';
import { useDispatch } from 'react-redux';
import { updatePlanDetailLockedAction } from 'redux/calendarPlan/calendarPlanAction';
export default function CalendarFilter({
  departments,
  departmentSelected,
  setDepartmentSelected,
  active,
  setActive,
  setUnitSelected,
  unitSelected,
}) {
  const [acitveUnit, setActiveUnit] = useState([]);
  const dispatch = useDispatch();
  const renderDepartment = (arr) => {
    return arr.map((item) => {
      return (
        <div key={item.departmentCode} className="calendar-filter__item__wrap">
          <div className="calendar-filter__item">
            <input
              type="radio"
              name="department"
              id={item.departmentCode}
              value={item.departmentCode}
              checked={departmentSelected === item.departmentCode}
              onChange={(e) => {
                setDepartmentSelected(e.target.value);
                setUnitSelected(item.unit[0]?.unitCode);
              }}
            />
            <label
              htmlFor={item.departmentCode}
              onClick={() => {
                if (acitveUnit.includes(item.departmentCode)) {
                  const activeUnitUpdated = acitveUnit.filter(
                    (activeUnitArr) => activeUnitArr !== item.departmentCode,
                  );
                  setActiveUnit(activeUnitUpdated);
                } else {
                  setActiveUnit([...acitveUnit, item.departmentCode]);
                }
              }}
            >
              <span>{item.departmentName}</span>
              {item.unit.length > 1 ? (
                <div className="drop-down active">
                  <CaretDownOutlined />
                </div>
              ) : null}
            </label>
          </div>
          {item.unit.length > 1 ? (
            <div
              className={`calendar-filter__item-unit ${
                acitveUnit.includes(item.departmentCode) ? 'active' : ''
              }`}
            >
              {item.unit.map((unit) => {
                return (
                  <div key={unit.unitCode} className="calendar-filter__item">
                    <input
                      type="radio"
                      name="unit"
                      id={unit.unitCode}
                      value={unit.unitCode}
                      checked={unitSelected === unit.unitCode}
                      onChange={(e) => {
                        setUnitSelected(unit.unitCode);
                        setDepartmentSelected(item.departmentCode);
                        dispatch(
                          updatePlanDetailLockedAction({
                            planDetailLockedList: [],
                            employeeIdLockedList: [],
                          }),
                        );
                      }}
                    />
                    <label htmlFor={unit.unitCode}>{unit.unitName}</label>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      );
    });
  };
  const renderActiveFilter = () => {
    return (
      <div className={`calendar-filter__wrap ${active ? 'active' : ''}`}>
        <div className="calendar-filter">
          <div className="filter-close">
            <CloseOutlined
              onClick={() => {
                setActive(false);
              }}
            />
          </div>
          <div className="calendar-filter__title">
            <FilterOutlined />
            <h3>{t('department')}</h3>
          </div>
          <div className="calendar-filter__group">{renderDepartment(departments)}</div>
        </div>
      </div>
    );
  };
  const renderFilter = () => {
    return (
      <div
        onClick={() => {
          setActive(true);
        }}
        className="calendar-filter__wrap"
      >
        <div className="calendar-filter">
          <FilterOutlined />
        </div>
      </div>
    );
  };
  return <div>{active ? renderActiveFilter() : renderFilter()}</div>;
}
