import React, { useEffect, useState } from 'react';
import './SoftRulesTab.scss';
import { useQuery } from 'react-query';
import axiosClient from 'axios/axiosClient';
import { useSelector } from 'react-redux';
import { selectGlobal } from 'redux/global/globalSlice';
import { Toast } from 'helper/alert';
import { t } from 'i18next';
import { isNumber } from 'lodash';

const RenderSoftRule = ({
  listSoftRule,
  selectedRule,
  setSelectedRule,
  language,
  percentWeekend,
  handleOnChangeInput,
}) => {
  return listSoftRule.map((softRule) => {
    const ruleFound = selectedRule.find((rule) => rule.property === softRule.property);
    const handleOnChange = (e) => {
      if (ruleFound) {
        const selectedRuleUpdate = selectedRule
          .filter((rule) => rule.property !== softRule.property)
          .map((item) => ({ ...item, value: true }));

        setSelectedRule(selectedRuleUpdate);
      } else {
        setSelectedRule([
          ...selectedRule,
          {
            ...softRule,
            value: softRule.property === 'maximum_working_weekend' ? +percentWeekend : true,
          },
        ]);
      }
    };
    return (
      <tr key={`soft-rules-${softRule.property}`}>
        <td>
          <label htmlFor={softRule.property}>
            {language === 'NO' ? softRule.no_content : softRule.en_content}
          </label>
          {softRule.property === 'maximum_working_weekend' ? (
            <input
              className="weekend-percent"
              name=""
              id=""
              value={percentWeekend}
              max={100}
              onChange={handleOnChangeInput}
            />
          ) : null}
        </td>
        <td>
          <input
            type="checkbox"
            name={softRule.property}
            id={softRule.property}
            checked={ruleFound}
            onChange={handleOnChange}
          />
        </td>
      </tr>
    );
  });
};

const SoftRulesTab = ({ unitSelected }) => {
  const { language } = useSelector(selectGlobal);
  const [listSoftRule, setListSoftRule] = useState([]);
  const [selectedRule, setSelectedRule] = useState([]);
  const [percentWeekend, setPercentWeekend] = useState();

  const listSoftRuleQuery = useQuery('softRuleList', () =>
    axiosClient.get('calendar-plan/soft-rules').then((res) => res),
  );
  const listSoftRuleRecordQuery = useQuery(['softRuleRecordList', unitSelected], async () =>
    axiosClient
      .post('calendar-plan/soft-rules-record', { unitCode: unitSelected })
      .then((res) => res),
  );
  const percentWeekendQuery = useQuery(['maximumWorkingWeekend', unitSelected], async () =>
    axiosClient
      .post('calendar-plan/soft-rules-record/maximum-weekend', { unitCode: unitSelected })
      .then((res) => res),
  );

  const handleSubmit = () => {
    const weekenđIndex = selectedRule.findIndex(
      (rule) => rule.property === 'maximum_working_weekend',
    );
    let selectedRuleUpdate = selectedRule;
    if (weekenđIndex > -1) {
      if (percentWeekend > 0 && !isNaN(percentWeekend)) {
        selectedRuleUpdate[weekenđIndex] = { ...selectedRule[weekenđIndex], value: percentWeekend };
      } else {
        selectedRuleUpdate.splice(weekenđIndex, 1);
      }
    }
    setSelectedRule(selectedRuleUpdate);

    axiosClient
      .post('calendar-plan/soft-rules', {
        selectedRule: selectedRuleUpdate,
        unitCode: unitSelected,
        percentWeekend,
      })
      .then((res) => {
        if (!res) {
          Toast.fire({ text: `${t('save')} ${t('unsuccess')}`, icon: 'error' });
          return;
        }
        Toast.fire({ text: `${t('save')} ${t('success')}`, icon: 'success' });
      })
      .catch((err) => {
        if (err) {
          Toast.fire({ text: `${t('save')} ${t('unsuccess')}`, icon: 'error' });
        }
      });
  };

  const handleOnChange = (e) => {
    const { value } = e.target;
    if (isNaN(+value)) {
      return;
    }
    if (+value > 100) {
      setPercentWeekend(100);
      return;
    }
    if (value.charAt(0) === '0' && value.length > 1) {
      e.target.value = value.substring(1);
      setPercentWeekend(+value.substring(1));
      return;
    }
    if (value.length === 0) {
      setPercentWeekend('');
    } else setPercentWeekend(+value);
  };

  useEffect(() => {
    if (percentWeekendQuery.data) {
      const ruleValue = percentWeekendQuery.data.value;
      if (ruleValue) {
        setPercentWeekend(+ruleValue);
      } else {
        setPercentWeekend('');
      }
    }
  }, [percentWeekendQuery.data, unitSelected]);

  useEffect(() => {
    if (listSoftRuleRecordQuery.data) {
      const selectdRuleId = listSoftRuleRecordQuery.data.map((rule) => rule.rule_id);
      const selected = listSoftRule.filter((rule) => selectdRuleId.includes(rule.id));

      for (let index in selected) {
        const value = listSoftRuleRecordQuery.data.find(
          (rule) => rule.rule_id === selected[index].id,
        )?.value;
        selected[index] = { ...selected[index], value };
      }
      setSelectedRule(selected);
    }
  }, [listSoftRuleRecordQuery.data, listSoftRule, unitSelected]);

  useEffect(() => {
    if (listSoftRuleQuery.data) {
      setListSoftRule(listSoftRuleQuery.data);
    }
  }, [listSoftRuleQuery.data, unitSelected]);
  return (
    <div className="soft-rules-tab__wrap">
      <div className="soft-rules-tab">
        <table className="soft-rules-table">
          <tbody>
            <RenderSoftRule
              language={language}
              listSoftRule={listSoftRule}
              selectedRule={selectedRule}
              setSelectedRule={setSelectedRule}
              percentWeekend={percentWeekend}
              handleOnChangeInput={handleOnChange}
            />
          </tbody>
        </table>
        <div className="soft-rules-submit">
          <button className="custom-btn" onClick={handleSubmit}>
            {t('save')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SoftRulesTab;
