import React, { memo, useEffect, useMemo, useState } from 'react';
import { read, readFile, utils, writeFile } from 'xlsx';
import * as moment from 'moment';

import './CalendarPlanWish.scss';
import { CloseOutlined } from '@ant-design/icons';
import { Toast } from 'helper/alert';
import axiosClient from 'axios/axiosClient';
import { t } from 'i18next';
import Loading from 'components/Loading/Loading';
import CalendarPlanWishListTable from '../CalendarPlanWishListTable/CalendarPlanWishListTable';

const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

const CalendarPlanWish = ({ setActive, unitSelected }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [fileName, setFileName] = useState(t('no-choose-file'));
  const [selectedFile, setSelectedFile] = useState(null);
  const [data, setData] = useState(null);
  const [dateRange, setDateRange] = useState([]);
  const [taskArr, setTaskArr] = useState([]);
  const [weekRange, setWeekRange] = useState([]);
  const [wishListInfo, setWishListInfo] = useState({});
  const [dateRangeData, setDateRangeData] = useState([]);

  const handleFileChange = (e) => {
    if (!wishListInfo.from || !wishListInfo.to || !wishListInfo.plan_name) {
      return;
    }
    try {
      const file = e.target.files[0];

      setSelectedFile(file);
    } catch (error) {
      Toast.fire({ icon: 'error', title: `${t('submit')} ${t('fail')}` });
    }
  };

  const readExcel = async (file, startDate, duration, isFilter) => {
    const fileReader = new FileReader();
    let dayCounter = 0;

    fileReader.onloadend = (event) => {
      const workbook = read(event.target.result, { type: 'binary' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const range = utils.decode_range(worksheet['!ref']);

      let data = [],
        count = 0,
        name = '',
        dates = [],
        rangeWeek = [],
        currDate = startDate.clone(),
        runDate = startDate.clone(),
        longDate = [],
        week = 1,
        year = 0,
        durationOfWeek = 1;

      if (duration === 0) {
        return;
      }

      for (let row = range.s.r; row <= range.e.r + 1; row++) {
        let i = data.length;

        let date = startDate.clone();
        let dateString = date.format('DD-MM-YYYY');
        data.push([]);
        for (let col = range.s.c; col <= range.e.c; col++) {
          let cell = worksheet[utils.encode_cell({ r: row, c: col })];
          if (!cell) count++;
          if (col > 2) {
            if (dayCounter++ < duration) {
              dates.push(date.format('YYYY-MM-DD'));
              longDate.push(runDate.format('YYYY-MM-DD'));
              let counterDay = date.weekday();
              if (counterDay < 6) {
                if (counterDay === 0) {
                  dateString = date.clone().add(1, 'day').format('DD-MM-YYYY');
                  week++;
                }
                durationOfWeek++;
                year = date.format('YYYY');
              } else if (counterDay >= 6) {
                let lastDayOfWeek = date.clone();
                lastDayOfWeek.add(1, 'day');
                dateString = `${dateString} - ${lastDayOfWeek.format('DD-MM-YYYY')}`;
                rangeWeek.push({ dateString, week, year, durationOfWeek });
                durationOfWeek = 1;
                counterDay = 0;
              }
            }

            if (col <= duration + 2) {
              let taskId = null;
              if (cell) {
                const taskFound = taskArr.find((task) => task.taskName === cell?.v);
                if (taskFound) {
                  taskId = taskFound.taskId;
                }
              }
              const dataAdd = {
                name,
                code: cell ? cell.v : null,
                date: date.format('YYYY-MM-DD'),
                unitCode: unitSelected,
                task_id: taskId,
              };
              data[i].push(dataAdd);

              date.add(1, 'day');
              currDate.add(1, 'day');
              runDate.add(1, 'day');
            }
          }
          if (col === 0) {
            const firsNumReg = /\d{1,}.\s/;
            name = cell?.v.replace(firsNumReg, '');
            date = startDate.clone();
            runDate.add(1, 'day');
          }
        }

        if (count > range.e.c) {
          break;
        } else {
          count = 0;
        }
      }

      const resultData = data.slice(0, data.length - 1);

      setFileData(resultData);
      if (!isFilter) {
        setDateRange(dates.slice(0));
      }
      setWeekRange(rangeWeek);
    };

    fileReader.readAsArrayBuffer(file);
  };

  const getDatesArr = (startDate, endDate) => {
    const current = startDate.clone();
    const dates = [];
    while (endDate.diff(current, 'days') !== 0) {
      dates.push(current.format('YYYY-MM-DD'));
      current.add(1, 'day');
      if (endDate.diff(current, 'days') === 0) {
        dates.push(current.format('YYYY-MM-DD'));
      }
    }
    return dates;
  };

  useEffect(() => {
    const data = fileData?.map((dataItem) => {
      let taskArr = {};
      dataItem?.forEach((item) => {
        if (item?.code !== null) {
          taskArr = {
            ...taskArr,
            [item.date]: [
              {
                taskName: item.code,
              },
            ],
          };
        }
      });
      return {
        name: dataItem[0]?.name,
        task_arr: taskArr,
      };
    });
    setData(data);
  }, [fileData]);

  const handleOnChange = (e) => {
    const { name, value, type } = e.target;
    if (type === 'date') {
      let date = moment(value);
      const weekday = date.weekday();
      if (name === 'from') {
        if (weekday >= 1) {
          date = date.add(-weekday + 1, 'days');
        } else {
          date = date.add(-6, 'days');
        }
      }
      if (name === 'to') {
        if (weekday > 0) {
          date = date.add(7 - weekday, 'days');
        }
      }
      setWishListInfo({
        ...wishListInfo,
        [name]: date.format('YYYY-MM-DD'),
      });
    } else {
      setWishListInfo({
        ...wishListInfo,
        [name]: value,
      });
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);
    axiosClient
      .post('calendar-plan/wish-list/create', {
        fileData: fileData,
        unitCode: unitSelected,
        name: wishListInfo?.plan_name,
        from: wishListInfo.from,
        to: wishListInfo.to,
      })
      .then(() => {
        setIsLoading(false);
        Toast.fire({ icon: 'success', title: `${t('submit')} ${t('success')}` });
        setActive(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        Toast.fire({ icon: 'error', title: `${t('submit')} ${t('unsuccess')}` });
        setActive(false);
      });
  };
  useEffect(() => {
    axiosClient
      .post('calendar-plan/kalender/all-task', { unitCode: unitSelected })
      .then((result) => {
        const taskArr = result?.map((item) => ({
          ...item,
          id: `${item.taskId}-${item.timelotId}`,
        }));
        setTaskArr(taskArr);
      });
  }, []);

  useEffect(() => {
    let durationDate = 364;

    if (selectedFile) {
      let foundedStart = 0;
      let foundedTo = dateRange?.length;

      if (wishListInfo?.from) {
        foundedStart = dateRange.findIndex((date) => date === wishListInfo?.from);
      }

      if (wishListInfo?.to) {
        foundedTo = dateRange.findIndex((date) => date === wishListInfo.to);
      }

      const newDateRage = dateRange?.slice(foundedStart >= 0 ? foundedStart : 0, foundedTo + 1);
      setDateRangeData(newDateRage);
    }

    if (wishListInfo?.to) {
      const startDate = wishListInfo.from;

      const momentStartDate = moment(startDate);
      const momentEndDate = moment(wishListInfo.to);
      durationDate = momentEndDate.diff(momentStartDate, 'days') + 1;

      const endYear = Number(wishListInfo?.to.split('-')[0]);
    }

    readExcel(selectedFile, moment(wishListInfo.from), durationDate, false);
  }, [selectedFile]);

  useEffect(() => {
    setDateRangeData(dateRange);
  }, [dateRange]);

  useEffect(() => {
    if (selectedFile) {
      let foundedStart = 0;
      let foundedTo = dateRange?.length;

      if (wishListInfo?.from) {
        foundedStart = dateRange.findIndex((date) => date === wishListInfo?.from);
      }

      if (wishListInfo?.to) {
        foundedTo = dateRange.findIndex((date) => date === wishListInfo.to);
      }

      const newDateRage = dateRange?.slice(foundedStart >= 0 ? foundedStart : 0, foundedTo + 1);
      setDateRangeData(newDateRage);

      readExcel(selectedFile, moment(wishListInfo.from), newDateRage?.length, true);
    }
  }, [wishListInfo]);

  return (
    <div className="wish-popup">
      <div className="wish-popup__wrap">
        <div className="wish-popup__content">
          {isLoading ? (
            <div className="overlay">
              <Loading />
            </div>
          ) : null}
          {!isLoading ? (
            <div className="wish-popup__content__header">
              <span className="select-wish-title">{selectedFile?.name}</span>

              <CloseOutlined
                onClick={() => {
                  setActive(false);
                }}
              />
            </div>
          ) : null}
          <div className="wish-popup__content__body">
            <div className="select-wish-upload__container">
              <div className="create-patient-plan-form__info-group">
                <input
                  className="create-patient-plan-form__info-group__input"
                  type="text"
                  name="plan_name"
                  onChange={handleOnChange}
                  value={wishListInfo?.plan_name}
                  placeholder="Enter wish name"
                  required
                />
                <div className="create-patient-plan-form__info-group__item">
                  <label htmlFor="" className="create-patient-plan-form__info-group__label">
                    {t('from-date')}
                  </label>
                  <input
                    type="date"
                    value={wishListInfo?.from}
                    onChange={handleOnChange}
                    name="from"
                    id=""
                  />
                </div>
                <div className="create-patient-plan-form__info-group__item">
                  <label htmlFor="" className="create-patient-plan-form__info-group__label">
                    {t('to-date')}
                  </label>
                  <input
                    type="date"
                    value={wishListInfo?.to}
                    onChange={handleOnChange}
                    name="to"
                    id=""
                  />
                </div>
              </div>
              <div>
                {Object.keys(wishListInfo).length > 2 ? (
                  <label className="custom-btn " htmlFor="file">
                    {t('upload-wish-list')}
                  </label>
                ) : null}
                <input
                  id="file"
                  style={{
                    visibility: 'hidden',
                    display: 'none',
                  }}
                  className="select-wish-file"
                  type="file"
                  accept=".xlsx, .xls"
                  name="file"
                  required
                  onChange={handleFileChange}
                  onClick={(e) => (e.target.value = '')}
                />
              </div>
            </div>
          </div>
          <div className="wish-list-table__container">
            {data?.length > 0 ? (
              <CalendarPlanWishListTable
                data={data}
                dateRange={dateRangeData}
                weekRange={weekRange}
                empArr={data}
                taskArr={taskArr}
              />
            ) : null}
          </div>
          {!fileData.length || isLoading ? null : (
            <div className="wish-list-table__footer">
              <button disabled={isLoading} className="custom-btn" type="button" onClick={onSubmit}>
                {t('submit')}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(CalendarPlanWish);
