import { CloseOutlined } from '@ant-design/icons';
import DatePickerCustom from 'components/DatePicker/DatePickerCustom';
import { t } from 'i18next';
import moment from 'moment';
import React from 'react';

const DateOnlyItem = ({ questionId, surveyRecord, setSurveyRecord, index }) => {
  const survey = { ...surveyRecord };
  if (!survey[questionId]) {
    survey[questionId] = { type: 'date-only', dateArr: [] };
  }

  return (
    <div className="calendar-plan-survey-form__date__wrap">
      <div className="calendar-plan-survey-form__date__index">{t('new-range')}:</div>
      <div className="calendar-plan-survey-form__date__group date-only">
        <input
          type="date"
          name=""
          id=""
          value={
            survey[questionId].dateArr[index]
              ? moment(survey[questionId].dateArr[index]).format('YYYY-MM-DD')
              : null
          }
          onChange={(e) => {
            const { value } = e.target;
            survey[questionId].dateArr[index] = new Date(value).toISOString();
            setSurveyRecord(survey);
          }}
        />
        <div className="clear">
          <CloseOutlined
            onClick={() => {
              survey[questionId].dateArr[index] = null;
              setSurveyRecord(survey);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DateOnlyItem;
