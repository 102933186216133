import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import axiosClient from 'axios/axiosClient';
import { Toast } from 'helper/alert';
import { t } from 'i18next';
import React, { useState } from 'react';

const CalendarPlanGroupRowNew = ({ setActive, isUpdate, setIsUpdate, unitSelected }) => {
  const [group, setGroup] = useState({ name: '', description: '', unit_code: unitSelected });
  const handleOnChange = (e) => {
    setGroup({ ...group, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    let isValid = true;
    for (const key of Object.keys(group)) {
      if (group[key].length == 0 && key !== 'description') {
        isValid = false;
      }
    }
    if (isValid) {
      axiosClient
        .post('calendar-plan/group/create', { group })
        .then((_) => {
          Toast.fire({ icon: 'success', title: `${t('create-group')} ${t('success')}` });
          setActive(false);
          setIsUpdate((prev) => !prev);
        })
        .catch((err) => {
          Toast.fire({ icon: 'error', title: `${t('create-group')} ${t('unsuccess')}` });
        });
    } else {
      Toast.fire({ icon: 'error', title: `${t('please-input-all')} ` });
    }
  };
  return (
    <tr className="calendar-plan-create-role__item">
      <td className="calendar-plan-create-role__item-label">
        <input
          autoFocus={true}
          name="name"
          type="text"
          value={group.name}
          onChange={handleOnChange}
          required
        />
      </td>
      <td className="calendar-plan-create-role__item-label">
        <input name="key" type="text" value={group.key} onChange={handleOnChange} required />
      </td>

      <td className="calendar-plan-create-role__item-description">
        <input name="description" type="text" value={group.description} onChange={handleOnChange} />
      </td>
      <td className="calendar-plan-create-role__item-action">
        <CheckOutlined onClick={handleSubmit} />
        <CloseOutlined
          onClick={() => {
            setActive(false);
          }}
        />
      </td>
    </tr>
  );
};

export default CalendarPlanGroupRowNew;
