import axiosClient from 'axios/axiosClient';
import { weekdayKey } from 'constant';
import { t } from 'i18next';
import moment from 'moment';
import React, { memo, useCallback, useEffect, useState } from 'react';
import './CalendarPlanWishListTable.scss';
import { useDispatch } from 'react-redux';
import {
  updateDataTable,
  updateEmailWishList,
  updateWishList,
  updateWishListItem,
} from 'redux/calendarPlan/calendarPlanSlice';
import Loading from 'components/Loading/Loading';
import { SyncOutlined } from '@ant-design/icons';

const Select = memo(({ emp, taskArr, date, onExternalChange }) => {
  const [open, setOpen] = useState(false);

  const handleToggle = useCallback(() => setOpen(!open), [open]);

  return (
    <select
      className={`kalender-plan-content-table-input`}
      defaultValue={emp.task_arr[date] ? emp.task_arr[date][0].taskName : ''}
      onChange={(e) => {
        onExternalChange(e, emp, date);
        handleToggle();
      }}
      onClick={handleToggle}
    >
      <option value={''}>{emp.task_arr[date] ? emp.task_arr[date][0].taskName : '-'}</option>
      {taskArr?.map((task, index) => {
        return (
          <option value={task.taskName} key={`task.uuid-${index}`}>
            {task.taskName}
          </option>
        );
      })}
    </select>
  );
});

function CalendarPlanWishListTable({
  data,
  dateRange,
  weekRange,
  empArr,
  taskArr,
  allowEdit,
  isUpdated,
  setUpdated,
  isShowInfo,
  enableEmail,
  enableSelect,
  loading,
  wishListId,
  handleScroll,
}) {
  const dispatch = useDispatch();
  const [empSelected, setEmpSelected] = useState([]);

  const weekendIndex = [6, 5];
  const hideClass = isShowInfo ? '' : 'hide';

  const RenderWeek = ({ weekRange }) => {
    return weekRange?.map((weekItem, index) => {
      return (
        <th
          key={weekItem.dateString}
          className="  sticky-top-week text-center"
          colSpan={
            weekItem?.durationOfWeek
              ? index !== 0
                ? weekItem.durationOfWeek
                : weekItem.durationOfWeek + 2
              : index !== 0
              ? 7
              : 8
          }
        >
          {t('week')} {weekItem.week} ({weekItem.dateString})
        </th>
      );
    });
  };

  const RenderDate = useCallback(
    ({ dateRange }) => {
      return dateRange?.map((date) => {
        const weekday = moment(date).weekday();

        let weekdayIndex = weekday - 1;
        if (weekday === 0) {
          weekdayIndex = 6;
        }
        return (
          <th
            key={date}
            className={`  text-center sticky-top-header ${
              weekendIndex.includes(weekdayIndex) ? 'weekend' : ''
            }`}
          >
            {t(weekdayKey[weekdayIndex])}
          </th>
        );
      });
    },
    [dateRange],
  );

  const handleOnChange = useCallback(
    (e, row, date) => {
      const { value } = e.target;
      const updateData = {
        row,
        value,
        date,
        wishListId,
      };
      dispatch(updateWishListItem({ data: updateData }));
    },
    [wishListId],
  );

  const handleSelect = (e, row) => {
    if (empSelected.includes(e.target.name)) {
      setEmpSelected(
        empSelected.filter((item) => {
          return item !== e.target.name;
        }),
      );
    } else {
      setEmpSelected([...empSelected, e.target.name]);
    }
  };

  useEffect(() => {
    dispatch(
      updateEmailWishList({
        data: empSelected,
      }),
    );
  }, [empSelected]);

  const RenderEmployee = useCallback(
    ({ data, dateRange }) => {
      return data?.map((emp, index) => {
        return (
          <tr key={emp.uuid}>
            {enableSelect ? (
              <td className="select sticky sticky-primary value">
                <input
                  name={emp.uuid}
                  type="checkbox"
                  onChange={(e) => handleSelect(e, emp)}
                  checked={empSelected.includes(emp.uuid)}
                />
              </td>
            ) : null}
            <td className="no sticky sticky-primary value">{index + 1}</td>
            <td className="sticky sticky-secondary value">{emp.name} </td>
            {enableEmail ? (
              <td className="sticky sticky-third value">{emp?.email ? emp.email : '-'} </td>
            ) : null}
            <td className={` value  sticky sticky-third  ${hideClass}`}></td>
            <td className={` value  sticky sticky-fouth  ${hideClass}`}></td>
            {dateRange?.map((date) => {
              return (
                <td key={`${emp.uuid}-${date}`} className="text-center value">
                  {allowEdit ? (
                    <Select
                      emp={emp}
                      taskArr={taskArr}
                      date={date}
                      onExternalChange={handleOnChange}
                    />
                  ) : emp.task_arr[date] ? (
                    emp.task_arr[date][0].taskName
                  ) : (
                    '-'
                  )}
                </td>
              );
            })}
          </tr>
        );
      });
    },
    [data, dateRange],
  );

  return (
    <div>
      <div className="calendar-plan-content-table__wrap" onScroll={handleScroll}>
        <table className="calendar-plan-content-table">
          <thead className="week-head">
            <th className=" sticky-top-week" colSpan={4}></th>
            {isShowInfo ? <th className=" sticky-top-week " colSpan={5}></th> : <></>}
            <RenderWeek weekRange={weekRange} />
          </thead>
          <thead className="weekDay">
            {enableSelect ? (
              <th className="no sticky sticky-primary sticky-top-header select"></th>
            ) : null}
            <th className="no sticky sticky-primary sticky-top-header">{t('no')}</th>
            <th className="sticky sticky-secondary sticky-top-header">{t('emp-name')}</th>
            {enableEmail ? (
              <th className="sticky sticky-third sticky-top-header">{t('email')}</th>
            ) : null}
            <th className={`job-percentage sticky sticky-third sticky-top-header ${hideClass}`}>
              {t('role')}
            </th>
            <th className={`job-percentage sticky sticky-fouth sticky-top-header  ${hideClass}`}>
              {t('job-percentage')}
            </th>
            <th className={`job-percentage sticky sticky-fifth sticky-top-header ${hideClass}`}>
              {t('job-percentage')} {t('assigned')}
            </th>
            <RenderDate dateRange={dateRange} />
          </thead>
          <tbody>
            <RenderEmployee data={data} dateRange={dateRange} />
            {loading ? (
              <tr>
                <td className="sticky">
                  <SyncOutlined spin />
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default memo(CalendarPlanWishListTable);
