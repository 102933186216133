import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import CalendarPlan from 'views/pages/CalendarPlan';
import Profile from 'views/pages/Profile';

const Dashboard = lazy(() => import('views/pages/Dashboard'));
const Planning = lazy(() => import('views/pages/Planning'));
const Evaluation = lazy(() => import('views/pages/Evaluation'));
const Analysis = lazy(() => import('views/pages/Analysis'));
const Patient = lazy(() => import('views/pages/Patient'));
const Help = lazy(() => import('views/pages/Help'));

const routes = [
  {
    key: 'home',
    path: '/',
    name: 'Dashboard',
    component: <Navigate replace to="/dashboard" />,
    roles: ['planner', 'partner', 'admin', 'economist'],
    pages: [
      'trondheim',
      'bluebirdcarers',
      'carersbreak',
      'heathlandsvillage',
      'falcare',
      'malvik',
      'indrefosen',
      'oslo',
      'hitra',
      'diakonhjemmet',
      'demo',
      'enebakk',
    ],
  },
  {
    key: 'dash-board',
    path: '/dashboard',
    name: 'Dashboard',
    component: <Dashboard />,
    roles: ['planner', 'partner', 'admin', 'economist'],
    pages: [
      'trondheim',
      'bluebirdcarers',
      'carersbreak',
      'heathlandsvillage',
      'falcare',
      'malvik',
      'indrefosen',
      'oslo',
      'hitra',
      'diakonhjemmet',
      'demo',
      'enebakk',
    ],
  },
  {
    key: 'planning',
    path: '/planning',
    name: 'Planning',
    component: <Planning />,
    roles: ['planner', 'admin'],
    pages: [
      'trondheim',
      'bluebirdcarers',
      'carersbreak',
      'heathlandsvillage',
      'falcare',
      'malvik',
      'indrefosen',
      'hitra',
      'diakonhjemmet',
      'demo',
      'enebakk',
      'oslo',
    ],
  },
  // {
  //   key: 'evaluation',
  //   path: '/evaluation',
  //   name: 'Evaluation',
  //   component: <Evaluation />,
  //   roles: ['planner', 'partner', 'admin', 'economist'],
  //   pages: [
  //     'trondheim',
  //     'bluebirdcarers',
  //     'carersbreak',
  //     'heathlandsvillage',
  //     'falcare',
  //     'malvik',
  //     'indrefosen',
  //     'oslo',
  //     'hitra',
  //     'diakonhjemmet',
  //     'demo',
  //     'enebakk',
  //   ],
  // },
  {
    key: 'analyse',
    path: '/analyse',
    name: 'Analyse',
    component: <Analysis />,
    roles: ['planner', 'admin', 'economist'],
    pages: [
      'trondheim',
      'bluebirdcarers',
      'carersbreak',
      'heathlandsvillage',
      'falcare',
      'malvik',
      'indrefosen',
      'oslo',
      'hitra',
      'diakonhjemmet',
      'demo',
      'enebakk',
    ],
  },
  {
    key: 'calendar',
    path: '/calendar',
    name: 'CalendarPlan',
    component: <CalendarPlan />,
    roles: ['planner', 'partner', 'admin'],
    pages: [
      // 'trondheim',
      // 'bluebirdcarers',
      // 'carersbreak',
      // 'heathlandsvillage',
      // 'falcare',
      // 'malvik',
      // 'indrefosen',
      'oslo',
      // 'hitra',
      // 'diakonhjemmet',
      'demo',
      'enebakk',
    ],
  },

  {
    key: 'venteid',
    path: '/venteid',
    name: 'Ventenid',
    component: <Patient />,
    roles: ['planner', 'admin'],
    pages: ['oslo', 'demo', 'enebakk'],
  },
  {
    key: 'help',
    path: '/help',
    name: 'Help',
    component: <Help />,
    roles: ['planner', 'partner', 'admin'],
    pages: [
      'trondheim',
      'bluebirdcarers',
      'carersbreak',
      'heathlandsvillage',
      'falcare',
      'malvik',
      'indrefosen',
      'oslo',
      'hitra',
      'diakonhjemmet',
      'demo',
      'enebakk',
    ],
  },
  {
    key: 'profile',
    path: '/profile',
    name: 'profile',
    component: <Profile />,
    roles: ['planner', 'partner', 'admin'],
    pages: [
      'trondheim',
      'bluebirdcarers',
      'carersbreak',
      'heathlandsvillage',
      'falcare',
      'malvik',
      'indrefosen',
      'oslo',
      'hitra',
      'diakonhjemmet',
      'demo',
      'enebakk',
    ],
  },
];

export default routes;
