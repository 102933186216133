import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import _nav from 'navigatiors/_nav';
import { useTranslation } from 'react-i18next';
import { GlobalOutlined, UserOutlined } from '@ant-design/icons';
import { logoutAction } from 'redux/auth/authAction';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectGlobal } from 'redux/global/globalSlice';
import { changeLangAction } from 'redux/global/globalAction';
import './DefaultHeader.scss';
import axiosClient from 'axios/axiosClient';
import { selectAuth } from 'redux/auth/authSlice';
import demoLogo from 'assets/logo/demo.svg';
import bluebird from 'assets/logo/Bluebird.png';
import healthland from 'assets/logo/Healthland.png';
import trondheimLogo from 'assets/logo/trondheim.png';
import falcare from 'assets/logo/falcare.png';
import malvik from 'assets/logo/Malvik.png';
import carersbreak from 'assets/logo/carersbreak-logo.png';
import falcareWhite from 'assets/logo/falcare-logo-white.png';
import oslo from 'assets/logo/oslo.png';
import ChangePasswordForm from 'components/ChangePasswordForm/ChangePasswordForm';

export default function DefaultHeader() {
  const languge = [
    { name: 'EN', value: 'EN' },
    { name: 'NO', value: 'NO' },
  ];
  const { theme } = useSelector(selectGlobal);
  const { role } = useSelector(selectAuth);
  const env = process.env.REACT_APP_SITE;
  const logoSrc = () => {
    switch (env) {
      case 'trondheim':
        return trondheimLogo;
      case 'bluebirdcarers':
        return bluebird;
      case 'heathlandsvillage':
        return healthland;
      case 'malvik':
        return malvik;
      case 'carersbreak':
        return carersbreak;
      case 'oslo':
        return oslo;
      case 'falcare':
        if (theme === 'light') {
          return falcare;
        } else {
          return falcareWhite;
        }

      default:
        return demoLogo;
    }
  };
  const { language } = useSelector(selectGlobal);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const smallLogoEnv = ['malvik', 'trondheim', 'demo', 'enebakk', 'oslo'];
  const [activeChangePassWord, setActiveChangePassword] = useState(false);
  const renderNavLink = () => {
    return _nav.map((nav) => {
      if (nav.roles.includes(role))
        return (
          <NavLink
            key={nav.key}
            to={nav.to}
            className={`nav-item ${pathname === nav.path && 'active'} ${
              pathname === '/' && nav.to == '/dashboard' ? 'active' : ''
            }`}
          >
            {t(nav.name)}
          </NavLink>
        );
    });
  };

  const renderChooseLaguage = () => {
    return languge.map((item) => {
      return (
        <li
          key={item.value}
          className="lang-option__item"
          onClick={() => {
            i18next.changeLanguage(item.value);
            dispatch(changeLangAction(item.value));
          }}
        >
          {item.name}
        </li>
      );
    });
  };
  useEffect(() => {
    i18next.changeLanguage(language);
  }, [language]);
  return (
    <div className="header__wrap">
      <div className="header__content">
        <div className="header__logo">
          <div
            className={`${smallLogoEnv.includes(env) ? 'small-logo' : ''}`}
            style={{
              backgroundImage: `url(${logoSrc()})`,
              width: '75px',
              height: '80px',
              marginRight: '20px',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          ></div>
          {/* <img src={logoSrc()} className="logo d-inline" alt="" width={80} /> */}
          <h3 className="d-inline healthcare-planning">Healthcare Planning Support System</h3>
        </div>
        <div className="header__middle">{renderNavLink()}</div>
        <div className="header__right">
          <div className="lang__wrap">
            <div className="lang-icon">
              <GlobalOutlined /> |
            </div>
            <div className="lang-value">
              {language}
              <div className="lang-option__list">{renderChooseLaguage()}</div>
            </div>
          </div>
          <div className="profile-menu__wrap">
            <div className="profile-menu">
              <div
                className="profile-menu-item"
                onClick={() => {
                  setActiveChangePassword(true);
                }}
              >
                {t('change-password')}
              </div>
              <div
                className="profile-menu-item"
                onClick={() => {
                  localStorage.clear();
                  navigate('/login');
                  dispatch(logoutAction());
                }}
              >
                {t('log-out')}
              </div>
            </div>
            <UserOutlined />
            {activeChangePassWord ? (
              <ChangePasswordForm
                setActive={setActiveChangePassword}
                active={activeChangePassWord}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
