import React, { useEffect, useState } from 'react';
import RangeDate from './RangeDate';
import NewRangeDate from './NewRangeDate';
import NewRangeTime from './NewRangeTime';
import CheckboxTextItem from './CheckboxTextItem';
import DateOnlyItem from './DateOnlyItem';
import { v4 as uuidv4 } from 'uuid';
import SelectWeekItem from './SelectWeekItem';
const AnswerItem = ({
  parentAnswerType,
  answer,
  answerKeys,
  surveyRecord,
  questionData,
  handleOnChange,
  index,
  setSurveyRecord,
  isDisabled,
}) => {
  const typeQuestionParent = parentAnswerType === 'date' ? 'radio' : parentAnswerType;
  const value = {
    en: Object.keys(questionData?.questionChoice[index])[0],
    no: answerKeys[0],
    id: answer[answerKeys[0]],
    type: typeQuestionParent,
  };

  const [isChecked, setIsChecked] = useState(false);
  useEffect(() => {
    if (
      Array.isArray(surveyRecord[questionData?.parentId]) &&
      parentAnswerType !== 'text' &&
      parentAnswerType !== 'checkbox-text' &&
      parentAnswerType !== 'select-week' &&
      parentAnswerType !== 'date' &&
      parentAnswerType !== 'date-only'
    ) {
      switch (parentAnswerType) {
        case 'radio':
          setIsChecked(
            +surveyRecord[questionData?.parentId]?.en === +value.en ||
              +surveyRecord[questionData?.parentId]?.en === +value.no,
          );
          break;

        default:
          setIsChecked(
            surveyRecord[questionData?.parentId]?.some(
              (item) => item.en === value.en || item.no === value.no,
            ),
          );
          break;
      }
    }
  }, [surveyRecord, questionData]);

  return (
    <div className="calendar-plan-survey-form__answer-item">
      {parentAnswerType === 'date' && surveyRecord[questionData?.parentId] ? (
        <RangeDate
          questionId={questionData?.parentId}
          surveyRecord={surveyRecord}
          setSurveyRecord={setSurveyRecord}
        />
      ) : null}
      {parentAnswerType === 'date' ? (
        <NewRangeDate
          questionId={questionData?.parentId}
          surveyRecord={surveyRecord}
          setSurveyRecord={setSurveyRecord}
        />
      ) : null}
      {parentAnswerType === 'date-only' ? (
        <>
          {Array.from({ length: 3 }).map((_, index) => {
            return (
              <DateOnlyItem
                key={uuidv4()}
                questionId={questionData?.parentId}
                surveyRecord={surveyRecord}
                setSurveyRecord={setSurveyRecord}
                index={index}
              />
            );
          })}
        </>
      ) : null}
      {parentAnswerType === 'select-week' ? (
        <>
          <SelectWeekItem
            key={uuidv4()}
            questionId={questionData?.parentId}
            surveyRecord={surveyRecord}
            setSurveyRecord={setSurveyRecord}
            index={index}
          />
        </>
      ) : null}
      {parentAnswerType === 'time' ? (
        <NewRangeTime
          questionId={questionData?.parentId}
          surveyRecord={surveyRecord}
          setSurveyRecord={setSurveyRecord}
        />
      ) : null}
      {parentAnswerType === 'checkbox-text' && (
        <CheckboxTextItem
          answer={answer}
          answerKeys={answerKeys}
          isChecked={isChecked}
          isDisabled={isDisabled}
          questionData={questionData}
          setSurveyRecord={setSurveyRecord}
          surveyRecord={surveyRecord}
          value={value}
        />
      )}
      {parentAnswerType === 'radio' && (
        <>
          <input
            type={parentAnswerType}
            name={questionData?.parentId}
            id={answer[answerKeys[0]]}
            value={answerKeys[0]}
            checked={
              surveyRecord[questionData?.parentId]?.en === value.en ||
              surveyRecord[questionData?.parentId]?.en === value.no
            }
            disabled={isDisabled ? !!isDisabled : false}
            onChange={(e) => {
              const { name, type } = e.target;

              setIsChecked(true);
              if (type === 'radio') {
                const updatedItems = { ...value, type: 'single' };

                setSurveyRecord({
                  ...surveyRecord,
                  [name]: updatedItems,
                });
              } else {
                setSurveyRecord({
                  ...surveyRecord,
                  [name]: { ...value, type: 'single' },
                });
              }
            }}
          />
          <label htmlFor={answer[answerKeys[0]]}>{answerKeys[0]}</label>
        </>
      )}

      {parentAnswerType === 'text' && (
        <input
          className="text-input"
          type={parentAnswerType}
          name={questionData?.parentId}
          id={answer[answerKeys[0]]}
          value={surveyRecord[questionData.parentId]}
          onChange={handleOnChange}
          disabled={isDisabled ? !!isDisabled : false}
        />
      )}

      {parentAnswerType === 'checkbox' && (
        <>
          <input
            type={parentAnswerType}
            name={questionData?.parentId}
            id={answer[answerKeys[0]]}
            value={answerKeys[0]}
            checked={isChecked}
            disabled={isDisabled ? !!isDisabled : false}
            onChange={(e) => {
              const { name, type } = e.target;

              if (type === 'checkbox') {
                const existingItems = surveyRecord[name] || [];
                const itemExists = existingItems.some(
                  (item) => item.en === value.en && item.no === value.no,
                );

                let updatedItems;
                if (itemExists) {
                  updatedItems = existingItems.filter(
                    (item) => item.en !== value.en && item.no !== value.no,
                  );
                } else {
                  updatedItems = [...existingItems, { ...value, type: 'multiple' }];
                }

                setSurveyRecord({
                  ...surveyRecord,
                  [name]: updatedItems,
                });
              } else {
                setSurveyRecord({
                  ...surveyRecord,
                  [name]: value,
                });
              }
            }}
          />

          <label htmlFor={answer[answerKeys[0]]}>{answerKeys[0]}</label>
        </>
      )}
    </div>
  );
};

export default AnswerItem;
