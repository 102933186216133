import { t } from 'i18next';
export const maximumWorkingHour = 1846;
export const SWITCH_BUTON_CALENDAR_EMPLOYEE = 'switch_button_calendar_employee';
export const analysisChartColor = [
  '#bc4bd1',
  '#077ae9',
  '#e1ce58',
  '#87c756',
  '#ff4081',
  '#ffffff',
  '#0ac4ba',
  '#ba7f3b',
  '#ff0000',
];

export const weekday = [t('mon'), t('tue'), t('wed'), t('thu'), t('fri'), t('sat'), t('sun')];
export const weekdayKey = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
export const months = [
  t('jan'),
  t('feb'),
  t('mar'),
  t('apr'),
  t('may'),
  t('jun'),
  t('jul'),
  t('aug'),
  t('sep'),
  t('oct'),
  t('nov'),
  t('dec'),
];
export const websocketURL = process.env.REACT_APP_OPTIMIZE_API;
export const defaultStartDate = '2023-01-01';
export const planCode = ['Prediksjonslag'];
export const dateFormatReg =
  /[1-9][0-9][0-9]{2}-([0][1-9]|[1][0-2])-([1-2][0-9]|[0][1-9]|[3][0-1])/;

export const maxHourWorking = 1846;

//Will refactor for environment when feature done

//E073730 will be Trondheim in the furutre. get name from react_app_site
export const shiftCategory = {
  E073730: {
    dayShift: ['DB', 'LV2', 'LV5'],
    eveShift: ['A', 'AA', 'AM5', 'AM6', 'P5', 'P6', 'LV2', 'LV5'],
    nightShift: ['N2'],
    longShift: ['LV2', 'LV5'],
  },
  E033010: {
    dayShift: ['D', 'ADM', 'PS2'],
    eveShift: ['MV10', 'MV12', 'A14', 'A'],
    nightShift: ['NV'],
    longShift: [],
  },
  E023350: {
    dayShift: ['D', 'D27', 'XD1', 'XD1', 'DPM', 'DPSYK2'],
    eveShift: ['APM', 'A', 'A10'],
    nightShift: ['N1'],
    longShift: ['LV7', 'LV5', 'LV6'],
    other: [],
  },

  other: {
    dayShift: ['D', 'ADM', 'PS2'],
    eveShift: ['MV10', 'MV12', 'A14', 'A'],
    nightShift: ['NV'],
    longShift: [],
  },
};
export const chartColorList = [
  '#3366CC',
  '#DC3912',
  '#FF9900',
  '#109618',
  '#990099',
  '#3B3EAC',
  '#0099C6',
  '#DD4477',
  '#66AA00',
  '#B82E2E',
  '#316395',
  '#994499',
  '#22AA99',
  '#AAAA11',
  '#6633CC',
  '#E67300',
  '#8B0707',
  '#329262',
  '#5574A6',
  '#3B3EAC',
];
export const chartTooltipSize =
  screen.width > 1990
    ? {
        titleFont: {
          size: Math.ceil(screen.width / 150),
        },
        bodyFont: {
          size: Math.ceil(screen.width / 170),
        },
      }
    : {};
