import { CloseOutlined } from '@ant-design/icons';
import DatePickerCustom from 'components/DatePicker/DatePickerCustom';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';

const SingleDateRange = ({ questionId, surveyRecord, setSurveyRecord }) => {
  const [survey, setSurvey] = useState({
    [questionId]: { date: [], single: {}, type: 'single-date' },
  });

  useEffect(() => {
    const surveyUpdate = { ...surveyRecord };
    if (!surveyUpdate[questionId]) {
      surveyUpdate[questionId] = { date: [], single: {}, type: 'single-date' };
    }
    setSurvey(surveyUpdate);
  }, [surveyRecord]);
  return survey[questionId].date.length > 0
    ? survey[questionId].date?.map((_, index) => {
        let position = index;

        return (
          <div
            key={`${questionId}-sub-single-date-${index}`}
            className="calendar-plan-survey-form__date__wrap"
          >
            <div className="calendar-plan-survey-form__date__index">{t('range')}:</div>
            <div className="calendar-plan-survey-form__date__group">
              <div className="calendar-plan-survey-form__date__item">
                <label htmlFor="">{t('from')}</label>
                <DatePickerCustom
                  showWeekNumbers={true}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                  date={
                    survey[questionId].date[index]?.fromDate
                      ? new Date(survey[questionId].date[index]?.fromDate)
                      : null
                  }
                  setDate={(value) => {
                    survey[questionId].date[index] = {
                      ...survey[questionId].date[index],
                      fromDate: value,
                      type: 'date',
                    };
                    setSurveyRecord(survey);
                  }}
                />
              </div>
              <div className="calendar-plan-survey-form__date__item">
                <label htmlFor="">{t('to')}</label>
                <DatePickerCustom
                  showWeekNumbers={true}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                  date={
                    survey[questionId].date[index]?.toDate
                      ? new Date(survey[questionId].date[index]?.toDate)
                      : null
                  }
                  setDate={(value) => {
                    survey[questionId].date[index] = {
                      ...survey[questionId].date[index],
                      toDate: value,
                      type: 'date',
                    };
                    setSurveyRecord(survey);
                  }}
                />
              </div>
              <CloseOutlined
                onClick={() => {
                  survey[questionId].date.splice(position, 1);
                  setSurveyRecord(survey);
                }}
              />
            </div>
          </div>
        );
      })
    : null;
};

export default SingleDateRange;
