import Swal from 'sweetalert2';
import { createSlice } from '@reduxjs/toolkit';
import { loadState } from 'helper/localStorage';
import { Cookies } from 'react-cookie';
const cookies = new Cookies();

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLoading: false,
    isLogged: false,
    role: cookies.get('role') || 'planner',
  },
  reducers: {
    logging(state, action) {
      if (action.payload.isLoading) {
        Swal.fire({
          title: 'Logging',
          html: 'This window will be closed after process success.',
          timerProgressBar: true,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
            const b = Swal.getHtmlContainer().querySelector('b');
          },
        });
      }

      state.isLoading = action.payload.isLoading;
    },
    logged(state, action) {
      cookies.set('accessToken', action.payload.accessToken);
      state.isLogged = action.payload.isLogged;
      state.role = action.payload.role;
    },
    logout(state, action) {
      state.isLogged = action.payload.isLogged;
    },
  },
});

export const selectAuth = (state) => state.auth;
export const { logging, logged, logout } = authSlice.actions;
export default authSlice.reducer;
