import { CloseOutlined } from '@ant-design/icons';
import axiosClient from 'axios/axiosClient';
import Pagination from 'components/Pagination/Pagination';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import './CalendarPlanSendSurveyForm.scss';
import { Toast } from 'helper/alert';
import Loading from 'components/Loading/Loading';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { selectGlobal } from 'redux/global/globalSlice';

const RenderQuestionList = ({ questionList, language, questionSelected, setQuestionSelected }) => {
  const handleOnchange = (question) => {
    const questionIndex = questionSelected.findIndex(
      (questionFind) => questionFind.uuid === question.uuid,
    );
    if (questionIndex >= 0) {
      const questionUpdated = questionSelected.filter(
        (questionFiler) => questionFiler.uuid !== question.uuid,
      );
      setQuestionSelected(questionUpdated);
      return;
    }
    setQuestionSelected([...questionSelected, question]);
  };
  return (
    <table className="block-question-table">
      <thead>
        <th>
          <input
            type="checkbox"
            name=""
            id=""
            checked={questionSelected?.length === questionList?.length}
            onChange={() => {
              if (questionSelected.length === questionList.length) {
                setQuestionSelected([]);
              } else {
                setQuestionSelected(questionList);
              }
            }}
          />
        </th>
        <th>{t('question')[0].toLocaleUpperCase() + t('question').slice(1)}</th>
      </thead>
      <tbody>
        {questionList?.map((question) => {
          const questionIndex = questionSelected.findIndex(
            (questionFind) => questionFind.uuid === question.uuid,
          );
          return (
            <tr key={question.uuid}>
              <td>
                <input
                  type="checkbox"
                  checked={questionIndex >= 0}
                  name=""
                  id=""
                  onChange={() => {
                    handleOnchange(question);
                  }}
                />
              </td>
              <td>{language === 'NO' ? question.questionContentNO : question.questionContent}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
export default function CalendarPlanSendSurveyForm({ setActive, mandatory, unitSelected }) {
  const [listEmp, setListEmp] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [totalEmp, setTotalEmp] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [questionSelected, setQuestionSelected] = useState([]);
  const [empSelected, setEmpSelected] = useState([]);
  const { language } = useSelector(selectGlobal);
  const [nexStep, setNextStep] = useState(false);

  const handleOnChange = (e) => {
    if (empSelected.includes(e.target.name)) {
      setEmpSelected(
        empSelected.filter((item) => {
          return item !== e.target.name;
        }),
      );
    } else {
      setEmpSelected([...empSelected, e.target.name]);
    }
  };
  const renderEmployee = (arr) => {
    return arr.map((item) => {
      return (
        <tr key={item.uuid}>
          <td>
            <input
              name={item.uuid}
              type="checkbox"
              onChange={handleOnChange}
              checked={empSelected.includes(item.uuid)}
            />
          </td>
          <td>{item.name}</td>
          <td>{item.email ? item.email : null}</td>
        </tr>
      );
    });
  };
  const onSendSurvey = async () => {
    setIsLoading(true);
    await axiosClient
      .post('email/survey', { empIds: empSelected })
      .then((result) => {
        setActive(false);
        setIsLoading(false);
        Toast.fire({ icon: 'success', title: t('send-survey') });
      })
      .catch((error) => Toast.fire({ icon: 'fail', title: t('send-survey') }));
  };
  useEffect(() => {
    axiosClient.post('calendar-plan/employee', { unitCode: unitSelected, page }).then((result) => {
      if (result) {
        setTotalPage(Math.ceil(result.length / 10));
        setTotalEmp(result);
      }
    });
    axiosClient
      .post('calendar-plan/employee-pagination', { unitCode: unitSelected, page })
      .then((result) => {
        setListEmp(result);
      });
  }, [unitSelected, page]);

  const mainQuestion = useQuery('survey-main-question', () =>
    axiosClient.post('calendar-plan/survey/get-all-main-question').then((res) => res),
  );
  useEffect(() => {
    const listQuestionUpdated = [...questionSelected];
    const listQuestionBlocked = mainQuestion.data?.questionBlockList;
    if (listQuestionBlocked) {
      for (const questionBlockedId of listQuestionBlocked) {
        const question = mainQuestion.data?.questionList.find(
          (item) => item.uuid === questionBlockedId,
        );
        if (question) {
          listQuestionUpdated.push(question);
        }
      }
      setQuestionSelected(listQuestionUpdated);
    }
  }, [mainQuestion.data]);
  return (
    <div className="overlay center-block">
      <div className="send-survey-form__wrap">
        <div className="send-survey-form__header">
          <h3 className="send-survey-form__title">
            {nexStep ? t('send-survey-form') : t('blocked-question')}
          </h3>
          <CloseOutlined
            onClick={() => {
              setActive(false);
            }}
          />
        </div>
        <div className="send-survey-form__content">
          <table className="send-survey-table">
            <thead>
              <th>
                <input
                  type="checkbox"
                  onChange={(e) => {
                    const arr = totalEmp.map((item) => item.uuid);
                    if (empSelected.length) {
                      setEmpSelected([]);
                    } else {
                      setEmpSelected(arr);
                    }
                  }}
                  checked={empSelected.length === totalEmp.length}
                />
              </th>
              <th> {t('name')}</th>
              <th> {t('email')}</th>
            </thead>
            <tbody>{renderEmployee(listEmp)}</tbody>
          </table>
          <div className="send-survey-form__footer">
            <div className="send-survey-form__total">
              <span className="send-survey-form__total-emp">
                <b>{t('mandatory-question')}:</b> {mandatory}
              </span>
              <span className="send-survey-form__total-emp">
                <b>{t('total-emp')}:</b> {empSelected.length}
              </span>
            </div>
            <Pagination totalPage={totalPage} page={page} setPage={setPage} />
          </div>
        </div>
        <div className="send-survey-form__next-btn">
          <div className=""></div>
          <button
            disabled={isLoading || empSelected?.length === 0}
            className={`custom-btn ${isLoading || (empSelected?.length === 0 && 'loading')}`}
            onClick={onSendSurvey}
          >
            {t('send')}
          </button>
        </div>
      </div>
    </div>
  );
}
