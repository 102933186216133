import React, { useState } from 'react';
import './EmployeeRequestQuestionForm.scss';
import { t } from 'i18next';
import { selectGlobal } from 'redux/global/globalSlice';
import { useSelector } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';
import { useQuery } from 'react-query';
import axiosClient from 'axios/axiosClient';

const RenderQuestionTable = ({ questionList, language, questionSelected, setQuestionSelected }) => {
  const handleOnChange = (value) => {
    const indexInList = questionSelected.findIndex(
      (questionFind) => value.key === questionFind.key,
    );
    if (indexInList >= 0) {
      const updateQuestionSelected = questionSelected.filter((item) => item.key !== value.key);
      setQuestionSelected(updateQuestionSelected);
      return;
    }

    setQuestionSelected([...questionSelected, value]);
  };
  return (
    <table className="employee-request-question-from__table">
      <thead>
        <th>
          <input
            checked={questionSelected.length === questionList.length}
            type="checkbox"
            name=""
            id=""
            onChange={() => {
              if (questionSelected.length === questionList.length) {
                setQuestionSelected([]);
              } else {
                setQuestionSelected(questionList);
              }
            }}
          />
        </th>
        <th>{t('question')[0].toUpperCase() + t('question').slice(1)}</th>
      </thead>
      <tbody>
        {questionList.map((question) => {
          const indexInList = questionSelected.findIndex(
            (questionFind) => question.key === questionFind.key,
          );
          return (
            <tr key={question.key}>
              <td>
                <input
                  checked={indexInList >= 0}
                  type="checkbox"
                  name=""
                  id=""
                  onChange={() => {
                    handleOnChange(question);
                  }}
                />
              </td>
              <td>{question[`${language === 'NO' ? 'no-ver' : 'en-ver'}`]}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const RenderEmployee = ({ employeeData, empSelected, setEmployeeSelected }) => {
  const handleOnChange = (value) => {
    const indexInList = empSelected.findIndex((questionFind) => value.uuid === questionFind.uuid);
    if (indexInList >= 0) {
      const updatEmpSelected = empSelected.filter((item) => item.uuid !== value.uuid);
      setEmployeeSelected(updatEmpSelected);
      return;
    }

    setEmployeeSelected([...empSelected, value]);
  };
  return (
    <table className="employee-request-question-from__table">
      <thead>
        <th>
          {' '}
          <input
            checked={empSelected.length === employeeData.length}
            type="checkbox"
            name=""
            id=""
            onChange={() => {
              if (empSelected.length === employeeData.length) {
                setEmployeeSelected([]);
              } else {
                setEmployeeSelected(employeeData);
              }
            }}
          />
        </th>
        <th>{t('name')}</th>
        <th>{t('email')}</th>
      </thead>
      <tbody>
        {employeeData.map((employee) => {
          const indexInList = empSelected.findIndex(
            (employeeFind) => employee.uuid === employeeFind.uuid,
          );
          return (
            <tr key={employee.uuid}>
              <td>
                <input
                  checked={indexInList >= 0}
                  type="checkbox"
                  name=""
                  id=""
                  onChange={() => {
                    handleOnChange(employee);
                  }}
                />
              </td>
              <td>{employee.name}</td>
              <td>{employee.email}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
const EmployeeRequestQuestionForm = ({ unitSelected, setActive }) => {
  const { language } = useSelector(selectGlobal);
  const [questionSelected, setQuestionSelected] = useState([]);
  const [employeeSelected, setEmployeeSelected] = useState([]);
  const [nexStep, setNextStep] = useState(false);
  const employeeData = useQuery(`${unitSelected}-employeeData`, () =>
    axiosClient
      .post('calendar-plan/employee', {
        unitCode: unitSelected,
      })
      .then((res) => res),
  );
  return (
    <div className="employee-request-question-from__wrap">
      <div className="employee-request-question-from">
        <div className="employee-request-question-from__header">
          <h3 className="employee-request-question-from__title"></h3>
          <CloseOutlined onClick={() => setActive(false)} />
        </div>
        <div className="employee-request-question-from__content">
          {nexStep ? (
            <RenderEmployee
              employeeData={employeeData.data}
              empSelected={employeeSelected}
              employeeSelected={employeeSelected}
              setEmployeeSelected={setEmployeeSelected}
            />
          ) : (
            <RenderQuestionTable
              questionList={[]}
              language={language}
              questionSelected={questionSelected}
              setQuestionSelected={setQuestionSelected}
            />
          )}
        </div>
        <div className="employee-request-question-from__submit">
          <button
            disabled={questionSelected.length === 0}
            className="custom-btn"
            onClick={() => setNextStep(!nexStep)}
          >
            {nexStep ? 'Prev' : 'Next'}
          </button>

          {nexStep ? (
            <button
              disabled={questionSelected.length === 0}
              className="custom-btn"
              onClick={() => {
                setActive(false);
              }}
            >
              {t('send')}
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmployeeRequestQuestionForm;
