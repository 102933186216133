import React, { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import FilterDayNight from 'components/FilterDayNight/FilterDayNight';
import FilterDepartment from 'components/FilterDepartment/FilterDepartment';
import {
  ClockCircleOutlined,
  FilterOutlined,
  SearchOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import axiosClient from 'axios/axiosClient';
import './Filter.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectGlobal } from 'redux/global/globalSlice';
import { Toast } from 'helper/alert';
import {
  getMinDateAction,
  selectDepartmentAction,
  unSelectDepartmentAction,
} from 'redux/global/globalAction';
import { useLocation } from 'react-router-dom';

export default function Filter({ filterActive, setFilterActive }) {
  const [departmentArr, setDepartmentArr] = useState([]);
  const dispatch = useDispatch();
  const path = useLocation().pathname;
  const ignoreUnitDay = ['/calendar'];
  const { unitSelectedArr, dayNightArr, departmentSelectedArr } = useSelector(selectGlobal);
  const filterActiveRef = useRef();
  const handleChangeSelectDepartment = (val, unitArr, departmentName) => {
    const unitCodes = unitArr?.map((item) => item.unitCode);
    !ignoreUnitDay &&
      Toast.fire({
        title: `${t('loading-data')}`,
        timerProgressBar: true,
        timer: false,

        didOpen: () => {
          Toast.showLoading();
        },
      });
    if (departmentSelectedArr?.includes(isNaN(val) ? val : +val)) {
      dispatch(unSelectDepartmentAction(unitCodes, isNaN(val) ? val : +val, departmentName));
    } else {
      dispatch(
        selectDepartmentAction(isNaN(val) ? val : +val, unitCodes, departmentName, ignoreUnitDay),
      );
    }
  };
  const handleSeachChange = (e) => {
    const value = e.target.value;
    axiosClient
      .get('/global/department', { params: { searchInput: value } })
      .then((result) => {
        setDepartmentArr(result);
      })
      .catch((err) => Toast.fire({ icon: 'error', title: t('check-connection') }));
  };

  // useClickOutside(filterActiveRef, () => {
  //   setFilterActive(!filterActive);
  // });
  const renderFilterActive = () => {
    return (
      <div className="filter__wrap active" ref={filterActiveRef}>
        <div>
          <div
            className="filter-title"
            onClick={() => {
              setFilterActive(false);
            }}
          >
            <h3>
              <FilterOutlined /> {t('department')}
            </h3>
            <div className="filter-close">
              <CloseOutlined />
            </div>
          </div>
          <div className="filter-item-group">
            <div className="filter-search-group">
              <SearchOutlined className="filter-search-icon" />
              <input
                className="filter-search-input"
                placeholder={t('search')}
                onChange={(e) => handleSeachChange(e)}
              />
            </div>
            <div className="filter-item">
              <FilterDepartment departmentArr={departmentArr} />
            </div>
            <div className="filter-item">
              {!ignoreUnitDay.includes(path) ? <FilterDayNight /> : <></>}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderFilterDefault = () => {
    return (
      <div
        className="filter__wrap"
        onClick={() => {
          setFilterActive(true);
        }}
      >
        <div
          className="filter-group"
          onClick={() => {
            setFilterActive(true);
          }}
        >
          <div className="filter-item">
            <FilterOutlined
              onClick={() => {
                setFilterActive(true);
              }}
            />
            <span className="count-filter">
              {' '}
              <span>{unitSelectedArr.length}</span>
            </span>
          </div>
          <div className="filter-item">
            {!ignoreUnitDay.includes(path) ? (
              <>
                <ClockCircleOutlined
                  onClick={() => {
                    setFilterActive(true);
                  }}
                />
                <span className="count-filter">
                  <span>{dayNightArr.length}</span>
                </span>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    axiosClient.get('/global/department').then((result) => {
      setDepartmentArr(result);
    });
    // axiosClient.get('/global/get-min-date').then((result) => {
    //   dispatch(getMinDateAction(result[0]['minDate'] || '2023-01-01'));
    // });
  }, []);
  useEffect(() => {
    if (ignoreUnitDay.includes(path) && departmentArr.length) {
      const department = departmentArr[0];
      const departmentExist = departmentArr.filter(
        (item) =>
          departmentSelectedArr.includes(item.departmentCode) &&
          department?.departmentCode !== item.departmentCode,
      );
      if (department?.departmentCode !== departmentSelectedArr[0]) {
        handleChangeSelectDepartment(
          department.departmentCode,
          department.unitArr,
          department.departmentName,
        );
      }
      if (departmentExist.length) {
        departmentExist.forEach((department) => {
          handleChangeSelectDepartment(
            department.departmentCode,
            department.unitArr,
            department.departmentName,
            ignoreUnitDay.includes(path),
          );
        });
      }
    }
  }, [path]);
  return <div>{filterActive ? renderFilterActive() : renderFilterDefault()}</div>;
}
