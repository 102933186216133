import { CloseOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import axiosClient from 'axios/axiosClient';
import { Toast } from 'helper/alert';
import { t } from 'i18next';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectGlobal } from 'redux/global/globalSlice';
import './CalendarPlanTopic.scss';
import TopicRow from './TopicRow';
export default function CalendarPlanTopic({ topicList, setActive, isUpdate, setIsUpdate }) {
  const [isCreating, setIsCreating] = useState(false);
  const [newTopic, setNewTopic] = useState({ topic: '', no_ver: '' });
  const renderTopic = (arr) => {
    return arr.map((item) => {
      return (
        <TopicRow key={item.uuid} topic={item} isUpdate={isUpdate} setIsUpdate={setIsUpdate} />
      );
    });
  };
  const handleOnChange = (e) => {
    setNewTopic({ ...newTopic, [e.target.name]: e.target.value });
  };
  const handleSubmit = () => {
    if (newTopic.no_ver.length || newTopic.topic.length) {
      axiosClient
        .post('calendar-plan/survey-topic/create', newTopic)
        .then((result) => {
          Toast.fire({ icon: 'success', title: `${t('create')} ${t('success')}` });
          setIsUpdate(!isUpdate);
          setIsCreating(false);
          setNewTopic({ topic: '', no_ver: '' });
        })
        .catch((err) => {
          Toast.fire({ icon: 'error', title: `${t('create')} ${t('unsuccess')}` });
        });
    }
  };

  return (
    <div className="topic-management__wrap">
      <div className="topic-management__content-wrap">
        <div className="topic-management__title">
          <h2>{t('topic-management')}</h2>
          <CloseOutlined
            onClick={() => {
              setActive(false);
            }}
          />
        </div>
        <div className="topic-management__content">
          <div className="topic-management__body">
            {renderTopic(topicList)}
            {isCreating ? (
              <div className="topic-management__item">
                <div className="topic-name">
                  <div className="topic-edit__group">
                    <label htmlFor="">{t('en-version')}</label>{' '}
                    <input
                      name="topic"
                      type="text"
                      value={newTopic.topic}
                      onChange={handleOnChange}
                      autoFocus
                    />
                    <label htmlFor="">{t('no-version')}</label>{' '}
                    <input
                      name="no_ver"
                      type="text"
                      value={newTopic.no_ver}
                      onChange={handleOnChange}
                    />
                    <label htmlFor="">{t('order')}</label>{' '}
                    <input
                      name="order"
                      type="number"
                      value={newTopic.order}
                      onChange={handleOnChange}
                      required
                    />
                  </div>
                </div>
                <div className="topic-action">
                  <button className="custom-btn" onClick={handleSubmit}>
                    {t('create-plan-form')}
                  </button>
                  <button
                    className="custom-btn"
                    onClick={() => {
                      setIsCreating(false);
                    }}
                  >
                    {t('cancel')}
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="topic-management__footer">
          <button
            className="custom-btn"
            onClick={() => {
              setIsCreating(true);
            }}
          >
            {t('create-topic')}
          </button>
        </div>
      </div>
    </div>
  );
}
