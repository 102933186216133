import axiosClient from 'axios/axiosClient';
import { maxHourWorking, shiftCategory, weekdayKey } from 'constant';
import { t } from 'i18next';
import moment from 'moment';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import './KanlenderPlanTable.scss';
import Loading from 'components/Loading/Loading';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Toast } from 'helper/alert';
import {
  CaretDownOutlined,
  CaretUpOutlined,
  CopyOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  LockOutlined,
  SyncOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import { useQuery } from 'react-query';
import { copyFeature } from 'helper/copy';
import { selectGlobal } from 'redux/global/globalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { updatePlanDetailLockedAction } from 'redux/calendarPlan/calendarPlanAction';
import { selectCalendarPlan } from 'redux/calendarPlan/calendarPlanSlice';

const KalenderInputUnAssignedEmployee = memo(
  ({ defaultValue, unassigned, employeeArr, employeeUnAssigned, isCleanVakant, lockTable }) => {
    const [value, setValue] = useState('');

    const handleOnChange = (e) => {
      const empId = e.target.value;
      const employee = employeeArr.find((emp) => emp.id == empId);
      setValue(empId);

      const updateInfo = {
        employeeUnAssignedId: employeeUnAssigned.employee_unassigned_uuid,
        employeeId: employee.id,
        isCleanVakant,
      };
      axiosClient
        .post('calendar-plan/kalender/assign-employee', { updateInfo })
        .then((result) => {});
    };

    useEffect(() => {
      setValue(defaultValue);
    }, [defaultValue]);
    return (
      <>
        {lockTable ? (
          <span>{t('un-assigned')}</span>
        ) : (
          <select
            className={`kalender-plan-content-table-input ${unassigned ? 'unassigned' : ''}`}
            type="select"
            value={value}
            onChange={handleOnChange}
          >
            <option value={''}>{t('un-assigned')}</option>
            {employeeArr?.map((emp) => {
              if (emp.dateAssigned[0] == null) {
                return (
                  <option value={emp.id} key={emp.uuid}>
                    {emp.name}
                  </option>
                );
              }
            })}
          </select>
        )}
      </>
    );
  },
);

const ButtonCopyTable = ({ unitSelected, planSelected, isCleanVakant, dateRange }) => {
  const [isCopying, setIsCopying] = useState(false);
  const copyData = () => {
    setIsCopying(true);
    axiosClient
      .post('calendar-plan/kalender/get-all-plan', {
        unitCode: unitSelected,
        planId: planSelected.id,
        isCleanVakant,
      })
      .then((res) => {
        const result = [];
        for (const emp of res) {
          const shifts = [];
          for (const date of dateRange) {
            const task = emp.tasskArr[date] ? emp.tasskArr[date][0]['taskName'] : '-';
            shifts.push(task);
          }
          result.push(shifts.join('\t'));
        }
        copyFeature(result, true);
        setIsCopying(false);
      });
  };

  return (
    <span>
      <CopyOutlined onClick={copyData} />
    </span>
  );
};
function KanlenderPlanTable({
  unitSelected,
  publicHoliday,
  isCleanVakant,
  title,
  planSelected,
  weekSelect,
  isOptimized,
  isAvailable,
  lockUser,
  lockedEmp,
  sortObj,
  lockAllUser,
  setIsOptimized,
  setIsAvailable,
  setIsLoading,
  isLoading,
}) {
  //TODO USE FOR UPLOAD STAFF PLAN LATER
  const [isActiveUploadForm, setIsActiveUploadForm] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [tableId, setTableId] = useState('');
  const [dataRender, setData] = useState([]);
  const [dataRenderVakant, setDataVakant] = useState([]);
  const [dataSummary, setDataSummary] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [weekRange, setWeekRange] = useState([]);
  const [empArr, setEmpArr] = useState([]);
  const [taskArr, setTaskArr] = useState([]);
  const [groupArr, setGroupArr] = useState([]);
  const [fteValue, setFteValue] = useState(0);
  const [lockTable, setLockTable] = useState(false);
  const [secondStickLeft, setSecondStickLeft] = useState(0);
  const [thirdStickLeft, setThirdStickLeft] = useState(0);
  const [fourStickLeft, setFourStickLeft] = useState(0);
  const [secondStickBottom, setSecondStickBottom] = useState(0);
  const [thirdStickBottom, setThirdStickBottom] = useState(0);
  const [activeRoleDetail, setActiveRoleDetail] = useState(false);
  const [activeSummaryTable, setActiveSummaryTable] = useState(false);
  const [isLockedAllEmp, setIsLockedAllEmp] = useState(false);
  const [isShowEmpInfo, setIsShowEmpInfo] = useState(false);
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [isMounted, setIsMount] = useState(false);
  const weekendIndex = [6, 5];
  const firstStickRef = useRef(null);
  const secondStickRef = useRef(null);
  const thirdStickRef = useRef(null);
  const secondStickSummaryRef = useRef(null);
  const firstStickSummaryRef = useRef(null);
  const { language } = useSelector(selectGlobal);
  const { planDetailLocked } = useSelector(selectCalendarPlan);

  const recordPerPage = 15;
  const dispatch = useDispatch();
  const handleScroll = useCallback(
    (e) => {
      const element = e.target;
      const scrollBottom =
        element.scrollHeight - element.scrollTop === element.clientHeight ||
        element.scrollHeight - element.scrollTop < element.clientHeight + 10;

      if (scrollBottom) {
        onLoadMore(page);
      }
    },
    [page],
  );
  const updateFTe = (empData, taskId) => {
    const taskFound = taskArr.find((task) => +task.taskId == +taskId);

    const ftePlus = +taskFound?.hourAssigned || 0 / maxHourWorking;
    setFteValue((prev) => {
      return prev + ftePlus;
    });
  };

  const updateShiftValue = (employeeId, date, task) => {
    const dataUpdate = [...dataRender];
    const empUpdateIndex = dataRender.findIndex((emp) => emp.employee_id == employeeId);
    const datePlanUpdate = dataUpdate[empUpdateIndex]['task_arr'][date];
    if (!datePlanUpdate) {
      const hourAssignedUpdate = dataUpdate[empUpdateIndex]['hourAssigned'];
      dataUpdate[empUpdateIndex] = {
        ...dataUpdate[empUpdateIndex],
        hourAssigned: +hourAssignedUpdate + task.hourAssigned,
        task_arr: {
          ...dataUpdate[empUpdateIndex]['task_arr'],
          [date]: [task],
        },
      };
    } else {
      const oldTask = datePlanUpdate[0];

      const shiftUpdate = { ...dataUpdate[empUpdateIndex]['task_arr'][date][0], ...task };
      const hourAssignedUpdate = dataUpdate[empUpdateIndex]['hourAssigned'];
      const hourNewShift = task ? +task?.hourAssigned : 0;
      dataUpdate[empUpdateIndex] = {
        ...dataUpdate[empUpdateIndex],
        hourAssigned: +hourAssignedUpdate + hourNewShift - oldTask.hourAssigned,
        task_arr: {
          ...dataUpdate[empUpdateIndex]['task_arr'],
          [date]: [shiftUpdate],
        },
      };
      if (!task) {
        delete dataUpdate[empUpdateIndex]['task_arr'][date];
      }
    }

    setData(dataUpdate);
  };
  const RenderWeek = useCallback(
    ({ weekRange }) => {
      return weekRange?.map((weekItem) => {
        return (
          <th key={weekItem.dateString} className="  sticky-top-week text-center" colSpan={7}>
            {t('week')} {weekItem.week} ({weekItem.dateString})
          </th>
        );
      });
    },
    [weekRange, weekSelect, dateRange],
  );
  const RenderDate = useCallback(
    ({ dateRange }) => {
      return dateRange?.map((date) => {
        const weekday = moment(date).weekday();

        let weekdayIndex = weekday - 1;
        if (weekday === 0) {
          weekdayIndex = 6;
        }
        return (
          <th
            key={date}
            className={`  text-center sticky-top-header ${
              weekendIndex.includes(weekdayIndex) ? 'weekend' : ''
            }`}
          >
            {t(weekdayKey[weekdayIndex])}
          </th>
        );
      });
    },
    [dateRange, lockTable, weekSelect],
  );

  const EmployeeRow = memo(({ emp, index, isShowEmpInfo, groupArr }) => {
    const [empData, setEmpData] = useState(emp);
    const [isLocked, setIsLocked] = useState(false);
    const shiftWishList = empData.shiftWish?.split(',') || [];

    const updateJobPercentage = (taskId, date) => {
      const empDataUpdate = { ...empData };
      const taskFound = taskArr.find((task) => task.taskId == +taskId);
      empDataUpdate['task_arr'][date] = [taskFound];
      if (empDataUpdate['task_arr'][date]) {
        const taskOld = empDataUpdate['task_arr'][date][0];
        setEmpData({
          ...empDataUpdate,
          hourAssigned: +empDataUpdate.hourAssigned + taskFound.hourAssigned - taskOld.hourAssigned,
        });
      } else {
        setEmpData({
          ...empDataUpdate,
          hourAssigned: +empDataUpdate.hourAssigned + taskFound.hourAssigned,
        });
      }
    };
    let dateOffArr = [];
    let workingDayRange = [];
    let totalDateOffInRange = 0;
    if (empData.day_off) {
      dateOffArr = empData.day_off.split(',');
    }
    if (empData.workingDayRange) {
      workingDayRange = empData.workingDayRange.split(',');
    }
    dateOffArr.forEach((date) => {
      if (dateRange.includes(date)) {
        totalDateOffInRange++;
      }
    });
    const getDataCopy = (shiftData) => {
      const data = dateRange?.map((date) => {
        return shiftData[date] ? shiftData[date][0]['taskName'] : '-';
      });
      copyFeature(data, false);
    };
    if (empData.employee_id) {
      useEffect(() => {
        setEmpData(emp);
        if (lockedEmp.includes(+emp.employee_id)) {
          setIsLocked(true);
        }
      }, [emp]);
      return (
        <tr key={empData.employee_id}>
          <td className="no sticky sticky-primary value">{index + 1}</td>
          <td className="sticky sticky-secondary value " style={{ left: secondStickLeft }}>
            <div className="name">
              <span>{empData.name}</span>
              <div className="emp-row-feature">
                <span
                  className={`copy-btn ${isLocked ? 'lock' : ''}`}
                  onClick={() => {
                    lockUser(empData.employee_id, empData.task_arr);
                    setIsLocked(!isLocked);
                  }}
                >
                  {isLocked ? <LockOutlined /> : <UnlockOutlined />}
                </span>
                <span className="copy-btn">
                  <CopyOutlined
                    onClick={() => {
                      getDataCopy(empData.task_arr);
                    }}
                  />
                </span>
              </div>
            </div>
          </td>

          {isShowEmpInfo ? (
            <>
              <td
                className={` sticky value ${isShowEmpInfo ? '' : 'hiden-column'}`}
                style={{ left: thirdStickLeft }}
              >
                {empData.role}
              </td>
              <td
                className={` sticky value ${isShowEmpInfo ? '' : 'hiden-column'}`}
                style={{ left: fourStickLeft }}
              >
                {empData.jobPercentage}
              </td>
            </>
          ) : null}

          {dateRange?.map((date) => {
            let isHaveGroup = false;
            if (empData.task_arr[date]) {
              let taskId = empData.task_arr[date][0]['taskId'];
              const taskFound = taskArr.findIndex((task) => +taskId === +task.taskId);
              const groupFound = groupArr?.findIndex(
                (group) => taskArr[taskFound]?.group_id === group.id,
              );
              isHaveGroup = groupFound > -1;
            }

            return (
              <td
                key={`${empData.employee_id}-${date}`}
                className={`text-center value ${
                  !workingDayRange.includes(date) && workingDayRange.length ? 'working-date' : ''
                } ${dateOffArr.includes(date) ? 'dayOff' : ''} ${
                  publicHoliday.includes(date) ? 'holiday' : ''
                } ${isHaveGroup ? 'has-group' : ''}`}
              >
                {empData.task_arr[date] ? (
                  <KalenderInput
                    defaultValue={`${empData.task_arr[date][0]['taskId']}-${empData.task_arr[date][0]['timelotId']}`}
                    detailShift={empData.task_arr[date][0]}
                    taskArr={taskArr}
                    defaultEmp={empData}
                    date={date}
                    shiftWishList={shiftWishList}
                    updateJobPercentage={updateJobPercentage}
                    dateOffArr={dateOffArr}
                    groupArr={groupArr}
                  />
                ) : (
                  <KalenderInput
                    defaultEmp={empData}
                    defaultValue={'-'}
                    taskArr={taskArr}
                    date={date}
                    shiftWishList={shiftWishList}
                    updateJobPercentage={updateJobPercentage}
                    dateOffArr={dateOffArr}
                    groupArr={groupArr}
                  />
                )}
              </td>
            );
          })}
        </tr>
      );
    }

    return (
      <tr key={empData.employee_unassigned_uuid}>
        <td className="no sticky sticky-primary value">{index + 1}</td>
        <td
          className={`sticky sticky-secondary value unassigned 
          
          `}
          style={{ left: secondStickLeft }}
        >
          <div className="name">
            <KalenderInputUnAssignedEmployee
              employeeUnAssigned={empData}
              defaultValue={''}
              unassigned={true}
              employeeArr={empArr}
              isCleanVakant={isCleanVakant}
              lockTable={lockTable}
            />
            <span className="copy-btn">
              <CopyOutlined
                onClick={() => {
                  getDataCopy(empData.task_arr);
                }}
              />
            </span>
          </div>
        </td>
        {isShowEmpInfo ? (
          <>
            <td
              className={` sticky value ${isShowEmpInfo ? '' : 'hiden-column'}`}
              style={{ left: thirdStickLeft }}
            >
              {empData.role}
            </td>
            <td
              className={` sticky value ${isShowEmpInfo ? '' : 'hiden-column'}`}
              style={{ left: fourStickLeft }}
            >
              {empData.jobPercentage}
            </td>
          </>
        ) : null}

        {dateRange?.map((date) => {
          let isHaveGroup = false;
          if (empData.task_arr[date]) {
            let taskId = empData.task_arr[date][0]['taskId'];
            const taskFound = taskArr.findIndex((task) => +taskId === +task.taskId);
            const groupFound = groupArr?.findIndex(
              (group) => taskArr[taskFound]?.group_id === group.id,
            );
            isHaveGroup = groupFound > -1;
          }
          return (
            <td
              key={`${empData.employee_unassigned_uuid}-${date} `}
              className={` 
                    ${publicHoliday.includes(date) ? 'holiday' : ''} text-center value
                    ${isHaveGroup ? 'has-group' : ''}
                    `}
            >
              {empData.task_arr[date] ? (
                <KalenderInput
                  defaultEmp={empData}
                  defaultValue={`${empData.task_arr[date][0]['taskId']}-${empData.task_arr[date][0]['timelotId']}`}
                  unassigned={true}
                  taskArr={taskArr}
                  date={date}
                  updateJobPercentage={updateJobPercentage}
                  dateOffArr={dateOffArr}
                  groupArr={groupArr}
                />
              ) : (
                <KalenderInput
                  defaultEmp={empData}
                  defaultValue={'-'}
                  taskArr={taskArr}
                  unassigned={true}
                  date={date}
                  updateJobPercentage={updateJobPercentage}
                  dateOffArr={dateOffArr}
                  groupArr={groupArr}
                />
              )}
            </td>
          );
        })}
      </tr>
    );
  });

  const RenderEmployee = useCallback(
    ({ data, vakantIndex, isShowEmpInfo, groupArr }) => {
      return data?.map((emp, index) => (
        <EmployeeRow
          key={`${emp.employee_id || emp.employee_unassigned_uuid}-${Math.random()}-row`}
          emp={emp}
          index={vakantIndex ? vakantIndex + index : index}
          isShowEmpInfo={isShowEmpInfo}
          groupArr={groupArr}
        />
      ));
    },
    [dataRender, lockTable, lockedEmp, secondStickLeft, thirdStickLeft, fourStickLeft],
  );
  const KalenderInput = memo(
    ({
      defaultValue,
      unassigned,
      taskArr,
      defaultEmp,
      date,
      shiftWishList,
      dateOffArr,
      detailShift,
      groupArr,
    }) => {
      const [value, setValue] = useState('');
      const [isEditValue, setIsEditValue] = useState(false);
      const [taskName, setTaskName] = useState('');
      const [groupName, setGroupName] = useState(null);
      const [taskId, setTaskId] = useState('');
      const [emp, setEmp] = useState(defaultEmp);
      const weekday = moment(date).weekday();
      let isValidShift = false;
      let isOff = false;
      let weekdayIndex = weekday - 1;
      if (weekday === 0) {
        weekdayIndex = 6;
      }
      let typeShift;
      if (detailShift) {
        switch (detailShift.typeValue) {
          case 0:
            typeShift = 'Day';
            break;
          case 1:
            typeShift = 'Evening';
            break;
          case 2:
            typeShift = 'Night';
            break;
          case 3:
            typeShift = 'Long';
            break;

          default:
            typeShift = 'Other';

            break;
        }
      }

      if (shiftWishList?.length === 0) {
        isValidShift = true;
      }

      if (shiftWishList?.includes(typeShift)) {
        isValidShift = true;
      }
      const handleOnChange = (e) => {
        const newValue = e.target.value;
        const idArr = newValue.split('-');
        setValue(newValue);
        const taskId = newValue.split('-')[0];
        const taskFoundIndex = taskArr.findIndex((task) => +taskId === +task.taskId);
        const taskName = taskFoundIndex > -1 ? taskArr[taskFoundIndex].taskName : null;
        setTaskName(taskName || '-');
        setTaskId(taskId);
        updateShiftValue(emp.employee_id, date, taskArr[taskFoundIndex]);
        // updateJobPercentage(+idArr[0]);
        updateFTe(emp, +idArr[0]);
        if (newValue.length > 0) {
          const task = taskArr.find((item) => item.taskId === +idArr[0]);
          if (!unassigned) {
            const updateInfo = {
              employeeId: emp.employee_id,
              date,
              taskName: task.taskName,
              hourAssigned: task.hourAssigned,
              isAssigned: emp.task_arr[date] ? true : false,
              taskId: +idArr[0],
              timelotId: +idArr[1],
              planId: emp.plan_id,
              isCleanVakant,
            };
            axiosClient.post('calendar-plan/kalender/update', { updateInfo }).catch((_) => {
              Toast.fire({ icon: 'error', title: `${t('update')} ${t('unsuccess')}` });
            });
          } else {
            const updateInfo = {
              employeeId: emp.employee_unassigned_uuid,
              date,
              taskName: task.taskName,
              hourAssigned: task.hourAssigned,
              isAssigned: emp.task_arr[date] ? true : false,
              taskId: +idArr[0],
              timelotId: +idArr[1],
              planId: emp.plan_id,
              isCleanVakant,
            };
            axiosClient
              .post('calendar-plan/kalender/update-unassigned', { updateInfo })
              .catch((_) => {
                Toast.fire({ icon: 'error', title: `${t('update')} ${t('unsuccess')}` });
              });
          }
        } else {
          const idArr = defaultValue.split('-');

          if (!unassigned) {
            const deleteInfo = {
              employeeId: emp.employee_id,
              date,
              isAssigned: emp.task_arr[date] ? true : false,
              taskId: +idArr[0],
              timelotId: +idArr[1],
              planId: emp.plan_id,
              isCleanVakant,
            };
            axiosClient.post('calendar-plan/kalender/delete', { deleteInfo }).catch((_) => {
              Toast.fire({ icon: 'error', title: `${t('update')} ${t('unsuccess')}` });
            });
          } else {
            const deleteInfo = {
              employeeId: emp.employee_unassigned_uuid,
              date,
              isAssigned: emp.task_arr[date] ? true : false,
              taskId: +idArr[0],
              timelotId: +idArr[1],
              planId: emp.plan_id,
              isCleanVakant,
            };
            axiosClient
              .post('calendar-plan/kalender/delete-unassigned', { deleteInfo })
              .catch((_) => {
                Toast.fire({ icon: 'error', title: `${t('update')} ${t('unsuccess')}` });
              });
          }
        }
      };
      useEffect(() => {
        if (defaultValue !== '-') {
          setValue(defaultValue);
        }
        const taskId = defaultValue.split('-')[0];
        const taskFound = taskArr.findIndex((task) => +taskId === +task.taskId);

        const taskName = taskFound > -1 ? taskArr[taskFound].taskName : null;
        setTaskName(taskName);
        setTaskId(taskId);
        setEmp(defaultEmp);
      }, []);
      return (
        <>
          {lockTable || !isEditValue ? (
            <span
              className={`kalender-plan-content-table-hard-input ${
                unassigned
                  ? 'unassigned'
                  : `${
                      (!isValidShift && shiftWishList.length && taskId) ||
                      (dateOffArr.includes(date) && taskId) ||
                      taskName === 'F1'
                        ? 'not-valid-shift'
                        : ''
                    } ${
                      publicHoliday.includes(date) && !dateOffArr.includes(date)
                        ? 'not-valid-shift'
                        : ''
                    }
                 
                    `
              }`}
              onClick={() => {
                if (!dateOffArr.includes(date)) setIsEditValue(true);
              }}
            >
              {dateOffArr.includes(date) ? 'F2' : taskName || '-'}
              {/* <span className="group">{groupName ? ` - ${groupName}` : null}</span> */}
            </span>
          ) : (
            <select
              className={`kalender-plan-content-table-input ${
                unassigned
                  ? 'unassigned'
                  : `${
                      (!isValidShift && shiftWishList.length && taskId) ||
                      (dateOffArr.includes(date) && taskId)
                        ? 'not-valid-shift'
                        : ''
                    } ${
                      publicHoliday.includes(date) && !dateOffArr.includes(date)
                        ? 'not-valid-shift'
                        : ''
                    }`
              }`}
              value={value}
              onChange={handleOnChange}
            >
              <option value={''}>-</option>
              {taskArr?.map((task) => {
                return (
                  <option value={task.id} key={task.uuid}>
                    {task.taskName}
                  </option>
                );
              })}
            </select>
          )}
        </>
      );
    },
  );

  const RenderSummary = useCallback(
    ({
      data,
      dateRange,
      type,
      activeRoleDetail,
      setActiveRoleDetail,
      FTESummary,
      isShowEmpInfo,
    }) => {
      const isStaffPlan = type === 'kalenderplan';
      const title = isStaffPlan ? t('total-awarded') : t('total-staffing');
      const dataRender = isStaffPlan ? data['resultCalendar'] : data['resultStaffPlan'];
      const renderRoleTitle = () => {
        return data['listRoleInPlan'].map((role) => {
          return (
            <div
              key={`${planSelected.uuid}-role-${role.roleId}`}
              className="summary-cell-kalender__item"
            >
              <span className="summary-cell-kalender__item-title">{role.roleName}</span>
            </div>
          );
        });
      };

      const renderTypeShiftByRole = (date) => {
        const dataRender = data['countTypeShiftByRole'].find((item) => item.date === date);
        return data?.listRoleInPlan.map((role) => {
          const indexInTypeList = dataRender?.listShiftByRole.findIndex(
            (item) => item.roleId === role.roleId,
          );
          if (indexInTypeList > -1) {
            const arrCellData = dataRender['listShiftByRole'][indexInTypeList]['listShift'];
            const cellData = {};

            for (const iterator of arrCellData) {
              const key = Object.keys(iterator);
              const value = iterator[key];
              cellData[key] = value;
            }
            return (
              <div
                key={`${role.roleName}-${planSelected.uuid}-${role.roleId}-by-role`}
                className={`summary-cell-kalender__item  role-detail ${
                  activeRoleDetail ? 'active' : ''
                }`}
              >
                <span> {cellData ? cellData['day-shift'] : 0}</span> -
                <span> {cellData ? cellData['aft-shift'] : 0}</span> -
                <span> {cellData ? cellData['evn-shift'] : 0}</span> -
                <span> {cellData ? cellData['long-shift'] : 0}</span>
              </div>
            );
          } else {
            return (
              <div
                key={`${role.roleName}-${planSelected.uuid}-${role.roleId}-by-role`}
                className="summary-cell-kalender__item"
              >
                -
              </div>
            );
          }
        });
      };
      let sumHour =
        type === 'kalenderplan'
          ? data['sumHourAssigned'][0]['hourAssigned']
          : data['sumHourAssignedPlan'][0]['hourAssigned'];
      if (Object.keys(data).length) {
        return (
          <>
            <td
              className="no sticky sticky-primary value"
              colSpan={isShowEmpInfo ? 4 : 2}
              style={{ position: 'sticky' }}
            >
              <div className="">
                <p className="summary-cell-kalender__item-title">
                  {title} {(+sumHour).toLocaleString('en')} ({t('hour')})
                </p>
                <p className="summary-cell-kalender__item-title">
                  {title}{' '}
                  {(isStaffPlan
                    ? FTESummary?.allocatedHourFTE || 0
                    : FTESummary?.jobPercentageFTE || 0
                  ).toLocaleString('en')}{' '}
                  ({t('FTE')})
                </p>
              </div>

              {isStaffPlan ? (
                <>
                  <div className="">
                    <p className="summary-cell-kalender__item-title">
                      {t('total-awarded-vakant')}{' '}
                      {(+data['sumHourAssignedVakant'][0]['hourAssigned']).toLocaleString('en')} (
                      {t('hour')})
                    </p>
                    <p className="summary-cell-kalender__item-title">
                      {t('total-awarded-vakant')}{' '}
                      {(FTESummary?.allocatedHourFTEVakant || 0).toLocaleString('en')} ({t('FTE')})
                    </p>
                  </div>

                  {activeRoleDetail ? (
                    <div
                      className={`summary-cell-kalender role-detail  ${
                        activeRoleDetail ? 'active' : ''
                      }`}
                    >
                      {renderRoleTitle()}
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="collapse" onClick={() => setActiveRoleDetail(!activeRoleDetail)}>
                    {activeRoleDetail ? <CaretUpOutlined /> : <CaretDownOutlined />}
                  </div>
                </>
              ) : (
                <></>
              )}
            </td>

            {dateRange?.map((date) => {
              const cellData = dataRender[date];
              const vakantData = data['resultCalendarVakant'][date];

              return (
                <td key={`summary-kalender-${date}`} className="text-center value">
                  <div className="summary-cell-kalender">
                    <br />

                    <div className="summary-cell-kalender__item">
                      <span> {cellData ? cellData['day-shift'] : 0}</span> -
                      <span> {cellData ? cellData['aft-shift'] : 0}</span> -
                      <span> {cellData ? cellData['evn-shift'] : 0}</span> -
                      <span> {cellData ? cellData['long-shift'] : 0}</span>
                    </div>
                    {isStaffPlan ? (
                      <>
                        <br />
                        <div className="summary-cell-kalender__item">
                          <span> {vakantData ? vakantData['day-shift'] : 0}</span> -
                          <span> {vakantData ? vakantData['aft-shift'] : 0}</span> -
                          <span> {vakantData ? vakantData['evn-shift'] : 0}</span> -
                          <span> {vakantData ? vakantData['long-shift'] : 0}</span>
                        </div>
                      </>
                    ) : null}
                    <br />
                    {activeRoleDetail && isStaffPlan ? <>{renderTypeShiftByRole(date)}</> : <></>}
                  </div>
                </td>
              );
            })}
          </>
        );
      }
    },
    [dataSummary, weekSelect, dateRange, lockTable, fteValue],
  );

  const onLoadMore = (page) => {
    if (page <= totalPage && isMounted && page > 0) {
      setIsLoadingMore(true);
      axiosClient
        .post('/calendar-plan/kalender/data', {
          weekSelect,
          unitCode: unitSelected,
          planId: planSelected.id,
          isCleanVakant,
          page: page,
          totalPage,
          recordPerPage,
          sortObj,
        })
        .then((result) => {
          if (result) {
            let fte = 0;
            const numberWeekendList = result.numberWorkingWeekend;
            const newSummaryData = { ...dataSummary };
            result.data.forEach((emp) => {
              if (emp.employee_id) {
                const employeeWeekendIndex = numberWeekendList.findIndex(
                  (el) => el.employeeId === emp.employee_id,
                );
                const numberWorkingWeekend =
                  employeeWeekendIndex > -1
                    ? numberWeekendList[employeeWeekendIndex]['numberWeekend']
                    : 0;
                emp['numberWorkingWeekend'] = numberWorkingWeekend;
                let hourOff = 0;
                if (emp.day_off) {
                  let dateOff = emp.day_off.split(',');

                  dateOff.forEach((date) => {
                    if (dateRange?.includes(date)) {
                      hourOff += 7.5 * (emp.jobPercentage / 100);
                    }
                  });
                }
              }

              fte += +emp.hourAssigned / maxHourWorking;
            });

            const plusData = result.data;
            if (plusData.length) {
              setData((prev) => {
                return [...prev, ...plusData];
              });
              setDataSummary(newSummaryData);
              setFteValue((prev) => prev + fte);
            }
          }
          setIsLoadingMore(false);
        })
        .catch((err) => {
          console.log(err);
        });
      setPage((prev) => prev + 1);
    }
  };
  useEffect(() => {
    if (page > totalPage && isMounted && page > 0) {
      axiosClient
        .post('/calendar-plan/kalender/vakant', {
          weekSelect,
          unitCode: unitSelected,
          planId: planSelected.id,
          isCleanVakant,
        })
        .then((result) => {
          if (result) {
            let fte = 0;
            const numberWeekendList = result.numberWorkingWeekend;
            const newSummaryData = { ...dataSummary };
            result.data.forEach((emp) => {
              if (emp.employee_id) {
                const employeeWeekendIndex = numberWeekendList.findIndex(
                  (el) => el.employeeId === emp.employee_id,
                );
                const numberWorkingWeekend =
                  employeeWeekendIndex > -1
                    ? numberWeekendList[employeeWeekendIndex]['numberWeekend']
                    : 0;
                emp['numberWorkingWeekend'] = numberWorkingWeekend;
                let hourOff = 0;
                if (emp.day_off) {
                  let dateOff = emp.day_off.split(',');

                  dateOff.forEach((date) => {
                    if (dateRange?.includes(date)) {
                      hourOff += 7.5 * (emp.jobPercentage / 100);
                    }
                  });
                }
              }

              fte += +emp.hourAssigned / maxHourWorking;
            });

            const plusData = result.data;
            if (plusData.length) {
              setDataVakant((prev) => {
                return [...prev, ...plusData];
              });
              setDataSummary(newSummaryData);
              setFteValue((prev) => prev + fte);
            }
          }
          setIsLoadingMore(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [page, unitSelected, planSelected, weekSelect]);
  useEffect(() => {
    setIsLoading(true);
    axiosClient
      .post('/calendar-plan/kalender', {
        weekSelect,
        unitCode: unitSelected,
        planId: planSelected.id,
        isCleanVakant,
        page: 0,
        totalPage: 1,
        recordPerPage,
        sortObj,
      })
      .then((data) => {
        if (data) {
          const totalEmpAssigned = data.totalEmployee;
          const totalPage = Math.ceil(totalEmpAssigned / recordPerPage);
          const numberWeekendList = data.numberWorkingWeekend;
          data.dataSummary['numberWorkingWeekend'] = 0;
          data.numberWorkingWeekend.forEach((item) => {
            data.dataSummary['numberWorkingWeekend'] += item.numberWeekend;
          });
          setIsMount(true);
          setTotalPage(totalPage);
          setTaskArr(data.taskArr);
          setGroupArr(data.groupArr);
          setEmpArr(data.empList);
          setDateRange(data.dateRange);
          setWeekRange(data.weekRange);
          setTotalPage(totalPage);
          let fte = 0;
          data.data.forEach((emp) => {
            if (emp.employee_id) {
              const employeeWeekendIndex = numberWeekendList.findIndex(
                (el) => el.employeeId === emp.employee_id,
              );
              const numberWorkingWeekend =
                employeeWeekendIndex > -1
                  ? numberWeekendList[employeeWeekendIndex]['numberWeekend']
                  : 0;
              emp['numberWorkingWeekend'] = numberWorkingWeekend;
              let hourOff = 0;
              if (emp.day_off) {
                let dateOff = emp.day_off.split(',');
                dateOff.forEach((date) => {
                  if (data.dateRange?.includes(date)) {
                    hourOff += 7.5 * (emp.jobPercentage / 100);
                  }
                });
              }
            }
            fte += +emp.hourAssigned / maxHourWorking;
          });
          setIsLoading(false);
          setFteValue(fte);
          setData(data.data);
          setPage(1);
          setDataSummary(data.dataSummary);
        }
      });

    return () => {
      setIsLoading(false);
      setPage(0);
      setTotalPage(1);
      setData([]);
      setDataVakant([]);
    };
  }, [
    unitSelected,
    weekSelect,
    planSelected,
    isOptimized,
    sortObj.name,
    sortObj.role,
    sortObj.jobPercentage,
  ]);

  useEffect(() => {
    if (lockTable) {
      setIsLoading(true);

      setTimeout(() => {
        const btn = document.getElementById(`${tableId}-button`);
        btn.click();
        setLockTable(false);
        setIsLoading(false);
      }, 10000);
    }
  }, [lockTable]);

  useEffect(() => {
    setTableId(`${title}-${weekSelect}-${t('week')}`);
  }, [weekSelect, title]);

  const { data } = useQuery('summarydata', () =>
    axiosClient
      .post('calendar-plan/kalender/summary-data', {
        planId: planSelected.id,
        fromDate: planSelected.from_date,
        endDate: planSelected.to_date,
      })
      .then((res) => res),
  );

  const FTESummary = useQuery(`${unitSelected}-FTE-summary`, () =>
    axiosClient.post('calendar-plan/kalender/summary-fte', {
      planId: planSelected.id,
      unitCode: unitSelected,
      fromDate: moment(planSelected.from_date).format('YYYY-MM-DD'),
      endDate: moment(planSelected.to_date).format('YYYY-MM-DD'),
      weekSelect,
    }),
  );
  useEffect(() => {
    const firstLeft = firstStickRef.current?.offsetWidth;
    const secondLeft = secondStickRef.current?.offsetWidth;
    const thirdLeft = thirdStickRef.current?.offsetWidth;
    const firstHeigh = firstStickRef.current?.offsetHeight;
    const secondStickHeight = secondStickSummaryRef.current?.offsetHeight;
    const thirdStickHeight = secondStickSummaryRef.current?.offsetHeight;
    const firstStickHeight = firstStickSummaryRef.current?.offsetHeight;
    setThirdStickBottom(secondStickHeight + firstStickHeight);
    setSecondStickBottom(firstStickHeight > 35 ? firstStickHeight - 2 : 35);
    setSecondStickLeft(firstLeft ? firstLeft - 2 : 50);
    setThirdStickLeft(secondLeft ? firstLeft + secondLeft - 4 : 290 + 50);
    setFourStickLeft(thirdLeft ? firstLeft + secondLeft + thirdLeft - 5 : 50 + 290 + 133);
  }, [
    dataRender,
    activeRoleDetail,
    activeSummaryTable,
    language,
    data,
    FTESummary.data,
    firstStickRef,
    secondStickRef,
    thirdStickRef,
  ]);
  useEffect(() => {
    if (planDetailLocked.employeeIdLockedList.length >= dataRender.length) setIsLockedAllEmp(true);
  }, []);
  return (
    <>
      {dataRender.length > 0 && Object.keys(dataSummary).length && isAvailable ? (
        <div className="kalender-plan-content__wrap">
          {isLoading ? (
            <div className="overlay">
              <Loading />
            </div>
          ) : null}

          <div className="kalender-plan-content__header">
            <h3>{planSelected.plan_name}</h3>
            <div className="kalender-plan-content__header-function">
              <button
                className="custom-btn square-btn"
                onClick={() => {
                  setLockTable(true);
                }}
                disabled={isLoading}
              >
                {t('download-as-XLS')}
              </button>
            </div>

            <ReactHTMLTableToExcel
              id={`${tableId}-button`}
              className="custom-btn square-btn hide-element"
              table={`${tableId}`}
              filename={`${tableId}`}
              sheet={`${tableId}`}
              buttonText="Download as XLAS"
            />
          </div>

          <div
            className="kalender-plan-content-table__wrap"
            id={`${tableId}-wrap`}
            onScroll={handleScroll}
          >
            <table className="kalender-plan-content-table" id={`${tableId}`}>
              <thead>
                <tr>
                  <th className=" sticky-top-week" colSpan={isShowEmpInfo ? 4 : 2}></th>

                  <RenderWeek weekRange={weekRange} />
                </tr>
                <tr>
                  <th className="no sticky sticky-primary sticky-top-header " ref={firstStickRef}>
                    {t('no-dashboard')}
                  </th>
                  <th
                    className="sticky sticky-secondary sticky-top-header"
                    style={{ left: secondStickLeft }}
                    ref={secondStickRef}
                  >
                    <div className="name">
                      <div>
                        <span>{t('emp-name')}</span>
                        <span
                          className={`copy-btn emp-info ${isShowEmpInfo ? 'active' : ''}`}
                          onClick={() => setIsShowEmpInfo(!isShowEmpInfo)}
                        >
                          <InfoCircleOutlined />
                        </span>
                      </div>
                      <div className="emp-row-feature">
                        <span
                          className={`copy-btn ${isLockedAllEmp ? 'lock' : ''} `}
                          onClick={() => {
                            if (lockedEmp.length) {
                              dispatch(
                                updatePlanDetailLockedAction({
                                  planDetailLockedList: [],
                                  employeeIdLockedList: [],
                                }),
                              );

                              setIsLockedAllEmp(false);
                              return;
                            }
                            lockAllUser(dataRender);

                            setIsLockedAllEmp(true);
                          }}
                        >
                          {isLockedAllEmp ? <LockOutlined /> : <UnlockOutlined />}
                        </span>
                        <span className="copy-btn">
                          <ButtonCopyTable
                            unitSelected={unitSelected}
                            planSelected={planSelected}
                            isCleanVakant={isCleanVakant}
                            dateRange={dateRange}
                          />
                        </span>
                      </div>
                    </div>
                  </th>
                  {isShowEmpInfo ? (
                    <>
                      <th
                        className={`sticky sticky-top-header ${
                          isShowEmpInfo ? '' : 'hiden-column'
                        }`}
                        style={{ left: thirdStickLeft }}
                        ref={thirdStickRef}
                      >
                        {t('role')}
                      </th>
                      <th
                        className={`sticky sticky-top-header ${
                          isShowEmpInfo ? '' : 'hiden-column'
                        }`}
                        style={{ left: fourStickLeft }}
                      >
                        {t('job-percentage')}
                      </th>
                    </>
                  ) : null}

                  <RenderDate dateRange={dateRange} isShowEmpInfo={isShowEmpInfo} />
                </tr>
              </thead>
              <tbody>
                <RenderEmployee
                  data={dataRender}
                  isShowEmpInfo={isShowEmpInfo}
                  groupArr={groupArr}
                />
                {dataRenderVakant.length ? (
                  <RenderEmployee
                    data={dataRenderVakant}
                    vakantIndex={dataRender.length}
                    isShowEmpInfo={isShowEmpInfo}
                    groupArr={groupArr}
                  />
                ) : null}
                {isLoadingMore ? (
                  <tr>
                    <td className="sticky">
                      <SyncOutlined spin />
                    </td>
                  </tr>
                ) : null}
                {data ? (
                  <>
                    <tr
                      className="tfoot "
                      style={{
                        bottom: `${activeSummaryTable ? thirdStickBottom - 8 : 0}px`,
                        position: 'sticky',
                      }}
                    >
                      <td
                        className="no sticky sticky-primary value text-center summary-header "
                        colSpan={isShowEmpInfo ? 4 : 2}
                      >
                        {t('summary')}
                        <div
                          className="collapse"
                          onClick={() => {
                            setActiveSummaryTable(!activeSummaryTable);
                            if (activeSummaryTable) setActiveRoleDetail(false);
                          }}
                        >
                          {activeSummaryTable ? <CaretUpOutlined /> : <CaretDownOutlined />}
                        </div>
                      </td>
                      {activeSummaryTable ? (
                        dateRange?.map((date) => {
                          return (
                            <td
                              key={`summary-kalender-${date}`}
                              className="text-center value  summary-header"
                            >
                              <span>D</span>-<span>A</span>-<span>N</span>-<span>K</span>
                            </td>
                          );
                        })
                      ) : (
                        <td
                          colSpan={dateRange.length}
                          className="text-center value  summary-header"
                        ></td>
                      )}
                    </tr>
                    {activeSummaryTable ? (
                      <>
                        <tr
                          className="tfoot "
                          style={{ bottom: `${secondStickBottom - 4}px`, position: 'sticky' }}
                          ref={secondStickSummaryRef}
                        >
                          <RenderSummary
                            data={data}
                            dateRange={dateRange}
                            type={'staffPlan'}
                            activeRoleDetail={activeRoleDetail}
                            setActiveRoleDetail={setActiveRoleDetail}
                            FTESummary={FTESummary?.data}
                            isShowEmpInfo={isShowEmpInfo}
                          />
                        </tr>
                        <tr className="tfoot" ref={firstStickSummaryRef}>
                          <RenderSummary
                            data={data}
                            dateRange={dateRange}
                            type={'kalenderplan'}
                            activeRoleDetail={activeRoleDetail}
                            setActiveRoleDetail={setActiveRoleDetail}
                            FTESummary={FTESummary?.data}
                            isShowEmpInfo={isShowEmpInfo}
                          />
                        </tr>
                      </>
                    ) : null}
                  </>
                ) : (
                  <></>
                )}
              </tbody>
            </table>
          </div>
          <div className="kalendar-legend">
            <div className="legend-wrap">
              <div className="dayOff legend"></div>
              <span>{t('day-off-legend')}</span>
            </div>
            <div className="legend-wrap">
              <div className="working-date legend"></div>
              <span>{t('validity-periods')}</span>
            </div>
            <div className="legend-wrap">
              <div className="has-group legend"></div>
              <span>{t('has-group')}</span>
            </div>
            <div className="legend-wrap">
              <div className="holiday legend"></div>
              <span>{t('holiday-legend')}</span>
            </div>
          </div>
        </div>
      ) : (
        <>
          <span>{t('kalendar-content-wait')}</span>
        </>
      )}
    </>
  );
}

export default KanlenderPlanTable;
