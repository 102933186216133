import axiosClient from 'axios/axiosClient';
import { t } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';

import EmployeeRow from './EmployeeRow';
import NewEmployeeRow from './NewEmployeeRow';
import './CalendarPlanEmployee.scss';
import Pagination from 'components/Pagination/Pagination';
import CalendarPlanSendSurveyForm from './CalendarPlanSendSurveyForm/CalendarPlanSendSurveyForm';
import CalendarPlanTopic from './CalendarPlanTopic/CalendarPlanTopic';
import CalendarPlanRole from './CalendarPlanRole/CalendarPlanRole';
import CalendarPlanWish from './CalendarPlanWish/CalendarPlanWish';
import EmployeeTimeOffChart from '../EmployeeTimeOffChart/EmployeeTimeOffChart';
import SurveyContentManagerment from '../SurveyContentManagerment/SurveyContentManagerment';
import EmployeeRequestQuestionForm from './EmployeeRequestQuestionForm/EmployeeRequestQuestionForm';
import CalendarSurveyQuestionRow from './CalendarPlanQuestion/CalendarSurveyQuestionRow';
import CalendarPlanGroup from './CalendarPlanGroup/CalendarPlanGroup';
import CalendarSurveyQuestionForm from './CalendarPlanQuestion/CalendarSurveyQuestionForm';
import UploadEmployeeForm from './UploadEmployeeForm/UploadEmployeeForm';
import { SWITCH_BUTON_CALENDAR_EMPLOYEE } from 'constant';

export default function CalendarPlanEmployee({ unitSelected }) {
  const [listQuestion, setListQuestion] = useState([]);
  const [listExtendedQuestion, setListExtendedQuestion] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [isAddEmployee, setIsAddEmployee] = useState(false);
  const [isAddQuestion, setIsAddQuestion] = useState(false);
  const [isSendSurvey, setIsSendSurvey] = useState(false);
  const [acitveChart, setActiveChart] = useState(false);
  const [isAddTopic, setIsAddTopic] = useState(false);
  const [isCreateRole, setIsCreateRole] = useState(false);
  const [isCreateGroup, setIsCreateGroup] = useState(false);
  const [isUploadingEmployee, setIsUploadingEmployee] = useState(false);
  const [isUploadWishEmpls, setIsUploadWishEmpls] = useState(false);
  const [topicList, setTopicList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [page, setPage] = useState(1);
  const [employees, setEmployees] = useState([]);
  const [totalEmp, setTotalEmp] = useState([]);
  const [mandatory, setMandatory] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [activeIntroForm, setActiveIntroForm] = useState(false);
  const [activeEmpRequestForm, setActiveEmpRequestForm] = useState(false);
  const RenderEmployee = useCallback(
    ({
      data,
      unitSelected,
      setEmployees,
      listQuestion,
      isUpdate,
      setIsUpdate,
      roleList,
      groupList,
      listExtendedQuestion,
      page,
    }) => {
      return data?.map((item, index) => {
        return (
          <EmployeeRow
            key={item.id}
            unitSelected={unitSelected}
            emp={item}
            setEmployees={setEmployees}
            listQuestion={listQuestion}
            listExtendedQuestion={listExtendedQuestion}
            isUpdate={isUpdate}
            setIsUpdate={setIsUpdate}
            roleList={roleList}
            groupList={groupList}
            page={page}
          />
        );
      });
    },
    [employees],
  );

  useEffect(() => {
    axiosClient
      .post('calendar-plan/employee-pagination', { unitCode: unitSelected, page })
      .then((result) => {
        setEmployees(result);
      });
    axiosClient.post('calendar-plan/employee', { unitCode: unitSelected, page }).then((result) => {
      if (result) {
        setTotalPage(Math.ceil(result.length / 10));
      }
    });
    axiosClient.post('calendar-plan/survey-question', { unitCode: unitSelected }).then((result) => {
      if (result) {
        setListQuestion(result);
      }
    });
    axiosClient
      .post('calendar-plan/survey/expaned-question', { unitCode: unitSelected })
      .then((result) => {
        if (result) {
          setListExtendedQuestion(result);
        }
      });
    axiosClient.post('calendar-plan/survey-topic').then((result) => {
      if (result) {
        setTopicList(result);
      }
    });
    axiosClient.post('calendar-plan/survey-question/mandatory').then((result) => {
      if (result) {
        setMandatory(result.data);
      }
    });
    axiosClient.post('calendar-plan/role').then((result) => {
      if (result) {
        setRoleList(result.data);
      }
    });
    axiosClient.post('calendar-plan/group', { unitSelected }).then((result) => {
      if (result) {
        setGroupList(result.data);
      }
    });
  }, [unitSelected, isUpdate, page]);
  return (
    <div className="calendar-plan-employee">
      <div className="calendar-plan-employee__title">
        <h3>{t('emp-list')}</h3>
      </div>
      <div className="employee-content">
        <div className="employee-content-table">
          <div className={`employee-content-chart ${acitveChart ? 'active' : ''}`}>
            <EmployeeTimeOffChart unitSelected={unitSelected} setActive={setActiveChart} />
          </div>
          <div className="calendar-plan-employee__wrap">
            <table className="calendar-plan-employee__table" id="employee-table">
              <thead>
                <th className="employee-table__name">{t('name')}</th>
                <th className="employee-table__email">{t('email')}</th>
                <th className="employee-table__email">{t('group')}</th>
                <th className="employee-table__value">{t('job-percentage')}</th>
                <th className="employee-table__value">{t('role')}</th>
                <th className="employee-table__survey">{t('survey')}</th>
                <th className="employee-table__survey">{t('last-update-survey')}</th>
                <th className="employee-table__feature">{t('action')}</th>
              </thead>
              <tbody>
                {employees.length ? (
                  <RenderEmployee
                    data={employees}
                    unitSelected={unitSelected}
                    setEmployees={setEmployees}
                    listQuestion={listQuestion}
                    listExtendedQuestion={listExtendedQuestion}
                    isUpdate={isUpdate}
                    setIsUpdate={setIsUpdate}
                    roleList={roleList}
                    groupList={groupList}
                    page={page}
                  />
                ) : null}
                {isAddEmployee ? (
                  <NewEmployeeRow
                    unitSelected={unitSelected}
                    setEmployees={setEmployees}
                    setIsAddEmployee={setIsAddEmployee}
                    roleList={roleList}
                    groupList={groupList}
                    isUpdate={isUpdate}
                    setIsUpdate={setIsUpdate}
                  />
                ) : (
                  <></>
                )}
              </tbody>
            </table>
          </div>
          <div className="calendar-plan-employee__feature">
            <button
              className="custom-btn"
              onClick={() => {
                setActiveChart(!acitveChart);
              }}
            >
              {t('active-time-off-chart')}
            </button>
            <button
              className="custom-btn"
              onClick={() => {
                setIsUploadingEmployee(true);
              }}
            >
              {t('upload-employee')}
            </button>

            <button
              className="custom-btn"
              onClick={() => {
                setIsCreateRole(true);
              }}
            >
              {t('add-position')}
            </button>
            {/* TODO TURN ON WHEN NEED CHANGE GROUP */}
            {/* <button
              className="custom-btn"
              onClick={() => {
                setIsCreateGroup(true);
              }}
            >
              {t('add-group')}
            </button> */}
            <button
              className="custom-btn "
              onClick={() => {
                if (isAddEmployee) {
                  setIsAddEmployee(false);
                } else {
                  setIsAddEmployee(true);
                }
              }}
            >
              {t('add')} {t('employee-low')}
            </button>
            <button
              className="custom-btn "
              onClick={() => {
                setIsSendSurvey(true);
              }}
            >
              {t('send-survey')}
            </button>
          </div>
          <div className="calendar-plan-employee__pagination">
            <Pagination totalPage={totalPage} page={page} setPage={setPage} />
          </div>
        </div>
      </div>

      {activeEmpRequestForm ? (
        <EmployeeRequestQuestionForm
          unitSelected={unitSelected}
          setActive={setActiveEmpRequestForm}
        />
      ) : (
        <></>
      )}

      {activeIntroForm ? (
        <SurveyContentManagerment unitSelected={unitSelected} setActive={setActiveIntroForm} />
      ) : (
        <></>
      )}
      {isUploadWishEmpls ? (
        <CalendarPlanWish
          selectedFile={selectedFile}
          unitSelected={unitSelected}
          setActive={setIsUploadWishEmpls}
        />
      ) : (
        <></>
      )}
      {isSendSurvey ? (
        <CalendarPlanSendSurveyForm
          setIsSendSurvey={setIsSendSurvey}
          setActive={setIsSendSurvey}
          listEmp={totalEmp}
          mandatory={mandatory}
          unitSelected={unitSelected}
        />
      ) : (
        <></>
      )}

      {isAddTopic ? (
        <CalendarPlanTopic
          topicList={topicList}
          setActive={setIsAddTopic}
          isUpdate={isUpdate}
          setIsUpdate={setIsUpdate}
        />
      ) : null}
      {isCreateRole ? (
        <CalendarPlanRole
          roleList={roleList}
          isUpdate={isUpdate}
          setIsUpdate={setIsUpdate}
          setActive={setIsCreateRole}
        />
      ) : null}
      {isCreateGroup ? (
        <CalendarPlanGroup
          groupList={groupList}
          isUpdate={isUpdate}
          setIsUpdate={setIsUpdate}
          setActive={setIsCreateGroup}
          unitSelected={unitSelected}
        />
      ) : null}
      {isAddQuestion ? (
        <CalendarSurveyQuestionForm
          setActive={setIsAddQuestion}
          isUpdate={isUpdate}
          setIsUpdate={setIsUpdate}
          topicList={topicList}
        />
      ) : null}
      {isUploadingEmployee ? (
        <UploadEmployeeForm
          setActive={setIsUploadingEmployee}
          unitCode={unitSelected}
          isUpdate={isUpdate}
          setIsUpdate={setIsUpdate}
        />
      ) : null}
    </div>
  );
}
