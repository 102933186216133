import { t } from 'i18next';
import React, { Fragment } from 'react';

const CommentBox = ({ employee, empComment, setEmpComment }) => {
  const handleOnChange = (e) => {
    const { value } = e.target;
    setEmpComment(value);
  };
  return (
    <Fragment>
      <div className="topic__wrap">
        <h2 className="topic-title">#{t('comment-survey')}</h2>
      </div>
      <div className="calendar-plan-survey-form__answer-group">
        <textarea
          className="comment-box"
          onChange={handleOnChange}
          value={empComment}
          cols="20"
          rows="5"
          maxLength={140}
        ></textarea>
      </div>
    </Fragment>
  );
};

export default CommentBox;
