import { CloseOutlined, SyncOutlined } from '@ant-design/icons';
import axiosClient from 'axios/axiosClient';
import { Toast } from 'helper/alert';
import { handleGetIdSheet } from 'helper/getGoogleSheetId';
import { t } from 'i18next';
import React, { useState } from 'react';
import './UploadEmployeeForm.scss';

const UploadEmployeeForm = ({ setActive, unitCode, isUpdate, setIsUpdate }) => {
  const [googleSheetId, setGoogleSheetId] = useState('');
  const [isUploading, setIsUploading] = useState(false);

  const handleSubmit = () => {
    setIsUploading(true);
    axiosClient
      .post('calendar-plan/employee/upload', { googleSheetId, unitCode })
      .then((res) => {
        setIsUploading(false);
        setActive(false);
        setIsUpdate(!isUpdate);
        Toast.fire({ icon: 'success', title: `${t('upload')} ${t('success')}` });
      })
      .catch((err) => {
        setIsUploading(false);
        Toast.fire({ icon: 'error', title: `${t('upload')} ${t('unsuccess')}` });
      });
  };

  return (
    <div className="overlay">
      <div className="upload-file-form__wrap">
        <div className="upload-file-form__content">
          <div className="upload-file-form__header">
            <h4>{t('upload-employee-form')}</h4>
            <div className="upload-file-form__close">
              <button disabled={isUploading}>
                <CloseOutlined
                  onClick={() => {
                    setActive(false);
                  }}
                />
              </button>
            </div>
          </div>

          <div className="upload-file-form-group link__wrap ">
            <div className="upload-file-form-group link">
              <label htmlFor="end-date"> Google Sheet {t('link')}</label>
              <input
                type="text"
                name="googleSheetId"
                onChange={(e) => {
                  const googleSheetId = handleGetIdSheet(e.target.value);
                  setGoogleSheetId(googleSheetId);
                }}
              />
            </div>
          </div>
          <div className="upload-file-form-group submit">
            <button className="custom-btn" disabled={isUploading} onClick={handleSubmit}>
              {t('send')}
              {isUploading ? (
                <span className="loading-icon">
                  <SyncOutlined spin />
                </span>
              ) : null}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadEmployeeForm;
