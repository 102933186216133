import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import Pagination from 'components/Pagination/Pagination';
import { t } from 'i18next';
import React, { useState } from 'react';
import TaskListRow from '../TaskListRow/TaskListRow';
import './CalendarStaffTaskListTable.scss';

export default function CalendarStaffTaskListTable({
  taskList,
  setTaskList,
  roleList,
  isUpdated,
  setIsUpdated,
  listTaskType,
  listGroup,
}) {
  const renderTaskList = (list) => {
    return list.map((task, index) => {
      return (
        <TaskListRow
          key={task.uuid}
          taskDefault={task}
          setTaskList={setTaskList}
          index={index + 1}
          roleList={roleList}
          isUpdated={isUpdated}
          setIsUpdated={setIsUpdated}
          listTaskType={listTaskType}
          listGroup={listGroup}
        />
      );
    });
  };
  return (
    <div className="calendar-staff-table__wrap">
      <table className="calendar-staff-table">
        <thead>
          <th>ID</th>
          <th className="record-name">{t('staff-task-list')}</th>
          <th className="record-time">
            <div className="time__wrap">
              <input className="time" value={t('from-date')} />
              <input className="time" value={t('to-date')} />
            </div>
          </th>
          <th>{t('role')}</th>
          <th>Type</th>
          <th>{t('group')}</th>
          <th>{t('description')}</th>
          <th>{t('action')}</th>
        </thead>
        <tbody>{renderTaskList(taskList)}</tbody>
      </table>
    </div>
  );
}
