import axiosClient from 'axios/axiosClient';
import { t } from 'i18next';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { selectGlobal } from 'redux/global/globalSlice';
import './EmployeeTimeOffChart.scss';
import { CloseOutlined } from '@ant-design/icons';
import { chartColorList } from 'constant';

const calcChartData = (data) => {
  if (data) {
    const countDayOff = {};
    const dateRegExp = /^(\d{1,}-\d{1,}-\d{1,})$/;
    for (const employee of data) {
      const dayListDefault = employee.dayOff.split(',');
      const daySet = new Set();
      for (const iterator of dayListDefault) {
        daySet.add(iterator);
      }
      const dayList = Array.from(daySet);
      for (const date of dayList) {
        if (countDayOff[date]) {
          countDayOff[date] = countDayOff[date] + 1;
        } else {
          countDayOff[date] = 1;
        }
      }
    }

    for (const key of Object.keys(countDayOff)) {
      if (!dateRegExp.test(key)) {
        const execKey = key.substring(1);
        if (countDayOff[execKey]) {
          countDayOff[execKey] = countDayOff[execKey] + countDayOff[key];
        } else {
          countDayOff[execKey] = countDayOff[key];
        }
        delete countDayOff[key];
      }
    }

    const weekList = {};
    for (const key of Object.keys(countDayOff)) {
      let week = moment(key).week();
      const weekday = moment(key).weekday();
      if (weekday === 0) {
        week--;
      }

      if (weekList[week]) {
        weekList[week] += countDayOff[key];
      } else {
        weekList[week] = countDayOff[key];
      }
    }
    const chartData = Array.from({ length: 52 }).map((_, index) => {
      return weekList[index + 1] || 0;
    });
    return chartData;
  }
  return [];
};
const calcChartDataByGroup = (data, roleList) => {
  const listRole = {};
  for (const role of roleList) {
    const dateOff = data.filter((date) => date.roleId === role.roleId);
    listRole[role.role] = calcChartData(dateOff);
  }
  return listRole;
};
const EmployeeTimeOffChart = ({ unitSelected, active, setActive }) => {
  const { theme } = useSelector(selectGlobal);
  const [chartData, setChartData] = useState([]);
  const [dateOffData, setDateOffData] = useState([]);
  const [allRole, setAllRole] = useState([]);
  const [quarter, setQuarter] = useState(1);

  useEffect(() => {
    axiosClient
      .post('calendar-plan/get-date-off', {
        unitCode: unitSelected,
      })
      .then((res) => {
        const chartData = calcChartData(res);
        setDateOffData(res);
        setChartData(chartData);
      });
    axiosClient
      .post('calendar-plan/get-all-role', {
        unitCode: unitSelected,
      })
      .then((res) => {
        setAllRole(res);
      });
  }, [unitSelected]);
  const options = {
    // responsive: true,

    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      tooltip: {
        enabled: true,
        intersect: true,
        mode: 'index',
        position: 'nearest',
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
        },
      },
    },

    interaction: {
      mode: 'index',
      intersect: false,
    },

    hover: {
      mode: 'index',
      intersect: true,
    },

    scales: {
      xAxis: {
        display: true,
        drawOnChartArea: true,
        grid: {
          color: theme === 'dark' ? '#2b2d30' : '#e2e1e1',
        },
      },
      yAxis: {
        display: true,
        color: theme === 'dark' ? 'rgb(75, 192, 192)' : '#e2e1e1',
        ticks: {
          beginAtZero: true,
        },
      },
    },
    elements: {
      point: {
        radius: 0,
        hitRadius: 10,
        hoverRadius: 5,
        hoverBorderWidth: 7,
        display: true,
        pointStyle: 'circle',
      },
    },
  };

  const generateData = useCallback(() => {
    const result = [];
    const roleData = calcChartDataByGroup(dateOffData, allRole);
    for (const index in Object.keys(roleData)) {
      const key = Object.keys(roleData)[index];
      result.push({
        label: key,
        data:
          roleData[key]
            .slice((quarter - 1) * 13, quarter * 13)
            .map((item) => (item * 7.1) / 35.5) || [],
        borderColor: chartColorList[index],
        backgroundColor: `${chartColorList[index]}ab`,
        borderWidth: 2,
        tension: 0.4,
      });
    }
    return result;
  }, [unitSelected, dateOffData, allRole, quarter]);
  const chartDataConfig = {
    labels: Array.from({ length: 13 }).map((_, index) => {
      return `${t('week')} ${(quarter - 1) * 13 + index + 1}`;
    }),
    datasets: generateData(),
  };
  return (
    <div className="employee-time-off-chart__wrap">
      <div className="employee-time-off-chart__header">
        <h3>{t('total-time-off')}</h3>
        <CloseOutlined
          onClick={() => {
            setActive(false);
          }}
        />
      </div>
      <div className="employee-time-off-chart__content">
        <select
          value={quarter}
          name="quarter-employee-off-chart"
          id="quarter-employee-off-chart"
          className="employee-time-off-chart__select"
          onChange={(e) => {
            setQuarter(+e.target.value);
          }}
        >
          <option value={1}>{t('first-quarter')} </option>
          <option value={2}>{t('second-quarter')}</option>
          <option value={3}>{t('third-quarter')}</option>
          <option value={4}>{t('fourth-quarter')}</option>
        </select>
        <div className="employee-time-off-chart">
          {chartData.length ? <Bar options={options} data={chartDataConfig} height={80} /> : null}
        </div>
      </div>
    </div>
  );
};

export default EmployeeTimeOffChart;
