import { saveState } from 'helper/localStorage';
import {
  changeLang,
  changeTheme,
  deletePlanPatinesTask,
  getMinDate,
  initPlanPatiensTask,
  selectDayNight,
  selectDepartment,
  selectUnit,
  unSelectDepartment,
  unSelectUnit,
  updatePlanPatinesTask,
  updatePlanPatinesTaskDetail,
  updatePlanPatinesTaskTime,
} from './globalSlice';

export const changeThemeAction = (val) => {
  return (dispatch) => {
    dispatch(changeTheme({ theme: val }));
    saveState('theme', val);
  };
};

export const changeLangAction = (val) => {
  return (dispatch) => {
    dispatch(changeLang({ language: val }));
    saveState('language', val);
  };
};

export const selectDepartmentAction = (val, unitCodes, departmentName, ignoreUnitDay) => {
  return (dispatch) => {
    dispatch(
      selectDepartment({ departmentSelected: val, unitCodes, departmentName, ignoreUnitDay }),
    );
  };
};

export const unSelectDepartmentAction = (unitCodes, val, departmentName) => {
  return (dispatch) => {
    dispatch(unSelectDepartment({ departmentSelected: val, unitCodes, departmentName }));
  };
};

export const selectUnitAction = (val, departmentCode, unitName) => {
  return (dispatch) => {
    dispatch(selectUnit({ unitSelected: val, departmentCode, unitName }));
  };
};

export const unSelectUnitAction = (val, totalUnitOfDepartment, departmentCode, unitName) => {
  return (dispatch) => {
    dispatch(
      unSelectUnit({
        unitSelected: val,
        totalUnitOfDepartment,
        departmentSelected: departmentCode,
        unitName,
      }),
    );
  };
};

export const selectDayNightAction = (val) => {
  return (dispatch) => {
    dispatch(
      selectDayNight({
        dayNightSelected: val,
      }),
    );
  };
};

export const getMinDateAction = (val) => {
  return (dispatch) => {
    dispatch(
      getMinDate({
        minDate: val,
      }),
    );
  };
};
