import React, { useEffect, useState } from 'react';

import './CalendarPlanWishList.scss';
import { t } from 'i18next';
import axiosClient from 'axios/axiosClient';
import { result } from 'lodash';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import CalendarPlanWishListViewPopUp from './CalendarPlanWishListViewPopUp/CalendarPlanWishListViewPopUp';
import CalendarPlanWishListEditPopUp from './CalendarPlanWishListEditPopUp/CalendarPlanWishListEditPopUp';
import { Toast } from 'helper/alert';
import CalendarPlanWish from '../CalendarPlanEmployee/CalendarPlanWish/CalendarPlanWish';
import moment from 'moment';

const CalendarPlanWishList = ({ unitSelected }) => {
  const [isDeleteWishList, setIsDeleteWishList] = useState(false);
  const [isEditWishList, setIsEditWishList] = useState(false);
  const [isViewWishList, setIsViewWishList] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [wishListEmp, setWishListEmp] = useState([]);
  const [isUploadWishEmpls, setIsUploadWishEmpls] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploadWishList, setIsUploadWishlist] = useState(false);

  useEffect(() => {
    axiosClient.post('calendar-plan/wish-list', { department: unitSelected }).then((result) => {
      if (result) {
        setWishListEmp(result.data);
      }
    });
  }, []);

  useEffect(() => {
    axiosClient.post('calendar-plan/wish-list', { department: unitSelected }).then((result) => {
      if (result) {
        setWishListEmp(result.data);
      }
    });
  }, [unitSelected, isUploadWishEmpls, isUploadWishList]);

  const handleViewWishList = (e, rowItem) => {
    setIsViewWishList(true);
    setSelectedRow(rowItem);
  };

  const handleDeleteWishList = (e, rowItem) => {
    setIsDeleteWishList(true);
    setSelectedRow(rowItem);
    axiosClient.post('calendar-plan/wish-list/del', { wishListId: rowItem.uuid }).then((result) => {
      axiosClient
        .post('calendar-plan/wish-list', { department: unitSelected })
        .then((result) => setWishListEmp(result.data));
      Toast.fire({ icon: 'success', title: `${t('delete')} ${t('success')}` });
    });
  };

  const renderData = () => {
    if (wishListEmp?.length === 0 || !wishListEmp) {
      return (
        <tr>
          <td colSpan={6}>No data</td>
        </tr>
      );
    }
    return wishListEmp?.map((wish, index) => {
      return (
        <tr key={wish.uuid}>
          <td>{index + 1}</td>
          <td>{wish.name ? wish.name : '-'}</td>
          <td>{wish.from}</td>
          <td>{wish.to}</td>
          <td>
            <div className="updated-survey">
              <span>{moment(wish.updatedAt).format('DD-MM-YYYY | HH:mm')}</span>
            </div>
          </td>
          <td>
            <div className="employee-feature">
              <EyeOutlined onClick={(e) => handleViewWishList(e, wish)} />

              <DeleteOutlined onClick={(e) => handleDeleteWishList(e, wish)} />
            </div>
          </td>
        </tr>
      );
    });
  };
  return (
    <div className="calendar-plan-wish-list">
      <div className="calendar-plan-wish-list__content">
        <table className="calendar-plan-wish-list__table" id="employee-table">
          <thead>
            <th className="employee-table__no">{t('no')}</th>
            <th className="employee-table__name">{t('name')}</th>
            <th className="employee-table__value">{t('from')}</th>
            <th className="employee-table__value">{t('to')}</th>
            <th className="employee-table__value">{t('last-update-survey')}</th>
            <th className="employee-table__feature">{t('action')}</th>
          </thead>
          <tbody>{renderData()}</tbody>
        </table>
      </div>
      <div className="calendar-plan-wish-list__group__btn">
        <button className="custom-btn" onClick={() => setIsUploadWishlist(true)}>
          {t('upload-wish-list')}
        </button>
      </div>
      {isViewWishList ? (
        <CalendarPlanWishListViewPopUp
          wishList={selectedRow}
          unitSelected={unitSelected}
          setClose={setIsViewWishList}
        />
      ) : null}
      {isUploadWishList ? (
        <CalendarPlanWish
          // selectedFile={selectedFile}
          unitSelected={unitSelected}
          setActive={setIsUploadWishlist}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default CalendarPlanWishList;
