import axiosClient from 'axios/axiosClient';
import { Toast } from 'helper/alert';
import { t } from 'i18next';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectGlobal } from 'redux/global/globalSlice';
import './NewEmployeeRow.scss';

export default function NewEmployeeRow({
  unitSelected,
  setEmployees,
  setIsAddEmployee,
  isUpdate,
  setIsUpdate,
  roleList,
  groupList,
}) {
  const [employeeInfo, setEmployeeInfo] = useState({ medical_delivery: false, sent_survey: false });
  const handleOnChange = (e) => {
    if (e.target.name === 'job_percentage') {
      if (+e.target.value < 0) {
        e.target.value = 0;
      }
      if (+e.target.value > 100) {
        e.target.value = 100;
      }
    }

    if (e.target.name === 'role_id') {
      e.target.value = +e.target.value;
    }
    setEmployeeInfo({ ...employeeInfo, [e.target.name]: e.target.value });
  };
  const renderRoleOption = (roleList) => {
    return roleList.map((role) => {
      return (
        <option key={role.id} value={role.id}>
          {role.role}
        </option>
      );
    });
  };
  const renderGroupOption = (groupList) => {
    return groupList.map((group) => {
      return (
        <option key={group.key} value={group.name}>
          {group.name}
        </option>
      );
    });
  };
  const hanldeCheckbox = (e) => {
    setEmployeeInfo({ ...employeeInfo, [e.target.name]: !employeeInfo[e.target.name] });
  };
  const handleSubmit = () => {
    if (employeeInfo.name && employeeInfo.role_id && employeeInfo.job_percentage) {
      axiosClient
        .post('calendar-plan/employee/create', {
          employeeInfo: {
            ...employeeInfo,
            unit_code: unitSelected,
          },
        })
        .then((result) => {
          setEmployees(result.data);
          setIsAddEmployee(false);

          Toast.fire({
            icon: 'success',
            title: `${t('create')} ${t('employee')} ${t('success')}`,
          });
        });
    } else {
      Toast.fire({
        icon: 'error',
        title: `${t('create')} ${t('employee')} ${t('unsuccess')}`,
      });
    }
  };
  return (
    <tr className="new-employee__row">
      <td>
        <input type="text" name="name" onChange={handleOnChange} autoFocus={true} />
      </td>
      <td>
        <input type="email" name="email" onChange={handleOnChange} />
      </td>
      <td className="employee-table__value">
        <div className="create-patient-task-form__group-select">
          <select
            className="create-patient-task-form__select"
            value={employeeInfo.group}
            onChange={handleOnChange}
            name="group_id"
            required
          >
            <option value={''}>{t('choose-group')}</option>
            {renderGroupOption(groupList)}
          </select>
        </div>
      </td>
      <td>
        <input
          type="number"
          min="0"
          max="100"
          name="job_percentage"
          onChange={handleOnChange}
          className="employee-table__value"
          required
        />
      </td>

      <td className="employee-table__value">
        <div className="create-patient-task-form__group-select">
          <select
            className="create-patient-task-form__select"
            value={employeeInfo.role}
            onChange={handleOnChange}
            name="role_id"
            required
          >
            <option value={''}>{t('choose-role')}</option>
            {renderRoleOption(roleList)}
          </select>
        </div>
      </td>

      <td className="employee-table__value">
        <input
          type="checkbox"
          checked={employeeInfo.sent_survey}
          value={true}
          name="sent_survey"
          className="employee-table__value sent-survey"
          onChange={hanldeCheckbox}
        />
      </td>
      <td></td>
      <td>
        <button className="custom-btn" onClick={handleSubmit}>
          {t('submit')}
        </button>
      </td>
    </tr>
  );
}
