import React, { useEffect, useState } from 'react';
import './MiniSwitch.scss';

const MiniSwitch = ({ obj1, obj2, value, setValue, width, height }) => {
  const [switchTo, setSwitchTo] = useState('right');
  useEffect(() => {
    if (value === obj2.value) {
      setSwitchTo('left');
    } else {
      setSwitchTo('right');
    }
  }, [value]);
  return (
    <div
      className={`mini-switch__wrap ${switchTo === 'right' ? 'on' : 'off'}`}
      style={{ width: `${width}px`, height: `${height}px` }}
    >
      <div
        className={`mini-switch__item ${value == obj1.value ? 'active-side' : ''}`}
        style={{ width: `${width / 2}px`, maxWidth: `${width / 2}px` }}
        onClick={() => {
          if (value !== obj2.value) {
            setSwitchTo('right');
            setValue(obj2.value);
          }
        }}
      >
        <span>{value === obj1.value ? obj1.name : null}</span>
      </div>
      <div
        className={`mini-switch__item ${value == obj2.value ? 'active-side' : ''}`}
        style={{ width: `${width / 2}px`, maxWidth: `${width / 2}px` }}
        onClick={() => {
          if (value !== obj1.value) {
            setSwitchTo('right');
            setValue(obj1.value);
          }
        }}
      >
        <span>{value === obj2.value ? obj2.name : null}</span>
      </div>
      <span className={`active ${switchTo}`} style={{ width: `${width / 2}px` }}></span>
    </div>
  );
};

export default MiniSwitch;
