import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import axiosClient from 'axios/axiosClient';
import { Toast } from 'helper/alert';
import { t } from 'i18next';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import CalendarPlanSurveyForm from '../CalendarPlanSurveyForm/CalendarPlanSurveyForm';
import './NewEmployeeRow.scss';

export default function EmployeeRow({
  emp,
  setEmployees,
  listQuestion,
  isUpdate,
  setIsUpdate,
  roleList,
  groupList,
  unitSelected,
  listExtendedQuestion,
  page,
}) {
  const [employeeInfo, setEmployeeInfo] = useState(emp);
  const [isEditing, setIsEditing] = useState(false);
  const [activeSurvey, setActiveSurvey] = useState(false);
  const [lastUpdated, setLastUpdated] = useState({});
  const [listUpdated, setListUpdated] = useState([]);
  const [mandatoryRecord, setMandatoryRecord] = useState(0);
  const handleOnChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === 'role') {
      if (!value.length) {
        return;
      }
    }
    if (name === 'job_percentage') {
      if (+value < 0) {
        e.target.value = 0;
      }
      if (+value > 100) {
        e.target.value = 100;
      }
    }

    setEmployeeInfo({ ...employeeInfo, [e.target.name]: e.target.value });
  };
  const hanldeCheckbox = (e) => {
    setEmployeeInfo({ ...employeeInfo, [e.target.name]: !employeeInfo[e.target.name] });
  };
  const handleUpdate = () => {
    if (employeeInfo !== emp && employeeInfo.role?.length) {
      axiosClient.post('calendar-plan/employee/update', { employeeInfo }).then((result) => {
        Toast.fire({
          icon: 'success',
          title: `${t('update')} ${t('employee')} ${t('success')}`,
        });
      });
    }
    setIsEditing(false);
  };
  const handleDelete = () => {
    axiosClient
      .post('calendar-plan/employee/delete', { employeeInfo })
      .then((result) => {
        setEmployees(result.data);
        Toast.fire({
          icon: 'success',
          title: `${t('delete')} ${t('employee')} ${t('success')}`,
        });
      })
      .catch((err) => {
        Toast.fire({
          icon: 'error',
          title: `${t('delete')} ${t('employee')} ${t('unsuccess')}`,
        });
      });
  };
  const renderRoleOption = (optionList) => {
    return optionList.map((role) => {
      return (
        <option key={role.id} value={role.role?.toLowerCase()}>
          {role.role}
        </option>
      );
    });
  };
  const renderGroupOption = (optionList) => {
    return optionList.map((group) => {
      return (
        <option key={group.key} value={group.name?.toLowerCase()}>
          {group.name}
        </option>
      );
    });
  };
  useEffect(() => {
    axiosClient
      .post('calendar-plan/survey-record/last-update', { empID: employeeInfo.uuid })
      .then((result) => {
        if (result) {
          setLastUpdated(result[0]);
          const list = result.map((item) => item.uuid);
          setListUpdated(list);
        }
      });
    axiosClient
      .post('calendar-plan/survey-record/mandatory', { empID: employeeInfo.uuid })
      .then((result) => {
        if (result) {
          setMandatoryRecord(result.data);
        }
      });
  }, [isUpdate]);
  return (
    <tr className="new-employee__row">
      {!!isEditing ? (
        <>
          <td>
            <input type="text" value={employeeInfo?.name} name="name" onChange={handleOnChange} />
          </td>
          <td>
            <input
              type="email"
              value={employeeInfo?.email}
              name="email"
              onChange={handleOnChange}
            />
          </td>
          <td className="employee-table__value">
            <div className="create-patient-task-form__group-select">
              <select
                className="create-patient-task-form__select"
                value={employeeInfo.group?.toLowerCase()}
                onChange={handleOnChange}
                name="group"
              >
                <option value={''}>{t('choose-group')}</option>
                {renderGroupOption(groupList)}
              </select>
            </div>
          </td>
          <td className="employee-table__value">
            <input
              type="number"
              min="0"
              value={employeeInfo.job_percentage}
              max="100"
              name="job_percentage"
              onChange={handleOnChange}
            />
          </td>
          <td className="employee-table__value">
            <div className="create-patient-task-form__group-select">
              <select
                className="create-patient-task-form__select"
                value={employeeInfo.role?.toLowerCase()}
                onChange={handleOnChange}
                name="role"
              >
                <option value={''}>{t('choose-role')}</option>
                {renderRoleOption(roleList)}
              </select>
            </div>
          </td>

          <td className="employee-table__value">
            <input
              type="checkbox"
              checked={employeeInfo.sent_survey}
              value={employeeInfo.sent_survey}
              name="sent_survey"
              onChange={hanldeCheckbox}
            />
          </td>
          <td></td>
          <td>
            <div className="employee-feature">
              <CheckOutlined onClick={handleUpdate} />
              <CloseOutlined
                onClick={() => {
                  setEmployeeInfo(emp);
                  setIsEditing(false);
                }}
              />
            </div>
          </td>
        </>
      ) : (
        <>
          <td>{employeeInfo.name || ''}</td>
          <td>{employeeInfo.email || ''}</td>
          <td className="employee-table__value">
            <div className="employee-role">{employeeInfo.group?.toLowerCase()}</div>
          </td>
          <td className="employee-table__value">{employeeInfo['job_percentage'] || 0}%</td>
          <td className="employee-table__value">
            <div className="employee-role">{employeeInfo.role?.toLowerCase()}</div>
          </td>

          <td className="employee-table__value">
            <div className={`employee-send `}>
              {!!employeeInfo.sent_survey ? t('received') : t('not-received')}
            </div>
          </td>
          <td className="employee-table__value">
            {lastUpdated ? (
              <div className="lasted-update">
                {lastUpdated.lastUpdated
                  ? moment(lastUpdated.lastUpdated).format('DD-MM-YYYY HH:mm')
                  : ''}
                {!!lastUpdated.seen ? (
                  <EyeOutlined
                    onClick={() => {
                      setActiveSurvey(true);
                    }}
                  />
                ) : (
                  <EyeInvisibleOutlined
                    onClick={() => {
                      setActiveSurvey(true);
                    }}
                  />
                )}
              </div>
            ) : (
              <div className="employee-feature">
                <button className="custom-btn" onClick={() => setActiveSurvey(true)}>
                  {t('create')}
                </button>
              </div>
            )}
          </td>

          <td>
            <div className="employee-feature">
              <EditOutlined
                onClick={() => {
                  setIsEditing(true);
                }}
              />
              <DeleteOutlined onClick={handleDelete} />
            </div>
          </td>
        </>
      )}
      {!!activeSurvey ? (
        <CalendarPlanSurveyForm
          unitSelected={unitSelected}
          listQuestion={listQuestion}
          listExtendedQuestion={listExtendedQuestion}
          emp={employeeInfo}
          setActive={setActiveSurvey}
          isUpdate={isUpdate}
          setIsUpdate={setIsUpdate}
          setEmployeeInfo={setEmployeeInfo}
          listUpdated={listUpdated}
          page={page}
          setEmployees={setEmployees}
        />
      ) : (
        <></>
      )}
    </tr>
  );
}
