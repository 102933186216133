import React, { forwardRef, useRef } from 'react';
import './CustomTooltip.scss';

const CustomTooltip = forwardRef(({ children }, ref) => {
  return (
    <div className="custom-tooltip__wrap" ref={ref}>
      <div className="tooltip">
        <div className="tooltip-text">{children}</div>
      </div>
    </div>
  );
});

export default CustomTooltip;
