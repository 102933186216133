import { Toast } from 'helper/alert';
import axiosClient from 'axios/axiosClient';
import { saveState } from 'helper/localStorage';
import { logged, logging, logout } from './authSlice';
import { t } from 'i18next';
import { Cookies } from 'react-cookie';

export const loginAction = (loginInfo) => {
  return async (dispatch) => {
    try {
      const result = await axiosClient.post('/auth/login', loginInfo);

      dispatch(logging({ isLoading: false }));
      dispatch(logged({ isLogged: true, role: result.role, accessToken: result.accessToken }));

      Toast.fire({
        icon: 'success',
        title: 'Log in success',
      });
      Object.keys(result).forEach((item) => {
        saveState(item, result[item]);
      });
    } catch (err) {
      dispatch(logging({ isLoading: false }));
      if (err.response?.status === 500) {
        Toast.fire({
          icon: 'error',
          title: t('check-connection'),
        });
      } else {
        Toast.fire({
          icon: 'error',
          title: t('check-login-info'),
        });
      }
    }
  };
};

export const logoutAction = () => {
  const cookies = new Cookies();

  cookies.remove('username');
  cookies.remove('role');
  cookies.remove('accessToken');
  return (dispatch) => {
    dispatch(logout(false));
  };
};
