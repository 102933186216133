import { t } from 'i18next';

import { Toast } from './alert';

export const copyFeature = (data, breakLine) => {
  const textArea = document.createElement('textarea');

  textArea.style.position = 'fixed';
  textArea.style.top = 0;
  textArea.style.left = 0;
  textArea.style.width = '2em';
  textArea.style.height = '2em';
  textArea.style.padding = 0;
  textArea.style.border = 'none';
  textArea.style.outline = 'none';
  textArea.style.boxShadow = 'none';
  textArea.style.background = 'transparent';
  textArea.value = data.join(breakLine ? '\n' : '\t');
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
    const successful = navigator.clipboard.writeText(textArea.value);
    if (successful) {
      Toast.fire({ icon: 'success', title: t('copy-text-sucess') });
    } else {
      Toast.fire({ icon: 'error', title: t('copy-text-unsucess') });
    }
  } catch (err) {
    Toast.fire({ icon: 'error', title: t('copy-text-unsucess') });
  }
};
