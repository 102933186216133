import { CaretDownOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import MiniSwitch from 'components/MiniSwitch/MiniSwitch';
import React, { useEffect, useState } from 'react';
import AnswerItem from './AnswerItem';
import SubQuestion from './SubQuestion';
import { t } from 'i18next';
import { Toast } from 'helper/alert';

const QuestionItem = ({
  parentQuestionData,
  language,
  index,
  surveyRecord,
  setSurveyRecord,
  handleOnChange,
  emp,
  listBlockedQuestion,
  setListBlockedQuestion,
  listExpandedQuestionActive,
  listHardRuleQuestion,
  setListHardRuleQuestion,
}) => {
  const [questionData, setQuestionData] = useState({});
  const [activeQuestion, setActiveQuestion] = useState(true);
  const [isLocked, setIsLocked] = useState(false);
  const typeQuestionParent = parentQuestionData.parent_question_type;
  const parentAnswerType = {
    single: 'radio',
    text: 'text',
    multiple: 'checkbox',
    date: 'date',
    time: 'time',
    ['checkbox-text']: 'checkbox-text',
    ['date-only']: 'date-only',
    ['select-week']: 'select-week',
  }[typeQuestionParent];

  const handleActiveQuestion = (value) => {
    setActiveQuestion(value);
    if (!value) {
      setListBlockedQuestion([...listBlockedQuestion, questionData.parentId]);
    } else {
      const updateBlockList = listBlockedQuestion.filter((item) => item !== questionData.parentId);
      setListBlockedQuestion(updateBlockList);
    }
  };
  useEffect(() => {
    if (listBlockedQuestion.includes(questionData.parentId)) {
      setActiveQuestion(false);
    }
  }, [questionData, parentQuestionData]);
  useEffect(() => {
    if (parentQuestionData && Object.keys(parentQuestionData).length) {
      const data = {
        questionContent:
          language === 'NO'
            ? parentQuestionData?.parent_question_no_ver
            : parentQuestionData?.parent_question_content,
        isMandatory: !!parentQuestionData?.parent_question_mandatory,
        description:
          language === 'NO'
            ? parentQuestionData.parent_question_description_no_ver
            : parentQuestionData.parent_question_description,
        answerList:
          language === 'NO'
            ? parentQuestionData.parent_answer_no_ver
            : parentQuestionData.parent_answer_choice,
        questionChoice: parentQuestionData.parent_answer_choice,
        questionType: parentAnswerType,
        child: parentQuestionData.child,
        parentId: parentQuestionData.parent_question_id,
        type: parentQuestionData.type,
      };

      setQuestionData(data);
    }
  }, [parentQuestionData, language]);

  useEffect(() => {
    const questionIndex = listHardRuleQuestion.findIndex(
      (item) => item.question_uuid === parentQuestionData.parent_question_id,
    );
    if (questionIndex > -1 && listHardRuleQuestion[questionIndex].is_hard) {
      setIsLocked(true);
    }
  }, [parentQuestionData, listHardRuleQuestion]);
  return (
    <div>
      {Object.keys(questionData).length &&
      (questionData.type !== 'expaned'
        ? true
        : listExpandedQuestionActive.findIndex(
            (question) => question.questionUUID === questionData.parentId && !!question.isActive,
          ) > -1) ? (
        <>
          <div className="calendar-plan-survey-form__question-header">
            <h3 htmlFor="" className="calendar-plan-survey-form__question">
              {index}. {questionData?.questionContent}
              {questionData?.isMandatory === '1' ? <span style={{ color: 'red' }}>*</span> : ''}
              {Object.keys(questionData?.child)[0] !== 'null' ? <CaretDownOutlined /> : ''}
              <span
                className={`toogle-rule ${isLocked ? 'locked' : 'unlocked'}`}
                onClick={() => {
                  let listHardRuleQuestionUpdate = listHardRuleQuestion;
                  const foundQuestionIndex = listHardRuleQuestionUpdate.findIndex(
                    (item) => questionData.parentId === item.question_uuid,
                  );
                  if (listHardRuleQuestionUpdate[foundQuestionIndex]?.always) {
                    Toast.fire({ icon: 'error', title: t('question-blocked') });
                    return;
                  }
                  if (isLocked && foundQuestionIndex > -1) {
                    listHardRuleQuestionUpdate[foundQuestionIndex].isHard = 0;

                    setIsLocked(false);
                  } else if (foundQuestionIndex > -1) {
                    listHardRuleQuestionUpdate[foundQuestionIndex].isHard = 1;
                    setIsLocked(true);
                  } else {
                    listHardRuleQuestionUpdate.push({
                      question_uuid: parentQuestionData.parent_question_id,
                      isHard: 1,
                    });
                    setIsLocked(true);
                  }
                  setListHardRuleQuestion(listHardRuleQuestionUpdate);
                }}
              >
                {isLocked ? <LockOutlined /> : <UnlockOutlined />}
              </span>
            </h3>
            <MiniSwitch
              obj1={{ value: true, name: t('on') }}
              obj2={{ value: false, name: t('off') }}
              width={100}
              height={30}
              value={activeQuestion}
              setValue={handleActiveQuestion}
            />
          </div>
          {questionData.description ? (
            <>
              <p className="calendar-plan-survey-form__description">{questionData.description}</p>
            </>
          ) : null}
          <div className="calendar-plan-survey-form__answer-group">
            {questionData?.answerList.map((answer, index) => {
              const answerKeys = Object.keys(answer);
              return (
                <AnswerItem
                  key={answer[answerKeys[0]]}
                  parentAnswerType={parentAnswerType}
                  answer={answer}
                  answerKeys={answerKeys}
                  surveyRecord={surveyRecord}
                  questionData={questionData}
                  handleOnChange={handleOnChange}
                  index={index}
                  setSurveyRecord={setSurveyRecord}
                />
              );
            })}
          </div>
          {Object.keys(questionData?.child)[0] !== 'null' &&
          questionData?.child[Object.keys(questionData?.child)[0]].child_parent_answer_id ===
            surveyRecord[questionData?.parentId]?.id ? (
            <>
              {Object.keys(questionData?.child).map((childQuestionKey) => (
                <SubQuestion
                  key={childQuestionKey}
                  childQuestionKey={childQuestionKey}
                  handleOnChange={handleOnChange}
                  parentQuestionData={questionData}
                  language={language}
                  surveyRecord={surveyRecord}
                  setSurveyRecord={setSurveyRecord}
                />
              ))}
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default QuestionItem;
