import { weekdayKey } from 'constant';
import { t } from 'i18next';
import moment from 'moment';
import React, { memo, useEffect, useState } from 'react';
import CalendarPlanFormTableRow from './CalendarPlanFormTableRow/CalendarPlanFormTableRow';
import './CalendarPlanFormTable.scss';

function CalendarPlanFormTable({
  dateRange,
  data,
  allTask,
  handleOnChangePlanDetail,
  handleOnChangeAllValueEachTask,
  handleOnChangeTime,
  handleOnDeleteTask,
  handlePasteWeekStaffPlanDetail,
  tab,
  listGroup,
}) {
  const [taskArr, setTaskArr] = useState();
  const RenderWeek = ({ dateRange }) => {
    let weekPrev = -1;
    return dateRange.map((date) => {
      if (moment(date).week() !== weekPrev && moment(date).weekday() === 0) {
        weekPrev = moment(date).week();
        return (
          <th key={date} className="stick top plan-task-table-week " colSpan={7}>{`${t(
            'week',
          )} ${moment(date).week()}`}</th>
        );
      }
    });
  };

  const RenderDates = ({ dateRange }) => {
    return dateRange.map((date) => {
      const weekday = moment(date).weekday();
      let weekdayIndex = weekday - 1;
      if (weekday === 0) {
        weekdayIndex = 6;
      }
      return (
        <th className="stick  plan-task-table-week plan-task-table-date " key={date}>
          {t(weekdayKey[weekdayIndex])}
        </th>
      );
    });
  };

  const RenderRow = ({ data, listGroup }) => {
    return data?.map((el) => {
      return (
        <CalendarPlanFormTableRow
          key={el.key}
          rowData={el}
          dateRange={dateRange}
          handleOnChangePlanDetail={handleOnChangePlanDetail}
          handleOnChangeAllValueEachTask={handleOnChangeAllValueEachTask}
          handleOnChangeTime={handleOnChangeTime}
          handleDeleteRow={handleDeleteRow}
          handlePasteWeekStaffPlanDetail={handlePasteWeekStaffPlanDetail}
          tab={tab}
          listGroup={listGroup}
        />
      );
    });
  };
  const handleDeleteRow = (value) => {
    const newTaskArr = taskArr.filter((task) => task.key !== value.key);
    handleOnDeleteTask(value);
    setTaskArr(newTaskArr);
  };
  useEffect(() => {
    if (data) {
      const taskList = Object.entries(data.task).map((task) => {
        return { ...task[1], key: task[0] };
      });

      for (let task of taskList) {
        let taskFound = allTask.find((taskF) => taskF.uuid === task.uuid);
        let taskInlistIndex = taskList.findIndex((taskF) => taskF.key === task.key);
        taskList[taskInlistIndex] = { ...task, taskName: taskFound.task_name };
      }
      setTaskArr(taskList);
    }

    return () => {};
  }, [data, allTask]);
  return (
    <div>
      <table className="plan-task-table">
        <thead>
          <tr>
            <th className="record-name stick left" rowSpan={2}>
              {tab === 'staff' ? t('staff-task-list') : t('task-name')}
            </th>
            <th rowSpan={2}>
              <div className="plan-task-table-time">
                <span className="plan-task-table-time__item">{t('from')}</span>
                <span className="plan-task-table-time__item">{t('to')}</span>
              </div>
            </th>
            <RenderWeek dateRange={dateRange} />
          </tr>
          <tr>
            <RenderDates dateRange={dateRange} />
          </tr>
        </thead>
        <tbody>
          <RenderRow data={taskArr} dateRange={dateRange} listGroup={listGroup} />
        </tbody>
      </table>
    </div>
  );
}
export default memo(CalendarPlanFormTable);
