import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { loadState } from 'helper/localStorage';
import { changeThemeAction } from 'redux/global/globalAction';
import './DefaultFooter.scss';

export default function DefaultFooter() {
  const { t } = useTranslation();
  const [theme, setTheme] = useState(loadState('theme' || 'dark'));
  const dispatch = useDispatch();

  return (
    <div className="footer__wrap">
      <div className="coppy-right">
        <span>&copy; 2022 SynPlan - Healthcare Planning System.</span>
      </div>
      <div className="change-theme__wrap">
        <span>{t('choose-theme')}</span>
        <div className="change-theme">
          <select
            className="select-theme"
            value={theme}
            onChange={(e) => {
              setTheme(e.target.value);
              dispatch(changeThemeAction(e.target.value));
            }}
          >
            <option className="select-theme-item" value="dark">
              {t('dark')}
            </option>
            <option className="select-theme-item" value="light">
              {t('light')}
            </option>
          </select>
        </div>
      </div>
    </div>
  );
}
