import { CloseOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import React, { useState } from 'react';

export default function TaskListRowRole({ roleSelected, setRoleSelected, roleList }) {
  const handleOnChangeRole = (e) => {
    const roleFound = roleList.find((item) => item.id == e.target.value);
    if (roleFound) {
      setRoleSelected({ roleName: roleFound.role, role_id: roleFound.id, roleKey: roleFound.key });
    } else {
      setRoleSelected({ roleName: null, role_id: null, roleKey: null });
    }
  };

  const renderRoleOption = (roleList) => {
    return roleList.map((role) => {
      return (
        <option key={`${role.id}-${role.key}`} value={+role.id}>
          {role.key || role.role}
        </option>
      );
    });
  };
  return (
    <div className="task-list-row-role__wrap">
      <div className="create-patient-task-form__group-select">
        <select
          className="create-patient-task-form__select"
          value={roleSelected.roleId}
          onChange={handleOnChangeRole}
        >
          <option value={''}>Choose role</option>
          {renderRoleOption(roleList)}
        </select>
      </div>
    </div>
  );
}
