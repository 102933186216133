import { CloseOutlined } from '@ant-design/icons';
import DatePickerCustom from 'components/DatePicker/DatePickerCustom';
import { t } from 'i18next';
import React from 'react';

const RangeDate = ({ questionId, surveyRecord, setSurveyRecord }) => {
  const survey = { ...surveyRecord };
  if (!survey[questionId]) {
    survey[questionId] = [{ fromDate: new Date(), toDate: new Date(), type: 'date' }];
  } else if (typeof survey[questionId] === 'string') {
    survey[questionId] = JSON.parse(survey[questionId]);
  }
  const deleteDate = (questionId, index) => {
    const surveyUpdate = { ...survey, [questionId]: [] };
    setSurveyRecord(surveyUpdate);
  };
  return survey[questionId].length > 0
    ? survey[questionId].map((range, index) => {
        return (
          <div
            key={`${questionId}-date-survey-${index}`}
            className="calendar-plan-survey-form__date__wrap"
          >
            <div className="calendar-plan-survey-form__date__index">
              {t('range')} {index + 1}:
            </div>
            <div className="calendar-plan-survey-form__date__group">
              <div className="calendar-plan-survey-form__date__item">
                <label htmlFor="">{t('from')}</label>

                <DatePickerCustom
                  showWeekNumbers={true}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                  date={survey[questionId] ? survey[questionId][index]?.fromDate : new Date()}
                  setDate={(value) => {
                    survey[questionId][index] = {
                      ...survey[questionId][index],
                      fromDate: value,
                      type: 'date',
                    };
                    setSurveyRecord(survey);
                  }}
                />
              </div>
              <div className="calendar-plan-survey-form__date__item">
                <label htmlFor="">{t('to')}</label>

                <DatePickerCustom
                  showWeekNumbers={true}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                  date={survey[questionId] ? survey[questionId][index]?.toDate : new Date()}
                  setDate={(value) => {
                    survey[questionId][index] = {
                      ...survey[questionId][index],
                      toDate: value,
                      type: 'date',
                    };
                    setSurveyRecord(survey);
                  }}
                />
              </div>
              <div
                className="calendar-plan-survey-form__date-close"
                onClick={() => {
                  deleteDate(questionId, index);
                }}
              >
                <CloseOutlined />
              </div>
            </div>
          </div>
        );
      })
    : null;
};

export default RangeDate;
