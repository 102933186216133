import React, { lazy, Suspense } from 'react';
import i18n from 'i18next';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { useSelector } from 'react-redux';
import PrivateRoute from './PrivateRoute';
import DefaultLayout from 'layout/Default';
import { initReactI18next } from 'react-i18next';
import { selectGlobal } from 'redux/global/globalSlice';
import translationEN from 'translation/translationEN.json';
import translationNO from 'translation/translationNO.json';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { loadState } from 'helper/localStorage';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';

const LoginPage = lazy(() => import('views/pages/Login'));
const SurveyPage = lazy(() => import('views/pages/Survey'));

import 'scss/style.scss';
import Loading from 'components/Loading/Loading';
import WishList from 'views/pages/WishListSurvey';
import WishListSurvey from 'views/pages/WishListSurvey';
const lang = loadState('language');
i18n.use(initReactI18next).init({
  resources: {
    NO: { translation: translationNO },
    EN: { translation: translationEN },
  },
  lng: lang || 'NO',
  fallbackLng: lang || 'NO',
  interpolation: { escapeValue: false },
});
if (process.env.REACT_APP_NODE_ENV == 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],

    //     // Set tracesSampleRate to 1.0 to capture 100%
    //     // of transactions for performance monitoring.
    //     // We recommend adjusting this value in production
    //     tracesSampleRate: 1.0,
  });
}
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const { theme } = useSelector(selectGlobal);
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App" data-theme={theme}>
        <BrowserRouter>
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/survey/:id" element={<SurveyPage />} />
              <Route
                path="/wish-list/:department/:wishListId/:empId"
                element={<WishListSurvey />}
              />
              <Route
                path="/*"
                element={
                  <PrivateRoute>
                    <DefaultLayout />
                  </PrivateRoute>
                }
              />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </div>
    </QueryClientProvider>
  );
}

export default App;
