import React, { useEffect, useState } from 'react';
import SingleDateRange from './SingleDateRange';
import NewSingleDateRange from './NewSingleDateRange';
import RangeDate from './RangeDate';
import NewRangeDate from './NewRangeDate';

const SubQuestion = ({
  parentQuestionData,
  language,
  childQuestionKey,

  surveyRecord,
  setSurveyRecord,
  handleOnChange,
  disabled,
}) => {
  const [childQuestionData, setChildQuestionData] = useState({});
  const childQuestion = parentQuestionData.child[childQuestionKey];
  const typeQuestionSub = childQuestion.child_question_type;

  const getChildAnswerType = () => {
    switch (typeQuestionSub) {
      case 'single':
      case 'single-date':
        return 'radio';
      case 'text':
        return 'text';
      case 'multiple':
        return 'checkbox';
      case 'date':
        return 'date';
      default:
        return 'radio';
    }
  };

  useEffect(() => {
    if (childQuestion && Object.keys(childQuestion).length) {
      const data = {
        questionContent:
          language === 'NO'
            ? childQuestion.child_question_no_ver
            : childQuestion.child_question_content,
        description:
          language === 'NO'
            ? childQuestion.child_question_description_no_ver
            : childQuestion.child_question_description,
        answerList: childQuestion.child_answer_no_ver,
        questionChoice: childQuestion.child_answer_choice,
        questionType: getChildAnswerType(),
        questionId: childQuestion.child_question_id,
      };

      setChildQuestionData(data);
    }
  }, [parentQuestionData, language]);

  const isChecked = (choiceValue) => {
    const selectedValues = surveyRecord[childQuestion?.child_question_id] || {};
    if (Array.isArray(selectedValues)) {
      {
        return selectedValues.some((item) => item.en === choiceValue || item.no === choiceValue);
      }
    } else if (typeQuestionSub === 'single-date') {
      return selectedValues.single?.en === choiceValue || selectedValues.single?.no === choiceValue;
    } else {
      return selectedValues.en === choiceValue || selectedValues.no === choiceValue;
    }
  };

  const renderAnswerInput = (choice, choiceValue, value, answerChoice) => {
    const survey = { ...surveyRecord };

    return (
      <div key={choice} className="calendar-plan-survey-form__answer-item">
        {value === 'date' && childQuestion ? (
          <RangeDate
            questionId={childQuestion?.child_question_id}
            surveyRecord={surveyRecord}
            setSurveyRecord={setSurveyRecord}
          />
        ) : null}
        {value === 'date' ? (
          <NewRangeDate
            questionId={childQuestion?.child_question_id}
            surveyRecord={surveyRecord}
            setSurveyRecord={setSurveyRecord}
          />
        ) : null}
        {value !== 'text' && value !== 'date' ? (
          <div>
            <input
              type={value}
              name={childQuestion?.child_question_id}
              id={`${choice}-${childQuestion?.child_question_id}`}
              value={choiceValue}
              onChange={(e) => {
                if (value === 'checkbox') {
                  if (!survey[childQuestion?.child_question_id]) {
                    survey[childQuestion?.child_question_id] = [];
                  }
                  const valueIndex = survey[childQuestion?.child_question_id].findIndex(
                    (item) => item.en === choiceValue || item.no === choiceValue,
                  );
                  if (valueIndex > -1) {
                    const valueUpdate = survey[childQuestion?.child_question_id];
                    valueUpdate.splice(valueIndex, 1);
                    setSurveyRecord({
                      ...survey,
                      [childQuestion?.child_question_id]: valueUpdate,
                    });
                  } else {
                    const valueUpdate = survey[childQuestion?.child_question_id];
                    valueUpdate.push(answerChoice);
                    setSurveyRecord({
                      ...survey,
                      [childQuestion?.child_question_id]: valueUpdate,
                    });
                  }
                } else if (typeQuestionSub === 'single-date') {
                  if (!survey[childQuestion?.child_question_id]) {
                    survey[childQuestion?.child_question_id] = {
                      date: [],
                      single: {},
                      type: 'single-date',
                    };
                  }
                  const valueUpdate = {
                    ...survey,
                    [childQuestion?.child_question_id]: {
                      ...survey[childQuestion?.child_question_id],
                      single: answerChoice,
                    },
                  };

                  setSurveyRecord(valueUpdate);
                } else {
                  setSurveyRecord({
                    ...survey,
                    [childQuestion?.child_question_id]: answerChoice,
                  });
                }
              }}
              checked={isChecked(choiceValue)}
            />
            <label htmlFor={`${choice}-${childQuestion?.child_question_id}`}>{choice}</label>
          </div>
        ) : null}
        {value === 'text' ? (
          <input
            type={value}
            name={childQuestion?.child_question_id}
            id={`${choice}-${childQuestion?.child_question_id}`}
            value={surveyRecord[childQuestion.child_question_id] || ''}
            onChange={handleOnChange}
          />
        ) : null}
      </div>
    );
  };

  return (
    <div key={childQuestionKey} className="calendar-plan-survey-form__sub-question">
      <h4 htmlFor="" className="calendar-plan-survey-form__question">
        +{' '}
        {language === 'NO'
          ? childQuestion?.child_question_no_ver
          : childQuestion?.child_question_content}
      </h4>
      {childQuestion.child_question_description ? (
        <p className="calendar-plan-survey-form__description">
          {language === 'NO'
            ? childQuestion.child_question_description_no_ver
            : childQuestion.child_question_description}
        </p>
      ) : null}
      <div className="calendar-plan-survey-form__answer-group">
        {language === 'NO'
          ? childQuestion?.child_answer_no_ver.split(',').map((choice, index) => {
              const choiceValue = childQuestion?.child_answer_choice.split(',')[index];
              const value = {
                en: choiceValue,
                no: choice,
                type: childQuestion.child_question_type,
              };
              return renderAnswerInput(choice, choiceValue, getChildAnswerType(), value);
            })
          : childQuestion?.child_answer_choice.split(',').map((choice, index) => {
              const choiceValue = childQuestion?.child_answer_no_ver.split(',')[index];
              const value = {
                en: choice,
                no: choiceValue,
                type: childQuestion.child_question_type,
              };
              return renderAnswerInput(choice, choiceValue, getChildAnswerType(), value);
            })}
      </div>
      {typeQuestionSub === 'single-date' ? (
        <div className="date-child">
          <SingleDateRange
            questionId={childQuestion?.child_question_id}
            setSurveyRecord={setSurveyRecord}
            surveyRecord={surveyRecord}
          />
          <NewSingleDateRange
            questionId={childQuestion?.child_question_id}
            setSurveyRecord={setSurveyRecord}
            surveyRecord={surveyRecord}
          />
        </div>
      ) : null}
    </div>
  );
};

export default SubQuestion;
