import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import './CalendarPlanWishListTable.scss';
import { t } from 'i18next';
import { useDispatch } from 'react-redux';
import { updateWishList, updateWishListSurvey } from 'redux/calendarPlan/calendarPlanSlice';

const CalendarPlanWishListTable = ({ dataTable }) => {
  const dispatch = useDispatch();

  const [flatTableData, setFlatTableData] = useState(dataTable);
  const [data, setData] = useState([]);
  const [taskArr, setTaskArr] = useState([
    {
      taskName: 'A',
      taskId: 1,
      timelotId: 45,
      hourAssigned: 7,
      id: '1-45',
    },
    {
      taskName: 'ASK',
      taskId: 3,
      timelotId: 46,
      hourAssigned: 7,
      id: '3-46',
    },
    {
      taskName: 'D',
      taskId: 5,
      timelotId: 49,
      hourAssigned: 7,
      id: '5-49',
    },
    {
      taskName: 'DK',
      taskId: 6,
      timelotId: 44,
      hourAssigned: 7,
      id: '6-44',
    },
    {
      taskName: 'DSK',
      taskId: 7,
      timelotId: 44,
      hourAssigned: 7,
      id: '7-44',
    },
    {
      taskName: 'FAG',
      taskId: 11,
      timelotId: 58,
      hourAssigned: 8,
      id: '11-58',
    },
    {
      taskName: 'LK',
      taskId: 14,
      timelotId: 53,
      hourAssigned: 12,
      id: '14-53',
    },
    {
      taskName: 'LK1',
      taskId: 15,
      timelotId: 55,
      hourAssigned: 12,
      id: '15-55',
    },
    {
      taskName: 'LV',
      taskId: 17,
      timelotId: 53,
      hourAssigned: 12,
      id: '17-53',
    },
    {
      taskName: 'N',
      taskId: 21,
      timelotId: 52,
      hourAssigned: 9,
      id: '21-52',
    },
    {
      taskName: 'PM',
      taskId: 23,
      timelotId: 50,
      hourAssigned: 8,
      id: '23-50',
    },
    {
      taskName: 'TA',
      taskId: 24,
      timelotId: 45,
      hourAssigned: 7,
      id: '24-45',
    },
    {
      taskName: 'TD',
      taskId: 27,
      timelotId: 44,
      hourAssigned: 7,
      id: '27-44',
    },
  ]);

  const handleOnChange = (e, cell) => {
    const updateCell = {
      ...cell,
      code: e.target.value,
    };

    const foundedIndex = dataTable?.findIndex((item) => {
      return item.date === cell.date && item.name === cell.name;
    });

    let newArr = [...flatTableData];
    newArr[foundedIndex] = updateCell;

    dispatch(updateWishListSurvey({ data: updateCell }));
    setFlatTableData(newArr);
  };

  useEffect(() => {
    const arr = [];
    let counter = 0;
    let indexArr = 0;
    flatTableData?.forEach((item) => {
      if (counter++ === 0) {
        arr.push({
          key: uuidv4(),
          data: [],
        });
        indexArr++;
      } else if (counter >= 7) {
        counter = 0;
      }
      arr[indexArr - 1].data.push(item);
    });
    setData(arr);
  }, [flatTableData]);

  useEffect(() => {
    setFlatTableData(dataTable);
  }, [dataTable]);

  return (
    <div className="calendar-plan-wish-list__wrap">
      <table className="calendar-plan-wish-list__table">
        <thead>
          <th>{t('mon')}</th>
          <th>{t('tue')}</th>
          <th>{t('wed')}</th>
          <th>{t('thu')}</th>
          <th>{t('fri')}</th>
          <th
            style={{
              color: '#dc056d',
            }}
          >
            {t('sat')}
          </th>
          <th
            style={{
              color: '#dc056d',
            }}
          >
            {t('sun')}
          </th>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.key}>
              {row.data.map((col, index) => {
                return (
                  <td className={index >= 5 ? 'day-off' : null} key={col.date}>
                    <div className="col-item">
                      <span className="col-item__date text-light">{col.date}</span>
                      <select
                        className={`kalender-plan-content-table-input text-bold`}
                        defaultValue={col.code}
                        onChange={(e) => handleOnChange(e, col)}
                      >
                        <option value={''}>{col.code ? col.code : '-'}</option>
                        {taskArr?.map((task) => {
                          return (
                            <option value={task.taskName} key={`task.uuid-${task.id}`}>
                              {task.taskName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CalendarPlanWishListTable;
