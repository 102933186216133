import { t } from 'i18next';
import React, { useState } from 'react';
import employeesIconLight from 'assets/calendar-plan/employeesIconLight.svg';
import employeesIconDark from 'assets/calendar-plan/employeesIconDark.svg';
import patitentsTaskLight from 'assets/calendar-plan/patitentsTaskLight.svg';
import patitentsTaskDark from 'assets/calendar-plan/patitentsTaskDark.svg';
import staffPlanLight from 'assets/calendar-plan/staffPlanLight.svg';
import staffPlanDark from 'assets/calendar-plan/staffPlanDark.svg';
import calendarPlanLight from 'assets/calendar-plan/calendarPlanLight.svg';
import calendarPlanDark from 'assets/calendar-plan/calendarPlanDark.svg';
import './CalendarPlanAdminHeader.scss';
import { useSelector } from 'react-redux';
import { selectGlobal } from 'redux/global/globalSlice';
export default function CalendarPlanAdminSideHeader({ menuActive, setMenuActive }) {
  const [isActive, setIsActive] = useState(true);
  const { theme } = useSelector(selectGlobal);
  const menuSide = [
    {
      key: 'employee',
      name: 'survey-header',
      icon: theme === 'dark' ? employeesIconDark : employeesIconLight,
    },

    {
      key: 'working-environment-act',
      name: 'working-environment-act',
      icon: theme === 'dark' ? patitentsTaskDark : patitentsTaskLight,
    },
    {
      key: 'soft-rules',
      name: 'soft-rules',
      icon: theme === 'dark' ? patitentsTaskDark : patitentsTaskLight,
    },
    {
      key: 'staff-plan',
      name: 'staff-plan',
      icon: theme === 'dark' ? staffPlanDark : staffPlanLight,
    },
    {
      key: 'generator',
      name: 'generator',
      icon: theme === 'dark' ? calendarPlanDark : calendarPlanLight,
    },
  ];

  const renderMenuActive = (arrMenu) => {
    return (
      <>
        {arrMenu.map((menu) => {
          return (
            <div
              key={menu.key}
              className={`calendar-plan-header__item ${menuActive === menu.key ? 'active' : ''}`}
              onClick={() => {
                setMenuActive(menu.key);
              }}
            >
              <div className="menu-icon">
                <img src={menu.icon} alt="" />
              </div>
              <div className="menu-title">{t(menu.name)}</div>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div className={`calendar-plan-header__wrap ${isActive ? 'active' : ''}`}>
      {renderMenuActive(menuSide)}
    </div>
  );
}
