import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import './MultipleTab.scss';

const RenderMultipleTab = ({ data, activeTab, setActiveTab }) => {
  return data.map((item) => {
    return (
      <div
        key={uuidv4()}
        className={`multiple-tab__item ${activeTab === item.label ? 'active' : ''}`}
        onClick={() => {
          setActiveTab(item.label);
        }}
      >
        <span>{t(item.label)}</span>
      </div>
    );
  });
};

const MultipleTab = ({ objs, activeTab, setActiveTab }) => {
  useEffect(() => {
    if (objs.length) {
      setActiveTab(objs[0].label);
    }
  }, []);
  return (
    <div className="multiple-tab__wrap">
      <div className="multiple-tab">
        <RenderMultipleTab data={objs} activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>
    </div>
  );
};

export default MultipleTab;
