import React, { memo, useEffect, useState } from 'react';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import './DatePicker.scss';
import 'react-datepicker/dist/react-datepicker.css';
import nb from 'date-fns/locale/nb';
import { useSelector } from 'react-redux';
import { selectGlobal } from 'redux/global/globalSlice';

const DatePickerCustom = ({ date, setDate, disabled, isNotAutofocus }, ref) => {
  const [dateChange, setDateChange] = useState(date);
  const { language } = useSelector(selectGlobal);
  if (language === 'NO') {
    registerLocale('nb', nb);
  }

  useEffect(() => {
    if (date !== 'Invalid Date') {
      setDateChange(date);
    } else {
      setDateChange(new Date());
    }
  }, [date]);
  return (
    <div className="date-picker-custom">
      <DatePicker
        disabled={disabled}
        selected={dateChange}
        showMonthDropdown={true}
        showYearDropdown={true}
        dropdownMode="select"
        onChange={(datePick) => {
          setDate(datePick);
        }}
        onChangeRaw={(e) => {
          const inputDate = e.target.value;
          if (inputDate) {
            const formattedDate = inputDate.replace(/^(\d{2})(\d{2})(\d{4})$/, '$1/$2/$3');
            if (inputDate.length >= 8 && !isNaN(inputDate)) {
              e.target.value = formattedDate;
            }
          }
        }}
        showWeekNumbers={true}
        dateFormat="dd/MM/yyyy"
        locale={language === 'NO' ? 'nb' : 'en'}
        calendarStartDay={1}
        autoFocus={!dateChange && !isNotAutofocus ? true : false}
      />
    </div>
  );
};
export default DatePickerCustom;
