import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import React from 'react';
import './Pagination.scss';
export default function Pagination({ page, setPage, totalPage }) {
  const pageShow = [1, 2, page, totalPage - 1, totalPage];
  const renderPaginationItem = () => {
    if (totalPage <= 6) {
      return Array.from({ length: +totalPage }).map((item, index) => {
        return (
          <div
            key={index}
            className={`pagination-item ${+page === index + 1 ? 'active' : ''}`}
            onClick={() => {
              setPage(index + 1);
            }}
          >
            {index + 1}
          </div>
        );
      });
    }
    return Array.from({ length: +totalPage }).map((item, index) => {
      if (index + 1 === totalPage - 2 && page === totalPage - 2) {
        return (
          <div
            key={index}
            className={`pagination-item ${+page === index + 1 ? 'active' : ''}`}
            onClick={() => {
              setPage(index + 1);
            }}
          >
            <span className="pagination-item"> ... </span>
            {index + 1}
          </div>
        );
      }
      if (index + 1 === totalPage - 1 && page >= totalPage - 1) {
        return (
          <div
            key={index}
            className={`pagination-item ${+page === index + 1 ? 'active' : ''}`}
            onClick={() => {
              setPage(index + 1);
            }}
          >
            <span className="pagination-item"> ... </span>
            {index + 1}
          </div>
        );
      }
      if (index + 1 <= 3 && page <= 3 && index + 1 > 2) {
        return (
          <div
            key={index}
            className={`pagination-item ${+page === index + 1 ? 'active' : ''}`}
            onClick={() => {
              setPage(index + 1);
            }}
          >
            {index + 1}
            <span className="pagination-item"> ... </span>
          </div>
        );
      }

      if (page > 3 && page < totalPage - 1 && index + 1 === page) {
        return (
          <div
            key={index}
            className={`pagination-item ${+page === index + 1 ? 'active' : ''}`}
            onClick={() => {
              setPage(index + 1);
            }}
          >
            <span className="pagination-item"> ... </span>
            {index + 1}
            <span className="pagination-item"> ... </span>
          </div>
        );
      }

      if (pageShow.includes(index + 1)) {
        return (
          <div
            key={index}
            className={`pagination-item ${+page === index + 1 ? 'active' : ''}`}
            onClick={() => {
              setPage(index + 1);
            }}
          >
            {index + 1}
          </div>
        );
      }
    });
  };
  return (
    <div className="pagination__wrap">
      <div className="pagination-pre">
        <LeftOutlined
          onClick={() => {
            if (+page > 1 && +page <= +totalPage) {
              setPage(page - 1);
            }
          }}
        />
      </div>
      <div className="pagination-group">{renderPaginationItem()}</div>
      <div className="pagination-next">
        <RightOutlined
          onClick={() => {
            if (+page >= 1 && +page < +totalPage) {
              setPage(page + 1);
            }
          }}
        />
      </div>
    </div>
  );
}
