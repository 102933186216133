import { loadState } from 'helper/localStorage';

const site = process.env.REACT_APP_SITE;
const role = loadState('role');
//add role to each obj
const baseNav = [
  {
    key: 'dash-board',
    to: '/dashboard',
    name: 'dashboard-upcase',
    roles: ['planner', 'admin', 'economist', 'partner'],
  },
  {
    key: 'planning',
    to: '/planning',
    name: 'planning-upcase',
    roles: ['planner', 'admin'],
  },

  // {
  //   key: 'evaluation',
  //   to: '/evaluation',
  //   name: 'evaluation-upcase',
  //   roles: ['planner', 'admin', 'economist', 'partner'],
  // },
  {
    key: 'analyse',
    to: '/analyse',
    name: 'analyse-upcase',
    roles: ['planner', 'admin'],
  },

  {
    key: 'help',
    to: '/help',
    name: 'help-upcase',
    roles: ['planner', 'admin', 'partner'],
  },
];
// const trondheimNav = [...baseNav];
const bluebirdcarersNav = [...baseNav];
const heathlandsvillageNav = [...baseNav];
const falcareNav = [...baseNav];
const malvikNav = [...baseNav];
const demoNav = [
  {
    key: 'dash-board',
    to: '/dashboard',
    name: 'dashboard-upcase',
    roles: ['planner', 'admin', 'economist', 'partner'],
  },
  {
    key: 'planning',
    to: '/planning',
    name: 'planning-upcase',
    roles: ['planner', 'admin'],
  },
  {
    key: 'calendar',
    to: '/calendar',
    name: 'calendar-upcase',
    roles: ['planner', 'admin', 'partner'],
  },

  {
    key: 'analyse',
    to: '/analyse',
    name: 'analyse-upcase',
    roles: ['planner', 'admin'],
  },

  {
    key: 'help',
    to: '/help',
    name: 'help-upcase',
    roles: ['planner', 'admin', 'partner'],
  },
];
const trondheimNav = [...baseNav];

const indrefosenNav = [
  {
    key: 'dash-board',
    to: '/dashboard',
    name: 'dashboard-upcase',
    roles: ['planner', 'admin', 'economist'],
  },

  {
    key: 'planning',
    to: '/planning',
    name: 'planning-upcase',
    roles: ['planner', 'admin'],
  },

  {
    key: 'analyse',
    to: '/analyse',
    name: 'analyse-upcase',
    roles: ['planner', 'admin'],
  },
  {
    key: 'help',
    to: '/help',
    name: 'help-upcase',
    roles: ['planner', 'admin'],
  },
];

const osloNav = [
  {
    key: 'dash-board',
    to: '/dashboard',
    name: 'dashboard-upcase',
    roles: ['planner', 'admin', 'economist', 'partner'],
  },
  {
    key: 'planning',
    to: '/planning',
    name: 'planning-upcase',
    roles: ['planner', 'admin'],
  },
  {
    key: 'calendar',
    to: '/calendar',
    name: 'calendar-upcase',
    roles: ['planner', 'admin', 'partner'],
  },

  {
    key: 'analyse',
    to: '/analyse',
    name: 'analyse-upcase',
    roles: ['planner', 'admin'],
  },

  {
    key: 'help',
    to: '/help',
    name: 'help-upcase',
    roles: ['planner', 'admin', 'partner'],
  },
];
const hitraNav = [
  {
    key: 'dash-board',
    to: '/dashboard',
    name: 'dashboard-upcase',
    roles: ['planner', 'admin', 'economist'],
  },
  {
    key: 'planning',
    to: '/planning',
    name: 'planning-upcase',
    roles: ['planner', 'admin'],
  },

  {
    key: 'analyse',
    to: '/analyse',
    name: 'analyse-upcase',
    roles: ['planner', 'admin'],
  },
];

const diakonhjemmetNav = [...baseNav];
let _nav = trondheimNav;

switch (site) {
  case 'trondheim':
    _nav = trondheimNav;
    break;

  case 'indrefosen':
    _nav = indrefosenNav;
    break;

  case 'oslo':
    _nav = osloNav;
    break;

  case 'hitra':
    _nav = hitraNav;
    break;

  case 'diakonhjemmet':
    _nav = diakonhjemmetNav;
    break;

  case 'demo':
    _nav = demoNav;
    break;
  case 'bluebirdcarers':
    _nav = bluebirdcarersNav;
    break;
  case 'heathlandsvillage':
    _nav = heathlandsvillageNav;
    break;
  case 'falcare':
    _nav = falcareNav;
    break;
  case 'malvik':
    _nav = malvikNav;
    break;

  default:
    _nav = demoNav;
    break;
}

export default _nav;
