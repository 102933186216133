import React from 'react';
import './ProgressBar.scss';
const ProgressBar = ({ value }) => {
  const styling = {
    width: `${value}%` || 0,
  };

  return (
    <div className="progress-bar__wrap">
      <div className="progress" style={styling}></div>
    </div>
  );
};

export default ProgressBar;
