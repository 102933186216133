import { CloseOutlined } from '@ant-design/icons';
import axiosClient from 'axios/axiosClient';
import { Toast } from 'helper/alert';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectGlobal } from 'redux/global/globalSlice';
import './CreateStaffTaskForm.scss';
export default function CreateStaffTaskForm({
  defaultTask,
  setTaskList,
  setActiveCreateForm,
  unitSelected,
  roleList,
  isUpdated,
  setIsUpdated,
  listTaskType,
  listGroup,
}) {
  const [taskWillCreate, setTaskWillCreate] = useState({
    unit_code: unitSelected,
  });
  const [roleSelected, setRoleSelected] = useState({});

  const renderRoleOption = (roleList) => {
    return roleList.map((role) => {
      return (
        <option key={role.id} value={role.id}>
          {role.role}
        </option>
      );
    });
  };

  const renderTaskTypeOption = (listTaskType) => {
    return listTaskType.map((type) => {
      return (
        <option key={`${type.key}-${type.name}`} value={type.id}>
          {t(type.key)}
        </option>
      );
    });
  };
  const renderTaskGroupOption = (listGroup) => {
    return listGroup.map((type) => {
      return (
        <option key={`${type.key}-${type.name}`} value={type.id}>
          {t(type.name)}
        </option>
      );
    });
  };
  const handleOnChange = (e) => {
    setTaskWillCreate({ ...taskWillCreate, [e.target.name]: e.target.value });
  };
  const handleOnChangeRole = (e) => {
    const roleFound = roleList.find((item) => item.id == e.target.value);
    setRoleSelected({ roleName: roleFound.role, role_id: roleFound.id });
  };
  const handleOnSubmit = async (e) => {
    e.preventDefault();
    if (
      taskWillCreate.unit_code &&
      taskWillCreate.type_id.length &&
      taskWillCreate.from_time &&
      taskWillCreate.task_name
    ) {
      await axiosClient
        .post('calendar-plan/staff-task/create', {
          taskWillCreate: taskWillCreate,
          role: roleSelected,
        })
        .then((_) => {
          axiosClient
            .post('calendar-plan/staff-task', { unitCode: unitSelected, page: 1 })
            .then((result) => {
              setTaskList(result.data);
              setActiveCreateForm(false);
              setIsUpdated(!isUpdated);
              Toast.fire({ icon: 'success', title: `${t('create')} ${t('success')}` });
            })
            .catch((err) => {
              Toast.fire({ icon: 'error', title: `${t('create')} ${t('unsuccess')}` });
            });
        });
    } else {
      Toast.fire({ icon: 'error', title: `${t('create')} ${t('unsuccess')}` });
    }
  };
  useEffect(() => {
    if (defaultTask) {
      setTaskWillCreate(defaultTask);
    }
  }, []);
  return (
    <div className="create-staff-task-form__wrap">
      <div className="create-staff-task-form__content">
        <div className="create-staff-task-form__header">
          <CloseOutlined
            onClick={() => {
              setActiveCreateForm(false);
            }}
          />
        </div>

        <form onSubmit={handleOnSubmit} className="create-staff-task-form">
          <div className="create-staff-task-form__group">
            <label htmlFor="" className="create-staff-task-form__label">
              {t('task-name')}
            </label>
            <input
              className="create-staff-task-form__input"
              placeholder={t('enter-task-name')}
              onChange={handleOnChange}
              name="task_name"
              required
            />
          </div>
          <div className="create-staff-task-form__group">
            <div className="create-staff-task-form__group-time__wrap">
              <div className="create-staff-task-form__group-time">
                <label className="create-staff-task-form__group-time__label">
                  {t('from-date')}
                </label>
                <input
                  type="time"
                  className="create-staff-task-form__input-time"
                  name="from_time"
                  id=""
                  onChange={handleOnChange}
                  required
                />
              </div>
              <div className="create-staff-task-form__group-time">
                <label className="create-staff-task-form__group-time__label">{t('to-date')}</label>
                <input
                  type="time"
                  className="create-staff-task-form__input-time"
                  name="to_time"
                  id=""
                  onChange={handleOnChange}
                  required
                />
              </div>
            </div>
          </div>
          <div className="create-staff-task-form__group">
            <div className="create-staff-task-form__group-select__wrap">
              <div className="create-staff-task-form__group-select">
                <label htmlFor="" className="create-staff-task-form__label">
                  {t('role')}
                </label>
                <select
                  className="create-staff-task-form__select"
                  value={roleSelected.id}
                  onChange={handleOnChangeRole}
                >
                  <option value={''}>{t('choose-role')}</option>
                  {renderRoleOption(roleList)}
                </select>
              </div>
              <div className="create-staff-task-form__group-select">
                <label htmlFor="" className="create-staff-task-form__label">
                  {t('Type')}
                </label>
                <select
                  className="create-staff-task-form__select"
                  value={taskWillCreate.type_id}
                  onChange={handleOnChange}
                  name="type_id"
                  required
                >
                  <option value={''}>{t('choose-type')}</option>
                  {renderTaskTypeOption(listTaskType)}
                </select>
              </div>
            </div>
          </div>
          <div className="create-staff-task-form__group-select">
            <label htmlFor="" className="create-staff-task-form__label">
              {t('group')}
            </label>
            <select
              className="create-staff-task-form__select"
              value={taskWillCreate.group_id}
              onChange={handleOnChange}
              name="group_id"
              required
            >
              <option value={''}>{t('choose-group')}</option>
              {renderTaskGroupOption(listGroup)}
            </select>
          </div>
          <div className="create-staff-task-form__group">
            <label htmlFor="" className="create-staff-task-form__label">
              {t('description')}
            </label>
            <textarea
              className="create-staff-task-form__textarea"
              placeholder={t('enter-description')}
              name="description"
              onChange={handleOnChange}
            />
          </div>
          <div className="create-staff-task-form__submit">
            <button
              type="button"
              className="custom-btn none-bg"
              onClick={() => {
                setActiveCreateForm(false);
              }}
            >
              {t('cancel')}
            </button>
            <button type="submit" className="custom-btn">
              {t('create-task')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
