import SwitchButton from 'components/SwitchButton/SwitchButton';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import CalendarPlanEmployee from '../CalendarPlanEmployee/CalendarPlanEmployee';

import './CalendarPlanSurvey.scss';
import CalendarPlanWishList from '../CalendarPlanWishList/CalendarPlanWishList';
import axiosClient from 'axios/axiosClient';
import { SWITCH_BUTON_CALENDAR_EMPLOYEE } from 'constant';

const CalendarPlanSurvey = ({ unitSelected }) => {
  const [activeTab, setActiveTab] = useState('survey');
  const [hideComponent, setHideComponent] = useState(false);

  const wishListObj = {
    name: `${t('wish-list')}`,
    value: 'wish-list',
  };
  const surveyObj = {
    name: `${t('survey')}`,
    value: 'survey',
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'wish-list':
        return <CalendarPlanWishList unitSelected={unitSelected} />;
      case 'survey':
        return <CalendarPlanEmployee unitSelected={unitSelected} />;

      default:
        return <CalendarPlanWishList unitSelected={unitSelected} />;
    }
  };
  useEffect(() => {
    axiosClient
      .post('hide-component', {
        unitCode: unitSelected,
        componentKey: SWITCH_BUTON_CALENDAR_EMPLOYEE,
      })
      .then((res) => {
        if (res) {
          setHideComponent(res);
        }
      });
  }, [unitSelected]);
  return (
    <div className="calendar-plan-survey">
      {hideComponent.componentHide == null ? (
        <div className="switch-btn switch__wrap">
          <SwitchButton
            obj2={wishListObj}
            obj1={surveyObj}
            value={activeTab}
            setValue={setActiveTab}
          />
        </div>
      ) : null}
      <div className="calendar-plan-survey__content">{renderContent()}</div>
    </div>
  );
};

export default CalendarPlanSurvey;
