import { CheckOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import axiosClient from 'axios/axiosClient';
import { Toast } from 'helper/alert';
import { t } from 'i18next';
import React, { useState } from 'react';

export const CalendarPlanGroupRow = ({ defaultGroup, isUpdate, setIsUpdate }) => {
  const [group, setgroup] = useState(defaultGroup);
  const [isEditing, setIsEditing] = useState(false);

  const handleOnChange = (e) => {
    setgroup({ ...group, [e.target.name]: e.target.value });
  };
  const handleUpdate = (e) => {
    let isValid = true;
    for (const key of Object.keys(group)) {
      if (group[key]?.length == 0 && key !== 'description') {
        isValid = false;
      }
    }
    if (isValid) {
      axiosClient
        .post('calendar-plan/group/update', { group })
        .then((_) => {
          Toast.fire({ icon: 'success', title: `${t('update-group')} ${t('success')}` });
          setIsUpdate(!isUpdate);
          setIsEditing(false);
        })
        .catch((err) => {
          Toast.fire({ icon: 'error', title: `${t('update-group')} ${t('unsuccess')}` });
        });
    } else {
      Toast.fire({ icon: 'error', title: `${t('please-input-all')} ` });
    }
  };
  const handleDelete = (e) => {
    axiosClient
      .post('calendar-plan/group/delete', { group })
      .then((_) => {
        Toast.fire({ icon: 'success', title: `${t('delete-group')} ${t('success')}` });
        setIsUpdate(!isUpdate);
      })
      .catch((err) => {
        console.log(err);
        setIsUpdate(!isUpdate);
        Toast.fire({ icon: 'error', title: `${t('delete-group')} ${t('unsuccess')}` });
      });
  };
  return (
    <tr className="calendar-plan-create-role__item">
      <td className="calendar-plan-create-role__item-label">
        <input
          name="group"
          disabled={!isEditing}
          type="text"
          value={group.name}
          onChange={handleOnChange}
          required
        />
      </td>
      <td className="calendar-plan-create-role__item-label">
        <input
          name="key"
          disabled={!isEditing}
          type="text"
          value={group.key}
          required
          onChange={handleOnChange}
        />
      </td>

      <td className="calendar-plan-create-role__item-description">
        <input
          name="description"
          disabled={!isEditing}
          type="text"
          value={group.description || t('no-description')}
          onChange={handleOnChange}
        />
      </td>
      <td className="calendar-plan-create-role__item-action">
        {isEditing ? (
          <CheckOutlined onClick={handleUpdate} />
        ) : (
          <EditOutlined
            onClick={() => {
              setIsEditing(true);
            }}
          />
        )}

        <DeleteOutlined onClick={handleDelete} />
      </td>
    </tr>
  );
};
