import { t } from 'i18next';
import moment from 'moment';
import React from 'react';

const NewSingleDateRange = ({ questionId, surveyRecord, setSurveyRecord }) => {
  const survey = { ...surveyRecord };

  if (!survey[questionId]) {
    survey[questionId] = { date: [], single: {}, type: 'single-date' };
  }

  if (!survey[questionId].date) {
    survey[questionId].date = [];
  }
  let index = survey[questionId].date.length;

  return (
    <div key={`${questionId}-sub-single-date`} className="calendar-plan-survey-form__date__wrap">
      <div className="calendar-plan-survey-form__date__index">{t('new-range')}:</div>
      <div className="calendar-plan-survey-form__date__group">
        <div className="calendar-plan-survey-form__date__item">
          <label htmlFor="">{t('from')}</label>
          <input
            type="date"
            name=""
            id=""
            onChange={(e) => {
              const { value } = e.target;
              survey[questionId].date[index] = {
                ...survey[questionId].date[index],
                fromDate: new Date(value).toISOString(),
                type: 'date',
              };

              if (moment(value).year() > 1970) {
                setSurveyRecord(survey);
                e.target.value = null;
              }
            }}
          />
        </div>
        <div className="calendar-plan-survey-form__date__item">
          <label htmlFor="">{t('to')}</label>
          <input
            type="date"
            name=""
            id=""
            onChange={(e) => {
              const { value } = e.target;
              survey[questionId].date[index] = {
                ...survey[questionId].date[index],
                toDate: new Date(value).toISOString(),
                type: 'date',
              };

              if (moment(value).year() > 1970) {
                setSurveyRecord(survey);
                e.target.value = null;
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default NewSingleDateRange;
