import { t } from 'i18next';
import React from 'react';
import PlanListRow from '../PlanListRow/PlanListRow';

import './CalendarStaffPlanListTable.scss';

export default function CalendarStaffPlanListTable({
  planList,
  isUpdated,
  setIsUpdated,
  unitSelected,
}) {
  const renderPlan = () => {
    return planList.map((plan) => {
      return (
        <PlanListRow
          key={plan.uuid}
          planDefault={plan}
          isUpdated={isUpdated}
          setIsUpdated={setIsUpdated}
          unitSelected={unitSelected}
        />
      );
    });
  };
  return (
    <div className="calendar-staff-table__wrap">
      <table className="calendar-staff-table">
        <thead>
          <th>{t('plan-name')}</th>
          <th>
            <div className="time__wrap">
              <span className="time">{t('from-date')}</span>
              <span className="time">{t('to-date')}</span>
            </div>
          </th>
          <th>{t('action')}</th>
        </thead>
        <tbody>{renderPlan()}</tbody>
      </table>
    </div>
  );
}
