import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import axiosClient from 'axios/axiosClient';
import React, { useEffect, useState } from 'react';
import './TaskListRow.scss';
import TaskListRowRole from './TaskListRowRole';
import { Toast } from 'helper/alert';
import { t } from 'i18next';

export default function TaskListRow({
  taskDefault,
  setTaskList,
  index,
  roleList,
  isUpdated,
  setIsUpdated,
  listTaskType,
  listGroup,
}) {
  const [task, setTask] = useState(taskDefault);
  const [isEdit, setIsEdit] = useState(false);
  const [roleSelected, setRoleSelected] = useState({});
  const handleOnChange = (e) => {
    let value = e.target.value;
    if (!e.target.value.length) {
      setTask({ ...task, [e.target.name]: null });
    } else {
      setTask({ ...task, [e.target.name]: value });
    }
  };

  const submitEdit = () => {
    if (task.type_id) {
      axiosClient
        .post('calendar-plan/staff-task/update', {
          taskWillUpdate: { ...task },
          role: roleSelected,
        })
        .then((result) => {
          setIsEdit(false);
          setIsUpdated(!isUpdated);
        })
        .catch((err) => {
          Toast.fire({ icon: 'error', title: `${t('update')} ${t('unsuccess')}` });
        });
    } else {
      Toast.fire({ icon: 'error', title: `${t('update')} ${t('unsuccess')}` });
    }
  };

  const handleDelete = () => {
    axiosClient
      .post('calendar-plan/staff-task/delete', { taskWillDelete: task })
      .then((result) => {
        setTaskList(result.data);
        setIsUpdated(!isUpdated);
        Toast.fire({ icon: 'success', title: `${t('delete')} ${t('success')}` });
      })
      .catch((err) => {
        Toast.fire({ icon: 'error', title: `${t('delete')} ${t('unsuccess')}` });
      });
  };
  useEffect(() => {
    setRoleSelected({ roleName: task.role, roleId: task.role_id, roleKey: task.roleKey });
  }, []);
  return (
    <tr className="task-list-row">
      <td className="record-id">{index}</td>
      <td className="record-name">
        <input
          disabled={!isEdit}
          onChange={handleOnChange}
          type="text"
          value={task.task_name || ''}
          name="task_name"
          required
        />
      </td>
      <td>
        <div className="time__wrap">
          <input
            disabled={!isEdit}
            onChange={handleOnChange}
            className="time"
            value={task.from_time}
            type="time"
            name="from_time"
            required
          />
          <input
            disabled={!isEdit}
            onChange={handleOnChange}
            value={task.to_time}
            className="time"
            type="time"
            name="to_time"
            required
          />
        </div>
      </td>
      <td className="roles">
        {isEdit ? (
          <TaskListRowRole
            roleList={roleList}
            setRoleSelected={setRoleSelected}
            roleSelected={roleSelected}
          />
        ) : (
          roleSelected.roleKey || roleSelected.roleName
        )}
      </td>
      <td className="type">
        <select
          name="type_id"
          id=""
          disabled={!isEdit}
          className={`create-staff-task-form__select  ${isEdit ? '' : 'disable'}`}
          value={task.type_id}
          onChange={handleOnChange}
          required
        >
          <option value={''}>-</option>
          {listTaskType.map((type) => {
            return (
              <option key={`${type.key}-${type.name}`} value={type.id}>
                {t(type.key)}
              </option>
            );
          })}
        </select>
      </td>
      <td className="group">
        <select
          name="group_id"
          id=""
          disabled={!isEdit}
          className={`create-staff-task-form__select  ${isEdit ? '' : 'disable'}`}
          value={task.group_id}
          onChange={handleOnChange}
          required
        >
          <option value={''}>-</option>
          {listGroup.map((group) => {
            return (
              <option key={`${group.key}-${group.name}`} value={group.id}>
                {t(group.name)}
              </option>
            );
          })}
        </select>
      </td>

      <td className="description">
        <input
          disabled={!isEdit}
          onChange={handleOnChange}
          type="text"
          name="description"
          value={task.description}
        />
      </td>

      <td className="record-action">
        <div className="calendar-staff-table__action">
          {isEdit ? (
            <>
              <CheckOutlined onClick={submitEdit} />
              <CloseOutlined
                onClick={() => {
                  setTask(taskDefault);
                  setIsEdit(false);
                }}
              />
            </>
          ) : (
            <>
              <EditOutlined onClick={() => setIsEdit(true)} />
              <DeleteOutlined onClick={handleDelete} />
            </>
          )}
        </div>
      </td>
    </tr>
  );
}
