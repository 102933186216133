import React, { useCallback, useEffect, useRef } from 'react';
import { useState } from 'react';
import './KalenderPlanContent.scss';
import axiosClient from 'axios/axiosClient';
import KalendarPlanTable from '../KanlenderPlanTable/KanlenderPlanTable';
import axios from 'axios';
import Loading from 'components/Loading/Loading';
import moment from 'moment';
import { t } from 'i18next';
import { Toast } from 'helper/alert';
import KalendarPlanDashboard from '../KalendarPlanDashboard/KalendarPlanDashboard';
import Swal from 'sweetalert2';
import OptimizeProcessDashBoard from 'components/CalendarPlan/OptimizeProcessDashBoard/OptimizeProcessDashBoard';
import { useQuery } from 'react-query';
import { websocketURL } from 'constant';
import UploadStaffPlan from '../KanlenderPlanTable/UploadStaffPlan/UploadStaffPlan';
import { useDispatch, useSelector } from 'react-redux';
import { updatePlanDetailLockedAction } from 'redux/calendarPlan/calendarPlanAction';
import { selectCalendarPlan } from 'redux/calendarPlan/calendarPlanSlice';

const requestOptimizePlan = async (
  planSelected,
  planList,
  setPlanList,
  unitSelected,
  weekSelect,
  planDetailLocked,
  setProgressPlan,
  setIsAvailable,
  setIsOptimized,
) => {
  let clientSocket = new WebSocket(websocketURL);

  clientSocket.onopen = async (e) => {
    if (Object.keys(planSelected).length && planSelected.id) {
      const planListUpdate = planList;
      const planFoundIndex = planListUpdate.findIndex((plan) => plan.id === planSelected.id);
      if (planFoundIndex > -1) {
        planListUpdate[planFoundIndex].isAvailable = false;
      }
      setPlanList(planListUpdate);
      const payload = {
        unitCode: unitSelected,
        week: weekSelect,
        planId: planSelected.id,
        lockedEmp: planDetailLocked.employeeIdLockedList,
      };
      if (planDetailLocked.employeeIdLockedList.length) {
        await axiosClient.post('calendar-plan/kalender/locked-shift', {
          planDetailLockedList: planDetailLocked.planDetailLockedList,
        });
      }

      axiosClient
        .post('calendar-plan/kalender/plan-optimizing', {
          planId: planSelected.id,
          unitCode: unitSelected,
        })
        .then((res) => setProgressPlan(res));

      clientSocket.send(JSON.stringify(payload));
      axiosClient
        .post('/calendar-plan/kalender/est-optimize-time', {
          planId: planSelected.id,
        })
        .then((result) => {
          Toast.fire(
            `${t('optimizng-plan')} ${t('in')} ${result.timeEst} ${t('minutes')}`,
            '',
            'info',
          );
        });
      setIsAvailable(false);
    }
  };
  clientSocket.onmessage = async (e) => {
    const payload = e.data;
    if (payload === 'Optimize success') {
      Toast.fire({
        icon: 'success',
        title: `${t('optimize')} ${planSelected.plan_name} ${t('success')}`,
      });
      setIsAvailable(true);
      setIsOptimized(false);
      clientSocket.close(1000);
    }
  };
  clientSocket.onerror = async (error) => {
    setIsAvailable(true);
    Toast.fire({ icon: 'error', title: 'Optimze server disconnect' });
  };
};

export default function KalenderPlanContent({ unitSelected }) {
  const [publicHoliday, setPublicHoliday] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [planSelected, setPlanSelected] = useState({});
  const [weekSelect, setWeekSelect] = useState(0);
  const [planList, setPlanList] = useState([]);
  const [isOptimized, setIsOptimized] = useState(false);
  const [isAvailable, setIsAvailable] = useState(true);
  const [progressPlan, setProgressPlan] = useState([]);
  const [sortObj, setSortObj] = useState({ name: true });
  const [isActiveUploadForm, setIsActiveUploadForm] = useState(false);

  const { planDetailLocked } = useSelector(selectCalendarPlan);
  const dispatch = useDispatch();

  const lockAllUser = (data) => {
    let planDetailLockedList = [];
    let employeeIdLockedList = [];
    for (const empData of data) {
      const empId = empData.employee_id;
      const taskArr = empData.task_arr;
      employeeIdLockedList.push(empId);
      for (const date of Object.keys(taskArr)) {
        const [task] = taskArr[date];

        if (task.planDetailId) {
          const planDetailIndex = planDetailLockedList.findIndex(
            (planDetail) => planDetail.planDetailId === task.planDetailId,
          );
          if (planDetailIndex > -1) {
            planDetailLockedList[planDetailIndex] = {
              ...planDetailLockedList[planDetailIndex],
              planId: task.planId,
              numberSlice: planDetailLockedList[planDetailIndex]['numberSlice'] + 1,
            };
          } else {
            planDetailLockedList.push({
              planDetailId: task.planDetailId,
              numberSlice: 1,
              planId: task.planId,
            });
          }
        }
      }
    }
    dispatch(
      updatePlanDetailLockedAction({
        planDetailLockedList: planDetailLockedList,
        employeeIdLockedList: employeeIdLockedList,
      }),
    );
  };
  const lockUser = (empId, taskArr) => {
    let planDetailLockedList = [...planDetailLocked['planDetailLockedList']];
    let employeeIdLockedList = [...planDetailLocked['employeeIdLockedList']];

    if (employeeIdLockedList.includes(empId)) {
      employeeIdLockedList = employeeIdLockedList.filter((emp) => emp !== empId);
      for (const date of Object.keys(taskArr)) {
        const [task] = taskArr[date];
        if (task.planDetailId) {
          const planDetailIndex = planDetailLockedList.findIndex(
            (planDetail) => planDetail.planDetailId === task.planDetailId,
          );
          if (planDetailIndex > -1) {
            planDetailLockedList[planDetailIndex] = {
              ...planDetailLockedList[planDetailIndex],
              planId: task.planId,
              numberSlice: planDetailLockedList[planDetailIndex]['numberSlice'] - 1,
            };
          }
        }
      }
    } else {
      employeeIdLockedList.push(empId);
      for (const date of Object.keys(taskArr)) {
        const [task] = taskArr[date];

        if (task.planDetailId) {
          const planDetailIndex = planDetailLockedList.findIndex(
            (planDetail) => planDetail.planDetailId === task.planDetailId,
          );
          if (planDetailIndex > -1) {
            planDetailLockedList[planDetailIndex] = {
              ...planDetailLockedList[planDetailIndex],
              planId: task.planId,
              numberSlice: planDetailLockedList[planDetailIndex]['numberSlice'] + 1,
            };
          } else {
            planDetailLockedList.push({
              planDetailId: task.planDetailId,
              numberSlice: 1,
              planId: task.planId,
            });
          }
        }
      }
    }

    dispatch(
      updatePlanDetailLockedAction({
        planDetailLockedList: planDetailLockedList,
        employeeIdLockedList: employeeIdLockedList,
      }),
    );
  };
  const progressData = useQuery(
    `${unitSelected}-optimizing-plan`,
    () =>
      axiosClient
        .post('calendar-plan/kalender/plan-optimizing', {
          planId: planSelected.id,

          unitCode: unitSelected,
        })
        .then((res) => res),
    {
      refetchInterval: !isAvailable && Object.keys(planSelected).length > 0 ? 3000 : false,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
    },
  );
  useEffect(() => {
    setPlanSelected({});
    setIsAvailable(true);
    setSortObj({ name: true });

    axiosClient.post('/calendar-plan/staff-plan', { unitCode: unitSelected }).then((result) => {
      if (result) {
        setIsLoading(false);
        setPlanList(result.result);
      }
    });

    axiosClient
      .post('calendar-plan/kalender/plan-optimizing', {
        planId: planSelected.id,

        unitCode: unitSelected,
      })
      .then((res) => setProgressPlan(res));
  }, [unitSelected]);

  useEffect(() => {
    axiosClient.get('/public-holiday').then((result) => {
      setPublicHoliday(result);
    });
  }, [unitSelected]);

  useEffect(() => {
    if (progressData.data) {
      setProgressPlan(progressData.data);
      if (progressData.data.length === 0) {
        axiosClient.post('/calendar-plan/staff-plan', { unitCode: unitSelected }).then((result) => {
          setPlanList(result.result);
          setIsAvailable(true);
        });
      }
    }
  }, [progressData.data]);
  return (
    <div className="kalender-plan-content">
      {isLoading ? (
        <div className="overlay">
          <Loading />
        </div>
      ) : null}

      <div className="kalender-plan-content__header">
        <div className="kalender-plan-content__action-select">
          <label className="kalender-plan-content__action-label" htmlFor="">
            {t('staff-plan')}
          </label>
          <select
            className="kalender-plan-content__action-select"
            value={planSelected.id}
            onChange={(e) => {
              if (e.target.value === '') {
                setPlanSelected({});
                return;
              }
              const planFound = planList.find((plan) => plan.id == e.target.value);
              if (planFound) {
                setPlanSelected(planFound);

                const fromDate = moment(planFound.from_date);
                const numWeek = moment(planFound.to_date).diff(fromDate, 'week') + 1;
                setWeekSelect(numWeek);
                setIsAvailable(planFound.isAvailable);
              }
            }}
          >
            <option value="">{t('select')}</option>
            {planList.map((plan) => {
              return (
                <option key={plan.uuid} value={plan.id}>
                  {plan.plan_name}
                </option>
              );
            })}
          </select>
        </div>
        {Object.keys(planSelected).length ? (
          <div>
            {/* <button
              className="custom-btn"
              style={{ marginRight: '10px' }}
              onClick={() => {
                setIsActiveUploadForm(true);
              }}
            >
              {t('upload-staff-plan')}
            </button> */}
            <button
              className="custom-btn"
              disabled={!isAvailable}
              onClick={() => {
                Swal.fire({
                  title: t('optimizing-question'),
                  icon: 'question',
                  confirmButtonText: t('yes-question'),
                  cancelButtonText: t('no-question'),
                  showCancelButton: true,
                  showCloseButton: true,
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    await requestOptimizePlan(
                      planSelected,
                      planList,
                      setPlanList,
                      unitSelected,
                      weekSelect,
                      planDetailLocked,
                      setProgressPlan,
                      setIsAvailable,
                      setIsOptimized,
                    );
                  }
                });
              }}
            >
              {t('optimize')}
            </button>
          </div>
        ) : null}
      </div>
      {isActiveUploadForm ? (
        <UploadStaffPlan
          setActive={setIsActiveUploadForm}
          unitSelected={unitSelected}
          planSelected={planSelected}
          setIsOptimized={setIsOptimized}
          setIsLoading={setIsLoading}
        />
      ) : null}
      {Object.keys(planSelected).length > 0 && isAvailable ? (
        <>
          <KalendarPlanTable
            title={'calendar-plan-optimized'}
            planSelected={planSelected}
            unitSelected={unitSelected}
            publicHoliday={publicHoliday}
            isCleanVakant={true}
            weekSelect={weekSelect}
            isOptimized={isOptimized}
            isAvailable={isAvailable}
            lockUser={lockUser}
            lockedEmp={planDetailLocked.employeeIdLockedList}
            sortObj={sortObj}
            lockAllUser={lockAllUser}
            setIsOptimized={setIsOptimized}
            setIsAvailable={setIsAvailable}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
          />
          <KalendarPlanDashboard
            publicHoliday={publicHoliday}
            planSelected={planSelected}
            unitSelected={unitSelected}
            sortObj={sortObj}
            setSortObj={setSortObj}
            weekSelect={weekSelect}
          />
        </>
      ) : progressPlan.length &&
        Object.keys(planSelected).length > 0 &&
        progressPlan.findIndex(
          (progress) =>
            progress.id === planSelected.id && progress.planName === planSelected.plan_name,
        ) > -1 ? (
        <OptimizeProcessDashBoard data={progressPlan} />
      ) : (
        <span>
          {t('please-select')} {t('staff-plan')}
        </span>
      )}
    </div>
  );
}
