import { CloseOutlined } from '@ant-design/icons';
import axiosClient from 'axios/axiosClient';
import { Toast } from 'helper/alert';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectGlobal } from 'redux/global/globalSlice';
import './CalendarSurveyQuestionForm.scss';

export default function CalendarSurveyQuestionForm({
  parentQuestion = {},
  isUpdate,
  setIsUpdate,
  setActive,
  topicList,
}) {
  let keyParentAnswerDefault = '';
  const [questionValues, setQuestionValues] = useState({
    parentQuestionId: '',
    answerId: '',
    typeQuestion: 'text',
  });
  const [subTopic, setSubTopic] = useState();
  const { language } = useSelector(selectGlobal);

  const handleOnChange = (e) => {
    if (e.target.name === 'typeQuestion' && e.target.value === 'text') {
      setQuestionValues({
        ...questionValues,
        [e.target.name]: e.target.value,
        number_option: 0,
      });
      return;
    }
    if (e.target.type === 'number') {
      if (e.target.value < 10 && e.target.value >= 1) {
        if (e.target.value[0] === '0') {
          e.target.value = e.target.value.replace(/^0+/, '');
        }
      }
    }
    setQuestionValues({ ...questionValues, [e.target.name]: e.target.value });
  };
  const renderMainOption = (arr) => {
    return arr.map((option) => {
      const key = Object.keys(option);
      return (
        <>
          <option value={option[key]}>{key[0].toUpperCase()}</option>
        </>
      );
    });
  };
  const renderOptionAnswer = (length) => {
    return Array.from({ length }).map((_, index) => {
      return (
        <>
          <input
            type="text"
            className="survey-question-form__option-input"
            name={`option_${index}`}
            onChange={handleOnChange}
            required
          />
        </>
      );
    });
  };
  const renderOptionAnswerNO = (length) => {
    return Array.from({ length }).map((_, index) => {
      return (
        <>
          <input
            type="text"
            className="survey-question-form__option-input"
            name={`option_no_${index}`}
            onChange={handleOnChange}
            required
          />
        </>
      );
    });
  };

  const renderTopic = (arr) => {
    return arr.map((topic) => {
      return (
        <option key={topic.uuid} value={topic.uuid}>
          {language === 'NO' ? topic.no_ver : topic.topic}
        </option>
      );
    });
  };
  const renderSubtopic = (arr, parentId) => {
    if (arr) {
      return arr.map((topic) => {
        return (
          <option key={`${parentId}-topic`} value={topic}>
            {topic}
          </option>
        );
      });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const listOption = Array.from({ length: questionValues.number_option }).map((_, index) => ({
      en: questionValues[`option_${index}`],
      no: questionValues[`option_no_${index}`],
      order: index,
    }));
    let newQuestion = { ...questionValues };
    if (questionValues.content && !questionValues.no_ver) {
      newQuestion = { ...newQuestion, no_ver: questionValues.content };
    }
    if (!questionValues.content && questionValues.no_ver) {
      newQuestion = { ...newQuestion, content: questionValues.no_ver };
    }
    if (!questionValues.description && questionValues.description_no_ver) {
      newQuestion = { ...newQuestion, description: questionValues.description_no_ver };
    }
    if (questionValues.description && !questionValues.description_no_ver) {
      newQuestion = { ...newQuestion, description_no_ver: questionValues.description };
    }
    if (questionValues.content || questionValues.no_ver) {
      axiosClient
        .post('calendar-plan/survey-question/create', { ...newQuestion, listOption })
        .then((result) => {
          Toast.fire({ icon: 'success', title: `${t('create')} ${t('success')}` });
          setIsUpdate(!isUpdate);
        });
      setActive(false);
    } else {
      Toast.fire({ icon: 'error', title: `${t('create')} ${t('unsuccess')}` });
    }
  };
  useEffect(() => {
    if (Object.keys(parentQuestion).length > 0) {
      keyParentAnswerDefault = Object.keys(parentQuestion.parent_answer_choice[0])[0];
      setQuestionValues({
        parentQuestionId: parentQuestion.parent_question_id || '',
        answerId: parentQuestion.parent_answer_choice[0][keyParentAnswerDefault] || '',
        typeQuestion: 'text',
      });
    }
  }, []);
  return (
    <div className="survey-question-form__content">
      <div className="survey-question-form__wrap">
        <div className="survey-question-form__header">
          <h4>{t('create-new-question')}</h4>
          <CloseOutlined
            onClick={() => {
              setActive(false);
            }}
          />
        </div>

        <form onSubmit={handleSubmit} className="survey-question-form">
          {Object.keys(parentQuestion).length ? (
            <div className="survey-question-form__group">
              <label htmlFor="" className="survey-question-form__label">
                {t('show-when-main-question')}
              </label>
              <select
                value={questionValues.answerId}
                onChange={handleOnChange}
                name="answerId"
                id=""
              >
                <option value="">{t('please-select')}</option>
                {renderMainOption(
                  language === 'NO'
                    ? parentQuestion.parent_answer_no_ver
                    : parentQuestion.parent_answer_choice,
                )}
              </select>
            </div>
          ) : (
            <></>
          )}

          <div className="survey-question-form__group">
            <label htmlFor="" className="survey-question-form__label">
              {t('question-in-english')}
            </label>
            <textarea
              value={questionValues.content || ''}
              onChange={handleOnChange}
              name="content"
              type="text"
            />
          </div>
          <div className="survey-question-form__group">
            <label htmlFor="" className="survey-question-form__label">
              {t('question-in-norway')}
            </label>
            <textarea
              value={questionValues.no_ver || ''}
              onChange={handleOnChange}
              name="no_ver"
              type="text"
            />
          </div>
          <div className="survey-question-form__group">
            <label htmlFor="" className="survey-question-form__label">
              {t('description-in-en')}
            </label>
            <textarea
              value={questionValues.description || ''}
              onChange={handleOnChange}
              name="description"
              type="text"
            />
          </div>
          <div className="survey-question-form__group">
            <label htmlFor="" className="survey-question-form__label">
              {t('description-in-no')}
            </label>
            <textarea
              value={questionValues.description_no_ver || ''}
              onChange={handleOnChange}
              name="description_no_ver"
              type="text"
            />
          </div>
          {!Object.keys(parentQuestion).length ? (
            <div className="survey-question-form__group">
              <label htmlFor="" className="survey-question-form__label">
                {t('topic')}
              </label>
              <select
                value={questionValues.topic || ''}
                onChange={handleOnChange}
                name="topic"
                id=""
              >
                <option value="">{t('please-select')}</option>

                {renderTopic(topicList)}
              </select>
            </div>
          ) : (
            <></>
          )}

          <div className="survey-question-form__group">
            <label htmlFor="" className="survey-question-form__label">
              {t('type-answer')}
            </label>
            <select
              value={questionValues.typeQuestion || 'text'}
              onChange={handleOnChange}
              name="typeQuestion"
              id=""
            >
              <option value="text">{t('text')}</option>
              <option value="multiple">{t('multi-option')}</option>
              <option value="single">{t('single-option')}</option>
              <option value="date">{t('date')}</option>
            </select>
          </div>

          {questionValues.typeQuestion !== 'text' &&
          questionValues.typeQuestion !== 'date' &&
          questionValues.typeQuestion ? (
            <>
              <div className="survey-question-form__group">
                <label htmlFor="" className="survey-question-form__label">
                  {t('number-option')}
                </label>
                <input
                  value={questionValues.number_option || 0}
                  onChange={handleOnChange}
                  type="number"
                  name="number_option"
                  required
                />
              </div>
              <div className="survey-question-form__group">
                <label htmlFor="" className="survey-question-form__label">
                  {t('option-in-english')}
                </label>
                <div className="survey-question-form__group-input">
                  {renderOptionAnswer(questionValues.number_option || 0)}
                </div>
              </div>
              <div className="survey-question-form__group">
                <label htmlFor="" className="survey-question-form__label">
                  {t('option-in-norway')}
                </label>
                <div className="survey-question-form__group-input">
                  {renderOptionAnswerNO(questionValues.number_option || 0)}
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {Object.keys(parentQuestion).length ? (
            <></>
          ) : (
            <div className="survey-question-form__group">
              <div className="survey-question-form__group-property">
                <div className="survey-question-form__group-property__item">
                  <label htmlFor="" className="survey-question-form__label">
                    {t('order')}
                  </label>
                  <input
                    value={questionValues.order || ''}
                    onChange={handleOnChange}
                    name="order"
                    type="number"
                    required
                  />
                </div>
                {/* <div className="survey-question-form__group-property__item">
                  <label htmlFor="" className="survey-question-form__label">
                    {t('percent')}
                  </label>
                  <input
                    value={questionValues.percent || 0}
                    onChange={handleOnChange}
                    name="percent"
                    type="number"
                    required={!!questionValues.mandatory}
                  />
                </div> */}
                <div className="survey-question-form__group-property__item">
                  <label htmlFor="" className="survey-question-form__label">
                    {t('mandatory')}
                  </label>
                  <input
                    value={true}
                    onChange={handleOnChange}
                    checked={
                      questionValues.mandatory === true ||
                      questionValues.mandatory === '1' ||
                      questionValues.mandatory === 'true'
                    }
                    name="mandatory"
                    type="checkbox"
                  />
                </div>
              </div>
            </div>
          )}

          <div className="survey-question-form__submit">
            <button
              type="button"
              className="custom-btn none-bg"
              onClick={() => {
                setActive(false);
              }}
            >
              {t('cancel')}
            </button>
            <button className="custom-btn ">
              {t('create')} {t('question')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
