import axiosClient from 'axios/axiosClient';
import Pagination from 'components/Pagination/Pagination';
import { Toast } from 'helper/alert';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import CalendarStaffTaskListTable from '../CalendarStaffTaskListTable/CalendarStaffTaskListTable';
import CreateStaffTaskForm from '../CreateStaffTaskForm/CreateStaffTaskForm';
import './TaskListContent.scss';

export default function TaskListContent({ unitSelected }) {
  const [taskList, setTaskList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [listTaskType, setListTaskType] = useState([]);
  const [listGroup, setListGroup] = useState([]);
  const [totalTask, setTotalTask] = useState(0);
  const [isUpdated, setIsUpdated] = useState(false);

  const [page, setPage] = useState(1);
  const [activeCreateForm, setActiveCreateForm] = useState(false);
  const headerList = ['ID', 'Task Name', 'Time From', 'Time To', 'Description', 'Action'];

  useEffect(() => {
    axiosClient
      .post('calendar-plan/staff-task', {
        unitCode: unitSelected,
        page,
      })
      .then((result) => {
        setTaskList(result.data);
        setActiveCreateForm(false);
        setTotalTask(result.total);
      })
      .catch((err) => {
        Toast.fire({ icon: 'error', title: `${t('create')} ${t('unsuccess')}` });
      });
    axiosClient.post('calendar-plan/role').then((result) => {
      if (result) {
        setRoleList(result.data);
      }
    });

    axiosClient.post('calendar-plan/staff-task-type').then((result) => {
      setListTaskType(result.data);
    });
    axiosClient.post('calendar-plan/group', { unitSelected }).then((result) => {
      setListGroup(result.data);
    });
  }, [page, unitSelected, isUpdated]);
  return (
    <div>
      <div className="task-list-content__wrap">
        <div className="task-list-content__header">
          <h1>{t('staff-task-list')}</h1>
        </div>
        <div className="task-list-content__body">
          <CalendarStaffTaskListTable
            headerList={headerList}
            taskList={taskList}
            setTaskList={setTaskList}
            listTaskType={listTaskType}
            listGroup={listGroup}
            roleList={roleList}
            isUpdated={isUpdated}
            setIsUpdated={setIsUpdated}
          />
        </div>
      </div>
      <div className="task-list-content__feature">
        <Pagination page={page} setPage={setPage} totalPage={Math.ceil(totalTask / 10)} />
        <div>
          <button className="custom-btn">{t('upload-new-staff-task')}</button>
          <button
            className="custom-btn"
            onClick={() => {
              setActiveCreateForm(true);
            }}
          >
            {t('create-new-staff-task')}
          </button>
        </div>
      </div>
      {activeCreateForm ? (
        <CreateStaffTaskForm
          setTaskList={setTaskList}
          setActiveCreateForm={setActiveCreateForm}
          unitSelected={unitSelected}
          roleList={roleList}
          isUpdated={isUpdated}
          setIsUpdated={setIsUpdated}
          listTaskType={listTaskType}
          listGroup={listGroup}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
