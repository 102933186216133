import { CloseOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import React, { useState } from 'react';
import './CalendarPlanRole.scss';
import CalendarPlanRoleRow from './CalendarPlanRoleRow';
import CalendarPlanRoleRowNew from './CalendarPlanRoleRowNew';

export default function CalendarPlanRole({ roleList, isUpdate, setIsUpdate, setActive }) {
  const [isCreate, setIsCreate] = useState(false);
  const RenderRoleRow = ({ roleList }) => {
    return roleList.map((role) => {
      return (
        <CalendarPlanRoleRow
          key={role.id}
          defaultRole={role}
          setIsUpdate={setIsUpdate}
          isUpdate={isUpdate}
        />
      );
    });
  };
  return (
    <div className="calendar-plan-create-role__wrap">
      <div className="calendar-plan-create-role__content">
        <div className="calendar-plan-create-role__header">
          <h4>{t('role-management')}</h4>
          <CloseOutlined
            onClick={() => {
              setActive(false);
            }}
          />
        </div>
        <div className="calendar-plan-create-role__list">
          <table className="calendar-plan-create-role__table">
            <thead>
              <th className="calendar-plan-create-role__item-label">{t('name')}</th>
              <th className="calendar-plan-create-role__item-label">{t('key')}</th>
              <th className="calendar-plan-create-role__item-label">{t('rank')}</th>
              <th className="calendar-plan-create-role__item-label">{t('description')}</th>
              <th className="calendar-plan-create-role__item-label">{t('action')}</th>
            </thead>
            <tbody>
              <RenderRoleRow roleList={roleList} />
              {isCreate ? (
                <CalendarPlanRoleRowNew
                  setActive={setIsCreate}
                  setIsUpdate={setIsUpdate}
                  isUpdate={isUpdate}
                />
              ) : null}
            </tbody>
          </table>
        </div>
        <div className="calendar-plan-create-role__action">
          <button
            className="custom-btn square-btn"
            onClick={() => {
              setIsCreate(true);
            }}
          >
            {t('create-new-role')}
          </button>
        </div>
      </div>
    </div>
  );
}
