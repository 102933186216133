import React from 'react';
import { t } from 'i18next';
import { ClockCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { selectDayNightAction } from 'redux/global/globalAction';
import { selectGlobal } from 'redux/global/globalSlice';
import './FilterDayNight.scss';
import { Toast } from 'helper/alert';
export default function FilterDayNight() {
  const { dayNightArr } = useSelector(selectGlobal);

  const dispatch = useDispatch();
  const handleOnchange = (e) => {
    Toast.fire({
      title: `${t('loading-data')}`,
      timerProgressBar: true,
      timer: false,

      didOpen: () => {
        Toast.showLoading();
      },
    });
    dispatch(selectDayNightAction(e.target.value));
  };
  return (
    <div className="filter-day-night__wrap">
      <div className="filter-title">
        <h3>
          <ClockCircleOutlined /> {t('day')}/{t('night')}
        </h3>
      </div>
      <div className="filter-day-night__group">
        <div className="filter-day-night__item">
          <input
            className="filter-day-night__checkbox"
            value={'day'}
            checked={dayNightArr.includes('day')}
            type="checkbox"
            onChange={(e) => handleOnchange(e)}
          />
          {t('day')}
        </div>
        <div className="filter-day-night__item">
          <input
            className="filter-day-night__checkbox"
            value={'night'}
            checked={dayNightArr.includes('night')}
            type="checkbox"
            onChange={(e) => handleOnchange(e)}
          />
          {t('late')}
        </div>
        {/* <div className="filter-day-night__item">
          <input
            className="filter-day-night__checkbox"
            value={'night'}
            checked={dayNightArr.includes('night')}
            type="checkbox"
            onChange={(e) => handleOnchange(e)}
          />
          {t('night')}
        </div> */}
      </div>
    </div>
  );
}
