import React, { useCallback, useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import './CalendarPlanWishListViewPopUp.scss';
import axiosClient from 'axios/axiosClient';
import { t } from 'i18next';
import Loading from 'components/Loading/Loading';
import CalendarPlanWishListTable from 'components/CalendarPlan/CalendarPlanEmployee/CalendarPlanWishListTable/CalendarPlanWishListTable';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { clearWishList, initDataTable, initWishList } from 'redux/calendarPlan/calendarPlanSlice';
import { result } from 'lodash';
import { Toast } from 'helper/alert';

const CalendarPlanWishListViewPopUp = ({ wishList, unitSelected, setClose }) => {
  const dispatch = useDispatch();
  const { emailList } = useSelector((state) => state.calendarPlan);
  const { wishListItems: saveData, dataTable: updateTableData } = useSelector(
    (state) => state.calendarPlan,
  );

  const [data, setData] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [weekRange, setWeekRange] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(1);

  const [taskArr, setTaskArr] = useState([]);
  const { currentPage } = useSelector((state) => state.calendarPlan);
  const getPageItems = (allItems = [], currentPage = 1, limit = 20) =>
    allItems.slice((currentPage - 1) * limit, currentPage * limit);
  const [dataItem, setDataItem] = useState([]);

  const handleSendMail = async () => {
    setIsLoading(true);
    await axiosClient
      .post('email/wish-list', {
        empIds: emailList,
        unitCode: unitSelected,
        wishListId: wishList?.uuid,
      })
      .then((result) => {
        setIsLoading(false);
        Toast.fire({ icon: 'success', title: t('send-survey') });
      })
      .catch((error) => Toast.fire({ icon: 'fail', title: t('send-survey') }));
  };

  const handleSubmit = () => {
    setIsLoading(true);
    axiosClient
      .post('calendar-plan/wish-list/update', {
        fileData: saveData,
        unitCode: unitSelected,
        wishListId: wishList.uuid,
      })
      .then((result) => {
        axiosClient
          .post('calendar-plan/wish-list/wish', {
            unitCode: unitSelected,
            wishList,
          })
          .then((result) => {
            setData(result.data);
          });
        Toast.fire({ icon: 'success', title: `${t('save')} ${t('success')}` });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);

    axiosClient
      .post('calendar-plan/wish-list/wish', {
        unitCode: unitSelected,
        wishList,
      })
      .then((result) => {
        setData(result.data);
        const totalPage = Math.ceil(result.totalEmp / 15);

        setTotalPage(totalPage);
        setPage(1);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, [wishList]);

  useEffect(() => {
    const dateInfo = {
      startDate: '',
      endDate: '',
    };

    const tableData = data?.map((dataItem) => {
      const splitData = Object.entries(dataItem.task_arr);
      const task_arr = splitData.map((task) => ({
        [task[0]]: [task[1]],
      }));

      const taskArr = task_arr.reduce((obj, item, index) => {
        if (index === 0) {
          dateInfo.startDate = Object.keys(item)[0];
        } else if (index === task_arr?.length - 1) {
          dateInfo.endDate = Object.keys(item)[0];
        }
        return {
          ...obj,
          [Object.keys(item)[0]]: [{ taskName: Object.values(item)[0][0] }],
        };
      }, {});

      return {
        name: dataItem.name,
        email: dataItem?.email,
        uuid: dataItem.uuid,
        task_arr: taskArr,
      };
    });

    const duration = moment(dateInfo.endDate).diff(moment(dateInfo.startDate), 'days') + 1;
    const date = moment(dateInfo.startDate);
    const dates = new Array(isNaN(duration) ? 0 : duration).fill(null);

    const dateTimes = dates.map(() => {
      const cloneDate = date.clone().format('YYYY-MM-DD');
      date.add(1, 'day');
      return cloneDate;
    });
    setDateRange(dateTimes);
    setDataTable(tableData);
    setDataItem(getPageItems(tableData, currentPage));

    const updateData = data?.reduce((obj, item) => {
      const { uuid } = item;
      const listOfDates = Object.keys(item.task_arr);
      return [
        ...obj,
        ...listOfDates.map((date) => ({
          name: item.name,
          uuid,
          code: item.task_arr[date],
          wishListId: item.wishListId,
          date,
        })),
      ];
    }, []);
    dispatch(initWishList({ data: updateData }));
  }, [data]);

  useEffect(() => {
    setDataItem([...dataItem, ...getPageItems(dataTable, currentPage)]);
  }, [currentPage]);

  useEffect(() => {
    const getWeeks = getWeekRange();
    setWeekRange(getWeeks);
  }, [dateRange]);

  useEffect(() => {
    axiosClient
      .post('calendar-plan/staff-task-all', {
        unitCode: unitSelected,
      })
      .then((result) => {
        const staffTaskAll = result.data.map((staff, index) => ({
          taskName: staff.task_name,
          id: staff.id,
        }));
        setTaskArr(staffTaskAll);
      });
  }, []);

  const getWeekRange = () => {
    if (!dateRange) {
      return null;
    }
    const currDate = moment(dateRange[0]);
    const lastDate = moment(dateRange[dateRange.length - 1]);

    const duration = lastDate.diff(currDate, 'days') + 1;
    const weekTimes = [];
    let week = 0;
    let counter = 0,
      firstWeek = true,
      dateString = '',
      durationOfWeek = 1,
      year = 0;

    while (counter < duration) {
      if (currDate.weekday() < 6) {
        if (currDate.weekday() === 0 || firstWeek) {
          if (firstWeek) {
            dateString = currDate.format('DD-MM-YYYY');
            firstWeek = false;
          } else {
            dateString = currDate.clone().add(1, 'day').format('DD-MM-YYYY');
          }
          week++;
        }
        if (duration - (counter + 1) === 0 && currDate.clone().add(1, 'day').weekday < 6) {
          dateString = `${dateString} - ${currDate.format('DD-MM-YYYY')}`;
          weekTimes.push({
            dateString,
            week,
            year,
            durationOfWeek,
          });
        }
        durationOfWeek++;
        year = currDate.format('YYYY');
      } else if (currDate.weekday() >= 6) {
        dateString = `${dateString} - ${currDate.clone().add(1, 'day').format('DD-MM-YYYY')}`;
        weekTimes.push({
          dateString,
          week,
          year,
          durationOfWeek,
        });
        durationOfWeek = 1;
      }

      currDate.add(1, 'day');
      counter++;
    }
    return weekTimes;
  };

  const onLoadMore = (page) => {
    if (page < totalPage && page > 0) {
      setIsLoadingMore(true);

      axiosClient
        .post('calendar-plan/wish-list/wish-load-more', {
          unitCode: unitSelected,
          wishList,
          page,
        })
        .then((result) => {
          const plusData = result.data;
          if (plusData.length) {
            setData((prev) => {
              return [...prev, ...plusData];
            });
          }
          setPage(page + 1);
          setIsLoadingMore(false);
        });
    }
  };

  const handleScroll = useCallback(
    (e) => {
      const element = e.target;
      const scrollBottom = element.scrollHeight - element.scrollTop === element.clientHeight;

      if (scrollBottom) {
        onLoadMore(page);
      }
    },
    [page],
  );

  return (
    <div className="calendar-view-plan-wish-list">
      {weekRange?.length > 0 && dateRange?.length > 0 && dataTable?.length > 0 ? (
        <div className="calendar-view-plan-wish-list__wrap">
          <div className="calendar-view-plan-wish-list__content">
            <div className="calendar-view-plan-wish-list__header">
              {' '}
              <span>{wishList.name}</span>
              <CloseOutlined
                onClick={() => {
                  dispatch(clearWishList());
                  setClose(false);
                }}
              />
            </div>
            <div className="calendar-view-plan-wish-list__body">
              {isLoading ? (
                <div className="overlay">
                  <Loading />
                </div>
              ) : null}

              {data?.length > 0 ? (
                <CalendarPlanWishListTable
                  loading={isLoadingMore}
                  currentPage={currentPage}
                  allowEdit
                  enableEmail
                  enableSelect
                  weekRange={weekRange}
                  dateRange={dateRange}
                  data={dataTable}
                  taskArr={taskArr}
                  wishListId={wishList?.uuid}
                  handleScroll={handleScroll}
                />
              ) : (
                t('no-employees')
              )}
            </div>
            {dataTable?.length > 0 ? (
              <div className="calendar-view-plan-wish-list__footer">
                <button
                  disabled={isLoading}
                  type="submit"
                  className="custom-btn"
                  onClick={handleSubmit}
                >
                  {t('save')}
                </button>
                <button
                  disabled={isLoading}
                  type="button"
                  className="custom-btn"
                  onClick={handleSendMail}
                >
                  {t('Send-to-email')}
                </button>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default CalendarPlanWishListViewPopUp;
