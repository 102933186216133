import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import './SortCustom.scss';

const SortCustom = ({ value, sortKey, setValue }) => {
  const [isIncrease, setIsIncrease] = useState(true);
  const handleOnChangeSort = (changeValue) => {
    setIsIncrease(changeValue);

    setValue({ [sortKey]: changeValue });
  };

  useEffect(() => {
    setIsIncrease(value[sortKey]);
  });

  return (
    <div
      className={`sort-group ${
        Object.keys(value).includes(sortKey) ? `${isIncrease ? 'increase' : 'decrease'}` : ''
      } $`}
    >
      <span className="sort-icon" onClick={() => handleOnChangeSort(true)}>
        <CaretUpOutlined />
      </span>
      <span className="sort-icon" onClick={() => handleOnChangeSort(false)}>
        <CaretDownOutlined />
      </span>
    </div>
  );
};

export default SortCustom;
