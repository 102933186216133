import axios from 'axios';
import { Toast } from 'helper/alert';
import { t } from 'i18next';
import { Cookies } from 'react-cookie';
const cookies = new Cookies();
const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    'Content-Type': `application/json`,
  },
});

axiosClient.interceptors.request.use((config) => {
  const token = cookies.get('accessToken');
  config.headers['Authorization'] = 'Bearer ' + token;
  config.headers['withCredentials'] = true;
  return config;
});

axiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return response.data;
    }

    return response;
  },
  ({ response }) => {
    if (response.status === 500) {
      Toast.fire({ icon: 'error', title: `${t('fetch-data')} ${t('unsuccess')}` });
    }
    if (response.status === 403 || response.status === 401) {
      localStorage.clear();
      cookies.remove('username');
      cookies.remove('role');
      cookies.remove('accessToken');
    }
  },
);
export default axiosClient;
