import React, { Fragment, useCallback, useState } from 'react';
import Status from '../Status/Status';
import { t } from 'i18next';
import moment from 'moment';
import { dateFormatReg } from 'constant';
import './EmployeeRow.scss';
import { v4 as uuidv4 } from 'uuid';

const EmployeeRow = ({
  data,
  employSurveyCheckList,
  planSelected,
  leftName,
  isUnassigned,
  totalAssginedEmp,
  totalHolidayAndSun,
  roleChecking,
  weekSelect,
  fromDate,
  endDate,
  isActiveSurveyDashboard,
  empSelected,
  activeTab,
}) => {
  const RenderEmployeeInfo = useCallback(
    ({
      hoursWeekend,
      dayShift,
      eveShift,
      nightShift,
      longShift,
      otherShift,
      numDayOff,
      numHolidayAndSun,
    }) => {
      switch (activeTab) {
        case 'soft':
          return (
            <>
              <td>{(+hoursWeekend).toFixed(2)}</td>
              <td>{dayShift}</td>
              <td>{eveShift}</td>
              <td>{nightShift}</td>
              <td>{longShift}</td>
              <td>{otherShift}</td>
            </>
          );
        case 'hard':
          return (
            <>
              <td>{numDayOff}</td>
              <td>
                {numHolidayAndSun} / {totalHolidayAndSun.length}
              </td>
            </>
          );
        case 'survey':
          return <> </>;

        default:
          return (
            <>
              <td>{(+hoursWeekend).toFixed(2)}</td>
              <td>{dayShift}</td>
              <td>{eveShift}</td>
              <td>{nightShift}</td>
              <td>{longShift}</td>
              <td>{otherShift}</td>
            </>
          );
      }
    },
    [activeTab],
  );
  const RenderStatus = useCallback(
    ({ statusComponents, emp }) => {
      let renderStatusList = [];
      switch (activeTab) {
        case 'soft':
          renderStatusList = statusComponents.filter((item) => item.level === 'soft');
          break;
        case 'hard':
          renderStatusList = statusComponents.filter((item) => item.level === 'hard');
          break;
        case 'survey':
          renderStatusList = statusComponents.filter((item) => item.level === 'survey');
          break;

        default:
          break;
      }
      return renderStatusList.map((status) => {
        return (
          <Status
            key={`${status.key}-${uuidv4()} `}
            check={status.value}
            dataDefault={status.data}
            employeeId={emp.employeeId}
            type={status.type}
            available={status.available}
            level={status.level}
            isActive={status.isActive}
          />
        );
      });
    },
    [activeTab],
  );
  const checkValidDate = (date, fromDate, endDate) => {
    const dateCheck = moment(date);
    const isValid =
      (dateCheck.isAfter(moment(fromDate)) && dateCheck.isBefore(moment(endDate))) ||
      date === fromDate ||
      date === endDate;
    return isValid;
  };
  const numDate =
    moment(endDate).diff(moment(fromDate), 'days') +
    1 -
    ((moment(endDate).diff(moment(fromDate), 'days') + 1) * 2) / 7;

  return data?.employeeData?.map((emp, index) => {
    const listDateOffFull = emp.dayOff?.split(',') || [];
    const listWorkingRangeFull = emp.workingDayRange?.split(',') || [];
    const employeeDataWeekend = data.employeeDataWeekend?.find(
      (item) => item.employeeId === emp.employeeId,
    );
    const employSurveyCheck = employSurveyCheckList.find(
      (item) => item.employeeID === emp.employeeId,
    );
    const hoursWeekend = employeeDataWeekend ? employeeDataWeekend.hourAssigned : 0;
    const setDateOff = new Set();
    const setWorkingRange = new Set();
    for (const dateOff of listDateOffFull) {
      if (dateFormatReg.test(dateOff)) {
        const weekday = moment(dateOff).weekday();
        if (weekday !== 0 && weekday !== 6 && checkValidDate(dateOff, fromDate, endDate)) {
          setDateOff.add(dateOff);
        }
      }
    }
    for (const dateWorking of listWorkingRangeFull) {
      if (dateFormatReg.test(dateWorking) && checkValidDate(dateWorking, fromDate, endDate)) {
        setWorkingRange.add(dateWorking);
      }
    }
    const listDateOff = Array.from(setDateOff);
    const workingRange = Array.from(setWorkingRange);
    let numDayOff = listDateOff.length;
    let numWeekend = 0;
    let dayShift = '-';
    let eveShift = '-';
    let nightShift = '-';
    let longShift = '-';
    let otherShift = '-';
    let shiftWish = `-/-`;
    const roleMatched = roleChecking?.find((role) => role.employeeId === emp.employeeId);
    const checkIndex = (array, employeeId) =>
      array ? array.findIndex((el) => el.employeeId === employeeId) > -1 : false;
    const numHolidayAndSunIndex = data.employeeWorkingWeekendAndHoliday?.findIndex(
      (item) => +item.employeeId === +emp.employeeId,
    );
    const numHolidayAndSun =
      numHolidayAndSunIndex > -1
        ? data?.employeeWorkingWeekendAndHoliday[numHolidayAndSunIndex]['numHolidayAndSun'].length
        : 0;
    const roleCheck = !isUnassigned ? checkIndex(data.checkRole, emp.employeeId) : false;
    const workingTooMuchInWeek = checkIndex(data.checkWorkingOver54Hours, emp.employeeId);
    const workingConsitveCheck = checkIndex(data.checkingWorkingInRow, emp.employeeId);
    const timeOffBetweenCheck = checkIndex(data.checkTimeOffBetweenShift, emp.employeeId);
    const workingMoreThanThreeWeekendCheck = checkIndex(
      data.listWorkingMoreThanThreeWeekend,
      emp.employeeId,
    );
    const f1Check = checkIndex(data.listCheckingF1, emp.employeeId);
    const hourOffPerWeekCheck = !isUnassigned
      ? checkIndex(data.checkHourOffPerWeek, emp.employeeId)
      : false;

    //SOFT RULES
    const F1Over50Check = checkIndex(data.checkingF1Over50, emp.employeeId);
    const F2Over50Check = checkIndex(data.checkingF2Over50, emp.employeeId);
    const F1Under50Check = checkIndex(data.checkingF1Under50, emp.employeeId);
    const checkingThreeMoreWeekend = checkIndex(data.checkingMore3Weekend, emp.employeeId);
    const checkingLongShiftAtWeekend = checkIndex(data.checkingWorkingLongWeekend, emp.employeeId);
    const checkingOff2DayAfterNightShift = checkIndex(data.off2DayAfterNightShift, emp.employeeId);
    const checkingOffWeekendBeforeVacation = checkIndex(
      data.checkingOffWeekendBeforeVacation,
      emp.employeeId,
    );
    const checkingDagShiftIfNotWorkingWeekend = checkIndex(
      data.checkingDagShiftIfNotWorkingWeekend,
      emp.employeeId,
    );

    //SURVEY RULES
    const validityPeriodsCheck = checkIndex(employSurveyCheck?.validityPeriods, emp.employeeId);
    const vacationCheck = checkIndex(employSurveyCheck?.vacation, emp.employeeId);
    const typeShiftCheck = checkIndex(employSurveyCheck?.typeShift, emp.employeeId);
    const longShiftWeekendCheck = checkIndex(employSurveyCheck?.longShiftWeekend, emp.employeeId);
    const weekendRotation = checkIndex(employSurveyCheck?.weekendRotation, emp.employeeId);
    const extendedDateOff = checkIndex(employSurveyCheck?.extendDateOff, emp.employeeId);
    const summerPeriodsCheck = checkIndex(employSurveyCheck?.summerPeriods, emp.employeeId);
    const holidayOffCheck = checkIndex(employSurveyCheck?.holidayOff, emp.employeeId);
    const extenedStartShiftCheck = checkIndex(employSurveyCheck?.extenedStartShift, emp.employeeId);
    const extenedEndShiftCheck = checkIndex(employSurveyCheck?.extenedEndShift, emp.employeeId);
    const extenedWeekdayCheck = checkIndex(employSurveyCheck?.extenedWeekday, emp.employeeId);
    const extenedDateRangeCheck = checkIndex(employSurveyCheck?.extenedDateRange, emp.employeeId);

    if (data.shiftMatched && data.totalShift) {
      const matchedIndex = data.shiftMatched.findIndex(
        (shift) => shift.employeeId === emp.employeeId,
      );
      const totalIndex = data.totalShift.findIndex((total) => total.employeeId === emp.employeeId);
      if (matchedIndex > -1 && totalIndex > -1) {
        shiftWish = `${data.shiftMatched[matchedIndex].shiftMatched} / ${data.totalShift[totalIndex].totalShift}`;
      }
    }

    const numWeekendIndex = data.countWorkingWeekend.findIndex(
      (empFind) => empFind.employeeId === emp.employeeId,
    );

    if (numWeekendIndex > -1) {
      numWeekend = data.countWorkingWeekend[numWeekendIndex]['numberWeekend'];
    }

    const listTypeShiftIndex = data.listTypeShift.findIndex(
      (el) => el.employeeId === emp.employeeId,
    );

    if (listTypeShiftIndex > -1) {
      const typeShiftDate = data.listTypeShift[listTypeShiftIndex]['listTypeShift'];
      dayShift = typeShiftDate['day-shift'] || '-';
      eveShift = typeShiftDate['aft-shift'] || '-';
      nightShift = typeShiftDate['evn-shift'] || '-';
      longShift = typeShiftDate['long-shift'] || '-';
      otherShift = typeShiftDate['other-shift'] || '-';
    }
    const statusComponents = [
      { value: roleCheck, key: 'roleCheck', data: data.checkRole, level: 'hard' },

      {
        value: workingConsitveCheck,
        key: 'workingConsitveCheck',
        data: data.checkingWorkingInRow,
        type: 'date',
        available: !isUnassigned,
        level: 'hard',
      },
      {
        value: timeOffBetweenCheck,
        key: 'timeOffBetweenCheck',
        data: data.checkTimeOffBetweenShift,
        type: 'date',
        available: !isUnassigned,
        level: 'hard',
      },
      {
        value: workingTooMuchInWeek,
        key: 'working-over-54-hours"',
        data: data.checkWorkingOver54Hours,
        type: 'date',
        available: !isUnassigned,
        level: 'hard',
      },

      {
        value: workingMoreThanThreeWeekendCheck,
        key: 'workingMoreThanThreeWeekendCheck',
        data: data.listWorkingMoreThanThreeWeekend,
        type: 'week',
        available: !isUnassigned,
        level: 'hard',
      },
      {
        value: hourOffPerWeekCheck,
        key: 'hourOffPerWeekCheck',
        data: data.checkHourOffPerWeek,
        type: 'week',
        available: !isUnassigned,
        level: 'hard',
      },
      {
        value: f1Check,
        key: 'f1-checking',
        data: data.listCheckingF1,
        type: 'week',
        available: !isUnassigned,
        level: 'hard',
      },

      {
        value: F1Over50Check,
        key: 'f1-checking-soft-over-50',
        data: data.checkingF1Over50,
        type: 'date',
        available: !isUnassigned,
        level: 'soft',
      },
      {
        value: F2Over50Check,
        key: 'f2-checking-soft',
        data: data.checkingF2Over50,
        type: 'date',
        available: !isUnassigned,
        level: 'soft',
      },
      {
        value: F1Under50Check,
        key: 'f1-checking-soft-under-50',
        data: data.checkingF1Under50,
        type: 'date',
        available: !isUnassigned,
        level: 'soft',
      },
      {
        value: checkingThreeMoreWeekend,
        key: 'checking-three-weekend-in-row',
        data: data.checkingMore3Weekend,
        type: 'date',
        available: !isUnassigned,
        level: 'soft',
      },
      {
        value: checkingLongShiftAtWeekend,
        key: 'checking-after-working-long-shift-weekend',
        data: data.checkingWorkingLongWeekend,
        type: 'date',
        available: !isUnassigned,
        level: 'soft',
      },
      {
        value: checkingOffWeekendBeforeVacation,
        key: 'checking-off-weekend-before-holiday',
        data: data.checkingOffWeekendBeforeVacation,
        type: 'date',
        available: !isUnassigned,
        level: 'soft',
      },
      {
        value: false,
        key: 'extended-f1',
        data: data.checkingWorkingLongWeekend,
        type: 'date',
        available: !isUnassigned,
        level: 'soft',
      },

      {
        value: checkingDagShiftIfNotWorkingWeekend,
        key: 'not-working-aft-shift-before-off-weekend',
        data: data.checkingWorkingLongWeekend,
        type: 'date',
        available: !isUnassigned,
        level: 'soft',
      },
      {
        value: checkingOff2DayAfterNightShift,
        key: 'two-day-off-after-working-night',
        data: data.off2DayAfterNightShift,
        type: 'date',
        available: !isUnassigned,
        level: 'soft',
      },
      {
        value: validityPeriodsCheck,
        key: 'validity-periods',
        data: employSurveyCheck?.validityPeriods,
        type: 'date',
        available: !isUnassigned,
        level: 'survey',
        isActive: true,
      },
      {
        value: vacationCheck,
        key: 'vacation-check',
        data: employSurveyCheck?.vacation,
        type: 'date',
        available: !isUnassigned,
        level: 'survey',
        isActive: true,
      },
      {
        value: typeShiftCheck,
        key: 'vacation-check',
        data: employSurveyCheck?.typeShift,
        type: 'date',
        available: !isUnassigned,
        level: 'survey',
        isActive: employSurveyCheck?.typeShift,
      },
      {
        value: employSurveyCheck?.avgLateShift,
        key: 'avg-aften-shift',
        data: [],
        type: 'date',
        available: !isUnassigned,
        level: 'survey',
        isActive: employSurveyCheck?.avgLateShift !== null,
      },
      {
        value: employSurveyCheck?.nigthShiftInRow,
        key: 'nigth-shift-in-row',
        data: [],
        type: 'date',
        available: !isUnassigned,
        level: 'survey',
        isActive: employSurveyCheck?.nigthShiftInRow !== null,
      },
      {
        value: longShiftWeekendCheck,
        key: 'nigth-shift-in-row',
        data: employSurveyCheck?.longShiftWeekend,
        type: 'date',
        available: !isUnassigned,
        level: 'survey',
        isActive: employSurveyCheck?.longShiftWeekend,
      },
      {
        value: weekendRotation,
        key: 'weekend-rotation',
        data: employSurveyCheck?.weekendRotation,
        type: 'date',
        available: !isUnassigned,
        level: 'survey',
        isActive: employSurveyCheck?.weekendRotation,
      },
      {
        value: extendedDateOff,
        key: 'weekend-rotation',
        data: employSurveyCheck?.extendDateOff,
        type: 'date',
        available: !isUnassigned,
        level: 'survey',
        isActive: employSurveyCheck?.extendDateOff,
      },
      {
        value: summerPeriodsCheck,
        key: 'summer-periods',
        data: employSurveyCheck?.summerPeriods,
        type: 'week',
        available: !isUnassigned,
        level: 'survey',
        isActive: employSurveyCheck?.summerPeriods,
      },
      {
        value: holidayOffCheck,
        key: 'holiday-off',
        data: employSurveyCheck?.holidayOff,
        type: 'date',
        available: !isUnassigned,
        level: 'survey',
        isActive: employSurveyCheck?.holidayOff,
      },
      {
        value: extenedStartShiftCheck,
        key: 'start-shift',
        data: employSurveyCheck?.extenedStartShift,
        type: 'date',
        available: !isUnassigned,
        level: 'survey',
        isActive: employSurveyCheck?.extenedStartShift,
      },
      {
        value: extenedEndShiftCheck,
        key: 'start-shift',
        data: employSurveyCheck?.extenedEndShift,
        type: 'date',
        available: !isUnassigned,
        level: 'survey',
        isActive: employSurveyCheck?.extenedEndShift,
      },
      {
        value: extenedWeekdayCheck,
        key: 'start-shift',
        data: employSurveyCheck?.extenedWeekday,
        type: 'date',
        available: !isUnassigned,
        level: 'survey',
        isActive: employSurveyCheck?.extenedWeekday,
      },
      {
        value: extenedDateRangeCheck,
        key: 'start-shift',
        data: employSurveyCheck?.extenedDateRange,
        type: 'date',
        available: !isUnassigned,
        level: 'survey',
        isActive: employSurveyCheck?.extenedDateRange,
      },
    ];
    const allocatedHour = +emp.hourAssigned + numDayOff * 7.1;
    const totalValidHour = numDate * 7.1;
    const devision = emp.jobPercentage
      ? allocatedHour - totalValidHour * (+emp.jobPercentage / 100)
      : allocatedHour - totalValidHour;
    return (
      <tr key={`${planSelected.uuid}-${emp.employeeId}`} className="employee-row">
        <td className="no sticky">{!isUnassigned ? index + 1 : totalAssginedEmp + index + 1}</td>
        <td
          className={`name sticky ${
            !!isActiveSurveyDashboard && empSelected.employeeId === emp.employeeId ? 'selected' : ''
          }`}
          style={{ left: leftName }}
        >
          <div className="name__wrap">
            <span>{emp?.name || t('un-assigned')}</span>
          </div>
        </td>
        <td>{emp.role}</td>
        <td>{roleMatched ? `${roleMatched.matchShift} / ${roleMatched.totalShift} ` : ''}</td>
        <td>{emp.jobPercentage}</td>
        <td>{allocatedHour.toFixed(2)}</td>
        <td>{devision.toFixed(2)}</td>
        <RenderEmployeeInfo
          dayShift={dayShift}
          eveShift={eveShift}
          hoursWeekend={hoursWeekend}
          longShift={longShift}
          nightShift={nightShift}
          numDayOff={numDayOff}
          numHolidayAndSun={numHolidayAndSun}
          otherShift={otherShift}
        />
        <RenderStatus emp={emp} statusComponents={statusComponents} />
      </tr>
    );
  });
};

export default EmployeeRow;
