import { t } from 'i18next';
import React, { useState } from 'react';
import './ChangePasswordForm.scss';
import { Toast } from 'helper/alert';
import { CloseOutlined } from '@ant-design/icons';
import axiosClient from 'axios/axiosClient';
import Loading from 'components/Loading/Loading';

const ChangePasswordForm = ({ active, setActive }) => {
  const [changePasswordInfo, setChangePasswordInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setChangePasswordInfo({ ...changePasswordInfo, [name]: value });
  };

  const handleSubmitChangePassWord = async (e) => {
    e.preventDefault();
    if (changePasswordInfo.newPassword !== changePasswordInfo.confirmNewPassword) {
      Toast.fire({
        icon: 'warning',
        title: 'New password and confirm new passworld field must be same',
      });
      return;
    }
    if (Object.keys(changePasswordInfo).length < 3) {
      Toast.fire({
        icon: 'warning',
        title: 'Please fill full field',
      });
      return;
    }
    for (const key of Object.keys(changePasswordInfo)) {
      if (!changePasswordInfo[key].length) {
        Toast.fire({
          icon: 'warning',
          title: 'Please fill full field',
        });
        return;
      }
    }
    setIsLoading(true);
    await axiosClient
      .post('profile/change-password', { ...changePasswordInfo })
      .then((result) => {
        setIsLoading(false);
        if (result.status === 200) {
          Toast.fire({
            icon: 'success',
            title: 'Change password success',
          });
          setActive(false);
        } else {
          Toast.fire({
            icon: 'error',
            title: 'Change password unsuccess',
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);

        Toast.fire({
          icon: 'error',
          title: 'Change password unsuccess',
        });
        console.log(err);
      });
  };
  return (
    <div className="overlay">
      <div className="change-password-form__wrap">
        <form className="change-password-form" onSubmit={handleSubmitChangePassWord}>
          <h5>{t('change-password-form')}</h5>
          <div
            className="change-password-form__close"
            onClick={() => {
              if (!isLoading) {
                setActive(false);
              }
            }}
          >
            <CloseOutlined />
          </div>
          <div className="change-password-form__item">
            <label htmlFor="oldPassword">{t('old-password')}</label>
            <input type="password" name="oldPassword" id="oldPassword" onChange={handleOnChange} />
          </div>
          <div className="change-password-form__item">
            <label htmlFor="newPassword">{t('new-password')}</label>
            <input type="password" name="newPassword" id="newPassword" onChange={handleOnChange} />
          </div>
          <div className="change-password-form__item">
            <label htmlFor="confirmNewPassword">{t('confirm-new-password')}</label>
            <input
              type="password"
              name="confirmNewPassword"
              id="confirmNewPassword"
              onChange={handleOnChange}
            />
          </div>
          <div className="change-password-form__item submit">
            <button disabled={isLoading} className="custom-btn">
              {t('submit')}
            </button>
          </div>
        </form>
      </div>
      {isLoading ? <Loading /> : null}
    </div>
  );
};

export default ChangePasswordForm;
