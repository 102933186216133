import { t } from 'i18next';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const SelectWeekItem = ({ questionId, surveyRecord, setSurveyRecord }) => {
  const survey = { ...surveyRecord };

  if (!survey[questionId]) {
    survey[questionId] = { type: 'select-week', value: '' };
  }
  const handleSelect = (e) => {
    const { value } = e.target;
    survey[questionId].value = value;
    setSurveyRecord(survey);
  };
  return (
    <div className="calendar-plan-survey-form__date__wrap">
      <div className="calendar-plan-survey-form__date__index">{t('select-week')}:</div>
      <div className="calendar-plan-survey-form__date__group">
        <div className="calendar-plan-survey-form__date__item"></div>
        <select
          name=""
          id=""
          className="select-week"
          onChange={handleSelect}
          value={survey[questionId].value}
        >
          <option key={uuidv4()} value="">
            {t('unselected')}
          </option>
          {Array.from({ length: 52 }).map((_, index) => {
            return (
              <option key={uuidv4()} value={index + 1}>
                {t('week')} {index + 1}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default SelectWeekItem;
