import React, { Fragment, useEffect, useState } from 'react';
import { CaretDownOutlined } from '@ant-design/icons';

import './FilterDepartment.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectGlobal } from 'redux/global/globalSlice';
import {
  selectDepartmentAction,
  selectUnitAction,
  unSelectDepartmentAction,
  unSelectUnitAction,
} from 'redux/global/globalAction';
import { Toast } from 'helper/alert';
import { t } from 'i18next';
import { useLocation } from 'react-router-dom';

export default function FilterDepartment({ departmentArr }) {
  const dispatch = useDispatch();
  const [departmentActive, setDepartmentActive] = useState([]);
  const path = useLocation().pathname;
  const ignoreArr = ['/calendar'];
  const ignoreUnitDay = ignoreArr.includes(path);
  const { departmentSelectedArr, unitSelectedArr } = useSelector(selectGlobal);
  const handleDropDepartmentList = (val) => {
    if (departmentActive.includes(val)) {
      const activeArray = departmentActive.filter((item) => item !== val);
      setDepartmentActive(activeArray);
    } else {
      setDepartmentActive([...departmentActive, val]);
    }
  };
  const handleChangeSelectDepartment = (val, unitArr, departmentName) => {
    const unitCodes = unitArr?.map((item) => item.unitCode);
    !ignoreUnitDay &&
      Toast.fire({
        title: `${t('loading-data')}`,
        timerProgressBar: true,
        timer: false,

        didOpen: () => {
          Toast.showLoading();
        },
      });
    if (departmentSelectedArr?.includes(isNaN(val) ? val : +val)) {
      dispatch(unSelectDepartmentAction(unitCodes, isNaN(val) ? val : +val, departmentName));
    } else {
      dispatch(
        selectDepartmentAction(isNaN(val) ? val : +val, unitCodes, departmentName, ignoreUnitDay),
      );
    }
  };

  const handleChangSelectUnit = (val, departmentCode, totalUnitOfDepartment, unitName) => {
    Toast.fire({
      title: `${t('loading-data')}`,
      timerProgressBar: true,
      timer: false,
      didOpen: () => {
        Toast.showLoading();
      },
    });
    if (unitSelectedArr?.includes(+val)) {
      dispatch(unSelectUnitAction(+val, totalUnitOfDepartment, departmentCode, unitName));
    } else {
      dispatch(selectUnitAction(+val, departmentCode, unitName));
    }
  };

  const renderDepartment = () => {
    return departmentArr?.map((item) => {
      return (
        <Fragment key={item.departmentCode}>
          <div className="filter-department__group">
            <div className="filter-department__head">
              <label>
                <input
                  className="filter-department__checkbox"
                  type={!ignoreUnitDay ? 'checkbox' : 'radio'}
                  checked={departmentSelectedArr?.includes(item.departmentCode)}
                  value={item.departmentCode}
                  onChange={(e) => {
                    handleChangeSelectDepartment(e.target.value, item.unitArr, item.departmentName);
                  }}
                />
                {item.departmentName}
              </label>
              {!ignoreUnitDay ? (
                <div
                  className={`filter-department__head-icon ${
                    departmentActive.includes(item.departmentCode) && 'active'
                  }`}
                  onClick={() => handleDropDepartmentList(item.departmentCode)}
                >
                  {item.unitArr?.length > 1 && <CaretDownOutlined />}
                </div>
              ) : (
                <></>
              )}
            </div>
            {departmentActive.includes(item.departmentCode) && !ignoreUnitDay ? (
              <div className="filter-department__list">
                {item.unitArr.map((unit) => {
                  if (item.departmentName !== unit.unitName)
                    return (
                      <Fragment key={unit.unitCode}>
                        <div className="filter-department__item">
                          <label>
                            <input
                              checked={unitSelectedArr?.includes(unit.unitCode)}
                              className="filter-department__checkbox-unit"
                              type="checkbox"
                              value={unit.unitCode}
                              onChange={(e) => {
                                const totalUnitOfDepartment = item.unitArr.map(
                                  (totalUnit) => totalUnit.unitCode,
                                );
                                handleChangSelectUnit(
                                  unit.unitCode,
                                  item.departmentCode,
                                  totalUnitOfDepartment,
                                  item.departmentName,
                                  unit.unitName,
                                );
                              }}
                            />
                            {/* {unit.unitName} */}
                            {process.env.REACT_APP_SITE === 'demo'
                              ? `${t('unit')} ${unit.unitName.split(' ')[1]}`
                              : unit.unitName}
                          </label>
                        </div>
                      </Fragment>
                    );
                })}
              </div>
            ) : (
              <></>
            )}
          </div>
        </Fragment>
      );
    });
  };
  return <div className="filter-department__wrap"> {renderDepartment()}</div>;
}
