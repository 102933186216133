import { t } from 'i18next';
import moment from 'moment';
import React from 'react';

const NewRangeDate = ({ questionId, surveyRecord, setSurveyRecord }) => {
  const survey = { ...surveyRecord };
  if (!survey[questionId]) {
    survey[questionId] = [];
  }
  let index = survey[questionId].length;
  return (
    <div key={Math.random()} className="calendar-plan-survey-form__date__wrap">
      <div className="calendar-plan-survey-form__date__index">{t('new-range')}:</div>
      <div className="calendar-plan-survey-form__date__group">
        <div className="calendar-plan-survey-form__date__item">
          <label htmlFor="">{t('from')}</label>
          <input
            type="date"
            name=""
            id=""
            onChange={(e) => {
              const { value } = e.target;
              survey[questionId][index] = {
                ...survey[questionId][index],
                fromDate: new Date(value),
                type: 'date',
              };

              if (moment(value).year() > 1970) {
                setSurveyRecord(survey);
              }
            }}
          />
        </div>
        <div className="calendar-plan-survey-form__date__item">
          <label htmlFor="">{t('to')}</label>

          <input
            type="date"
            name=""
            id=""
            onChange={(e) => {
              const { value } = e.target;
              survey[questionId][index] = {
                ...survey[questionId][index],
                toDate: new Date(value),
                type: 'date',
              };
              if (moment(value).year() > 1970) {
                setSurveyRecord(survey);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default NewRangeDate;
