import React, { createRef, useCallback, useEffect, useRef, useState } from 'react';

import _ from 'lodash';
import { useClickOutside } from 'helper/useClickOutSide';
import moment from 'moment';
import axiosClient from 'axios/axiosClient';
import { Toast } from 'helper/alert';
import { t } from 'i18next';
import { CloseOutlined, PlusSquareOutlined } from '@ant-design/icons';
import AddTask from '../AddTask/AddTask';
import CalendarPlanFormTable from 'components/CalendarPlan/CalendarPlanFormTable/CalendarPlanFormTable';
import { useDispatch, useSelector } from 'react-redux';
import { selectCalendarPlan } from 'redux/calendarPlan/calendarPlanSlice';
import {
  addPlanStaffTaskAction,
  deletePlanStaffTaskAction,
  handlePasteWeekStaffPlanDetailAction,
  initPlanStaffTaskAction,
  updatePlanStaffTaskAction,
  updatePlanStaffTaskDetailAction,
  updatePlanStaffTaskRangeDateAction,
  updatePlanStaffTaskTimeAction,
} from 'redux/calendarPlan/calendarPlanAction';
import { dateFormatReg } from 'constant';

import './CreateStaffPlanForm.scss';

export default function CreateStaffPlanForm({
  setActiveCreateForm,
  isUpdated,
  setIsUpdated,
  planTaskDefault,
  unitSelected,
}) {
  const dispatch = useDispatch();
  const { planStaffTask } = useSelector(selectCalendarPlan);
  const [planTask, setPlanTask] = useState({
    from: moment().clone().startOf('W').format('YYYY-MM-DD'),
    to: moment().clone().endOf('W').format('YYYY-MM-DD'),
    task: {},
  });
  const [planTaskDisplay, setPlanTaskDisplay] = useState({
    from: moment().clone().startOf('W').format('YYYY-MM-DD'),
    to: moment().clone().endOf('W').format('YYYY-MM-DD'),
    task: {},
  });

  const addTaskRef = useRef();
  const [taskList, setTaskList] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [taskSelected, setTaskSelected] = useState([]);
  const [isSelectTask, setIsSelectTask] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [listGroup, setListGroup] = useState([]);

  const enumerateDaysBetweenDates = (startDate, endDate) => {
    let dates = [];

    let currDate = moment(startDate).startOf('day');
    let lastDate = moment(endDate).startOf('day');

    while (currDate.diff(lastDate) <= 0) {
      dates.push(currDate.clone().format('YYYY-MM-DD'));
      currDate.add(1, 'days');
    }

    return dates;
  };

  const handleOnChange = (e) => {
    let value = e.target.value;

    const depatchValue = { key: e.target.name, value };

    dispatch(updatePlanStaffTaskRangeDateAction(depatchValue));
    setPlanTask({ ...planTask, [e.target.name]: value });
  };

  const handleAddTask = (task) => {
    const prevPlanTask = { ...planStaffTask };
    const key = `${task.uuid}-duplicate${Math.random(10)}`;

    const updatedTask = {
      ...task,
      taskName: task.task_name,
    };
    dateRange.forEach((date) => {
      updatedTask[date] = 0;
    });

    let updatedTaskList = {
      ...prevPlanTask.task,
      [key]: { ...updatedTask, key },
    };

    const result = { ...prevPlanTask, task: updatedTaskList };
    dispatch(addPlanStaffTaskAction(result));
    setPlanTaskDisplay(result);
    setIsSelectTask(false);
  };

  const handleOnDeleteTask = useCallback((value) => {
    const planDetailArr = Object.keys(value)
      .map((key) => {
        if (dateFormatReg.test(key)) {
          return value[key]['uuid'];
        }
      })
      .filter((item) => item);
    axiosClient
      .post('/calendar-plan/staff-plan/delete-task', { planDetailArr })
      .then((_) => {
        setIsUpdated(!isUpdated);
      })
      .catch((err) => {
        console.log(err);
      });
    dispatch(deletePlanStaffTaskAction(value));
  }, []);
  const handleOnChangeTime = useCallback((value) => {
    dispatch(updatePlanStaffTaskTimeAction(value));
  }, []);

  const handleOnChangeAllValueEachTask = useCallback((value) => {
    dispatch(updatePlanStaffTaskAction(value));
  }, []);
  const handleOnChangePlanDetail = useCallback((key, value, taskUUID) => {
    const dispatchValue = { key, value, taskUUID };
    dispatch(updatePlanStaffTaskDetailAction(dispatchValue));
  }, []);
  const handlePasteWeekStaffPlanDetail = useCallback((weekData, taskUUID) => {
    const dispatchValue = { weekData, taskUUID };
    dispatch(handlePasteWeekStaffPlanDetailAction(dispatchValue));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const planDetail = [];
    const taskDetail = [];
    const planInfo = {
      plan_uuid: planStaffTask.plan_uuid,
      plan_id: planStaffTask.plan_id,
      plan_name: planStaffTask['plan_name'],
      from_date: planStaffTask['from'],
      to_date: planStaffTask['to'],
      unit_code: unitSelected,
    };
    const planTaskSelectedKey = Object.keys(planStaffTask.task);
    planTaskSelectedKey.forEach((taskUuid) => {
      const task = planStaffTask.task[taskUuid];
      const dates = Object.keys(task);
      dates.forEach((date) => {
        if (dateRange.includes(date)) {
          planDetail.push({
            task_uuid: task.uuid,
            task_id: task.id,
            task_role_id: task.roleId,
            plan_name: planStaffTask.plan_name,
            date,
            weekday: moment(date).weekday(),
            number_person: +task[date]['numberPerson'] || 0,
            uuid: task[date]['uuid'],
            fromTime: task['from_time'],
            toTime: task['to_time'],
            key: task.key,
          });
        }
      });
      taskDetail.push({
        taskUuid: task.uuid,
        fromTime: task['from_time'],
        toTime: task['to_time'],
      });
    });
    const apiUrl = planTaskDefault
      ? 'calendar-plan/staff-plan/update'
      : 'calendar-plan/staff-plan/create';
    setIsUpdating(true);
    axiosClient
      .post(apiUrl, { planDetail, planInfo, taskDetail, unitCode: unitSelected })
      .then((result) => {
        setActiveCreateForm(false);
        setIsUpdated(!isUpdated);
        setIsUpdating(false);
        dispatch(
          initPlanStaffTaskAction({
            from: moment().clone().startOf('W').format('YYYY-MM-DD'),
            to: moment().clone().endOf('W').format('YYYY-MM-DD'),
            task: {},
          }),
        );
        Toast.fire({
          icon: 'success',
          title: `${t(`${planTaskDefault ? 'update' : 'create'}`)} ${t('success')}`,
        });
      })
      .catch((err) => {
        setActiveCreateForm(false);
        setIsUpdated(!isUpdated);
        setIsUpdating(false);
        Toast.fire({
          icon: 'error',
          title: `${t(`${planTaskDefault ? 'update' : 'create'}`)} ${t('unsuccess')}`,
        });
      });
  };
  useClickOutside(addTaskRef, () => {
    setIsSelectTask(false);
  });

  useEffect(() => {
    setDateRange(enumerateDaysBetweenDates(planTask.from, planTask.to));
  }, [planTask.from, planTask.to]);
  useEffect(() => {
    axiosClient
      .post('calendar-plan/staff-task-all', {
        unitCode: unitSelected,
      })
      .then((result) => {
        setTaskList(result.data);
      })
      .catch((err) => {
        Toast.fire({ icon: 'error', title: `${t('data-load')} ${t('unsuccess')}` });
      });

    axiosClient.post('calendar-plan/group', { unitSelected }).then((result) => {
      setListGroup(result.data);
    });
  }, [unitSelected]);

  useEffect(() => {
    if (planTaskDefault && taskList.length) {
      const taskSelect = [];
      let planTaskUpdate = { ...planTaskDefault };
      Object.keys(planTaskDefault.task).forEach((key) => {
        let taskFound = taskList.find((item) => item.uuid === planTaskDefault.task[key]['uuid']);
        if (taskFound) {
          taskFound = {
            ...taskFound,
            ...planTaskDefault['task'][key],
            taskName: taskFound['task_name'],
          };
          taskSelect.push(taskFound);
        }
      });
      planTaskUpdate = { ...planTaskUpdate, task: taskSelect };
      setPlanTask(planTaskUpdate);
      setPlanTaskDisplay(planTaskUpdate);
      dispatch(initPlanStaffTaskAction(planTaskUpdate));
    } else {
      dispatch(
        initPlanStaffTaskAction({
          from: moment().clone().startOf('W').format('YYYY-MM-DD'),
          to: moment().clone().endOf('W').format('YYYY-MM-DD'),
          task: {},
        }),
      );
    }
  }, [planTaskDefault, taskList.length]);

  return (
    <div className="create-staff-plan-form__wrap">
      <div className="create-staff-plan-form__content">
        <div className="create-staff-plan-form__header">
          <h3 className="create-staff-plan-form__title">{t('staff-plan')}</h3>
          <CloseOutlined
            onClick={() => {
              setActiveCreateForm(false);
            }}
          />
        </div>
        <form onSubmit={handleSubmit} className="create-staff-plan-form">
          <div className="create-staff-plan-form__info">
            <div className="plan-name">{t('plan-name')}</div>
            <div className="create-staff-plan-form__info-group">
              <input
                className="create-staff-plan-form__info-group__input"
                type="text"
                name="plan_name"
                onChange={handleOnChange}
                value={planTask.plan_name}
                placeholder="Enter plan name"
                required
              />
              <div className="create-staff-plan-form__info-group__item">
                <label htmlFor="" className="create-staff-plan-form__info-group__label">
                  {t('from-date')}
                </label>
                <input
                  type="date"
                  value={planTask.from}
                  onChange={handleOnChange}
                  name="from"
                  id=""
                  required
                />
              </div>
              <div className="create-staff-plan-form__info-group__item">
                <label htmlFor="" className="create-staff-plan-form__info-group__label">
                  {t('to-date')}
                </label>
                <input
                  type="date"
                  value={planTask.to}
                  onChange={handleOnChange}
                  name="to"
                  id=""
                  required
                />
              </div>
            </div>
          </div>
          <div className="plan-task-table__wrap">
            <CalendarPlanFormTable
              dateRange={dateRange}
              data={planTaskDisplay}
              allTask={taskList}
              listGroup={listGroup}
              handleOnChangePlanDetail={handleOnChangePlanDetail}
              handleOnChangeAllValueEachTask={handleOnChangeAllValueEachTask}
              handleOnChangeTime={handleOnChangeTime}
              handleOnDeleteTask={handleOnDeleteTask}
              handlePasteWeekStaffPlanDetail={handlePasteWeekStaffPlanDetail}
              tab="staff"
            />
          </div>
          <div className="addMoreRow__wrap" ref={addTaskRef}>
            <div
              className="addMoreRow"
              onClick={() => {
                setIsSelectTask(true);
              }}
              id="addMoreRow"
            >
              <PlusSquareOutlined />
              <p className="add-task">
                {t('add')} {t('staff-task-list')}
              </p>
            </div>
            {!!isSelectTask ? (
              <AddTask
                isActive={isSelectTask}
                setIsActive={setIsSelectTask}
                taskList={taskList}
                taskSelected={taskSelected}
                setTaskSelected={setTaskSelected}
                fromDate={planStaffTask.from}
                planTask={planTask}
                setPlanTask={setPlanTask}
                handleAddTask={handleAddTask}
                listGroup={listGroup}
              />
            ) : (
              <></>
            )}
          </div>

          <div className="create-staff-plan-form__submit">
            <button
              type="button"
              disabled={isUpdating}
              className="custom-btn none-bg"
              onClick={() => {
                setActiveCreateForm(false);
              }}
            >
              {t('cancel')}
            </button>
            <button className={`custom-btn ${isUpdating ? 'disable' : ''}`} disabled={isUpdating}>
              {t(`${planTaskDefault ? 'update' : 'create-plan-form'}`)}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
