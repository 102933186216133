import React, { useEffect, useState } from 'react';
import './UploadStaffPlan.scss';
import { CloseOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import axiosClient from 'axios/axiosClient';
import { Toast } from 'helper/alert';
import Loading from 'components/Loading/Loading';

const UploadStaffPlan = ({
  setActive,
  unitSelected,
  planSelected,
  setIsOptimized,
  setIsLoading,
}) => {
  const [planInfo, setPlanInfo] = useState({ importType: 'googleSheet' });
  const [isUploading, setIsUploading] = useState(false);
  const handleGetIdSheet = (e) => {
    const { value, name } = e.target;
    const sheetIdReg = /\/d\/([a-zA-Z0-9_-]+)\//;
    if (value && value.match(sheetIdReg)) {
      setPlanInfo({
        ...planInfo,
        [name]: value.match(sheetIdReg)[1],
      });
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setPlanInfo({
      ...planInfo,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsUploading(true);
    setIsOptimized(true);
    axiosClient
      .post('calendar-plan/staff-schedule/upload-google-sheet', {
        ...planInfo,
        unitCode: unitSelected,
        planId: planSelected.id,
      })
      .then((res) => {
        Toast.fire({ icon: 'success', title: `${t('upload-staff-plan')} ${t('success')}` });
        setIsLoading(false);
        setIsOptimized(false);
        setActive(false);
        setIsUploading(false);
      });
  };
  return (
    <div className="upload-staff-plan__wrap">
      {isUploading ? (
        <div className="overlay">
          <Loading />
        </div>
      ) : null}
      <div className="upload-staff-plan__form">
        <h3 className="upload-staff-plan__header">{t('upload-staff-plan')}</h3>
        <div className="upload-staff-plan__close">
          <CloseOutlined onClick={() => setActive(false)} />
        </div>
        <form action="" onSubmit={handleSubmit}>
          <div className="upload-staff-plan__form-group">
            <div className="upload-staff-plan__form-item">
              <label htmlFor="">{t('from-date')}</label>
              <input type="date" name="fromDate" id="" onChange={handleOnChange} required />
            </div>
            <div className="upload-staff-plan__form-item">
              <label htmlFor="">{t('to-date')}</label>
              <input type="date" name="endDate" id="" onChange={handleOnChange} required />
            </div>
          </div>

          <div className="upload-staff-plan__form-item">
            <label htmlFor="">Link</label>
            <input type="text" name="googleSheetId" onChange={handleGetIdSheet} required />
          </div>
          <div className="upload-staff-plan__form-submit">
            <button className="custom-btn">{t('upload')}</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UploadStaffPlan;
