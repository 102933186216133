import axiosClient from 'axios/axiosClient';
import Pagination from 'components/Pagination/Pagination';
import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import CalendarStaffPlanListTable from '../CalendarStaffPlanListTable/CalendarStaffPlanListTable';
import CreateStaffPlanForm from '../CreateStaffPlanForm/CreateStaffPlanForm';
import { read, utils, readFile } from 'xlsx';
import { Toast } from 'helper/alert';
import { getColName } from 'helper/getColumnOfExcelName';
import moment from 'moment';
import Loading from 'components/Loading/Loading';
import UploadFileForm from 'components/CalendarPlan/UploadFileForm/UploadFileForm';
import './PlanListContent.scss';

export default function PlanListContent({ unitSelected }) {
  const [planList, setPlanList] = useState([]);
  const [totalPlan, setTotalPlan] = useState(0);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [page, setPage] = useState(1);
  const [activeCreateForm, setActiveCreateForm] = useState(false);
  const importStaffPlanRef = useRef(null);
  const [isImportFile, setIsImportFile] = useState(false);

  const headerList = ['Plan Name', 'From Date', 'To Date', 'Task List', 'Action'];

  const handleFileChange = async (e, planInfo, setIsUploadFile) => {
    try {
      const file = e.target.files[0];
      if (!file || isImportFile) {
        return;
      }
      const fileName = file.name || '';
      const dateInFileNameReg = /(\d{6,6})\W(\d{6,6})/;
      const fromDate = moment(planInfo.from);
      const endDate = moment(planInfo.to);
      const title = ['Dagvakt', 'Aftenvakt', 'Adm', 'Nattvakt', 'Kombinasjonskode'];
      const copyShiftSymbol = '-->';
      const shiftReg = /\w{1,}(\s\(\w{1,}:\w{1,}-\w{1,}:\w{1,}\))/;
      const shiftTimeReg = /(\s\(\w{1,}:\w{1,}-\w{1,}:\w{1,}\))/;
      const dataResult = [];
      const listTaskName = [];
      setIsImportFile(true);
      await file.arrayBuffer().then((data) => {
        const workbook = read(data);

        const sheet = workbook['Sheets'];
        const bemanningsplanData = sheet['Bemanningsplan'];
        if (bemanningsplanData) {
          const range = utils.decode_range(bemanningsplanData['!ref']);
          const starRow = 2;
          const endColumn = range['e']['c'];
          const endRow = range['e']['r'];
          for (let i = starRow; i <= endRow + 1; i++) {
            // row loop
            let taskName = '';
            let roleKey = null;
            if (title.includes(bemanningsplanData[`A${i}`].v)) {
              continue;
            }
            for (let j = 0; j <= endColumn; j++) {
              // column loop
              const column = getColName(j);
              const columnData = bemanningsplanData[`${column}${i}`];
              if (columnData) {
                if (shiftReg.test(columnData.v)) {
                  taskName = columnData.v.replace(shiftTimeReg, '');
                  listTaskName.push({ taskName, roleKey: null });
                } else if (columnData.v == copyShiftSymbol) {
                  roleKey = bemanningsplanData[`B${i}`].v;
                  taskName = bemanningsplanData[`A${i - 1}`].v.replace(shiftTimeReg, '');
                  listTaskName.push({ taskName, roleKey });
                } else {
                  if (j > 1) {
                    const date = fromDate
                      .clone()
                      .add(j - 2, 'd')
                      .format('YYYY-MM-DD');
                    let numberPerson = columnData.v;
                    if (bemanningsplanData[`A${i + 1}`]?.v == copyShiftSymbol) {
                      const slicePerson = +bemanningsplanData[`${column}${i + 1}`].v;
                      numberPerson = numberPerson - slicePerson || 0;
                    }
                    dataResult.push({
                      taskName,
                      roleKey,
                      date,
                      numberPerson,
                    });
                  }
                }
              } else {
                if (j > 1) {
                  const date = fromDate
                    .clone()
                    .add(j - 2, 'd')
                    .format('YYYY-MM-DD');
                  dataResult.push({
                    taskName,
                    roleKey,
                    date,
                    numberPerson: 0,
                  });
                }
              }
            }
          }
        }
      });

      axiosClient
        .post('/calendar-plan/upload-staff-plan', {
          data: dataResult,
          listTaskName,
          fileName: planInfo.planName,
          fromDate: fromDate.format('YYYY-MM-DD'),
          endDate: endDate.format('YYYY-MM-DD'),
          unitCode: unitSelected,
        })
        .then((_) => {
          setIsImportFile(false);
          setIsUpdated(!isUpdated);
          setIsUploading(false);
          setIsUploadFile(false);
        })
        .catch((err) => {
          setIsImportFile(false);
          Toast.fire({ icon: 'error', title: `${t('submit')} ${t('fail')}` });
          setIsUploading(false);
          setIsUploadFile(false);
        });
    } catch (error) {
      setIsImportFile(false);
      Toast.fire({ icon: 'error', title: `${t('submit')} ${t('fail')}` });
    }
  };

  useEffect(() => {
    axiosClient
      .post('/calendar-plan/staff-plan', { page, unitCode: unitSelected })
      .then((result) => {
        setPlanList(result.result);
        setTotalPlan(result.total);
      });
  }, [isUpdated, page, unitSelected]);
  return (
    <div className="plan-list-content__wrap">
      {isImportFile ? (
        <div className="overlay">
          <Loading />
        </div>
      ) : null}
      <div className="plan-list-content__header">
        <h1>{t('plan-list')}</h1>
      </div>
      <div className="plan-list-content__body">
        <CalendarStaffPlanListTable
          headerList={headerList}
          planList={planList}
          setPlanList={setPlanList}
          isUpdated={isUpdated}
          setIsUpdated={setIsUpdated}
          page={page}
          setPage={setPage}
          unitSelected={unitSelected}
        />
      </div>
      <div className="plan-list-content__header">
        <Pagination page={page} setPage={setPage} totalPage={Math.ceil(totalPlan / 10)} />

        <div className="plan-list-content__feature">
          <button
            onClick={() => {
              // importStaffPlanRef.current.click();
              setIsUploading(true);
            }}
            className={`custom-btn`}
          >
            {t('upload-new-plan')}
          </button>
          <input
            ref={importStaffPlanRef}
            type="file"
            name=""
            accept=".xlsx, .xls"
            id=""
            className="hide-element"
            onChange={handleFileChange}
          />
          <button
            className="custom-btn"
            onClick={() => {
              setActiveCreateForm(true);
            }}
          >
            {t('create-new-plan')}
          </button>
        </div>
      </div>
      {isUploading ? (
        <UploadFileForm
          active={isUploading}
          setActive={setIsUploading}
          handleFileChange={handleFileChange}
          unitSelected={unitSelected}
          setIsUpdated={setIsUpdated}
          isUpdated={isUpdated}
        />
      ) : null}
      {activeCreateForm ? (
        <CreateStaffPlanForm
          setActiveCreateForm={setActiveCreateForm}
          planList={planList}
          setPlanList={setPlanList}
          isUpdated={isUpdated}
          setIsUpdated={setIsUpdated}
          unitSelected={unitSelected}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
