import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import axiosClient from 'axios/axiosClient';
import { Toast } from 'helper/alert';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectGlobal } from 'redux/global/globalSlice';

export default function TopicRow({ topic, isUpdate, setIsUpdate }) {
  const [defaultTopic, setDefaultTopic] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const { language } = useSelector(selectGlobal);
  const handleOnChange = (e) => {
    setDefaultTopic({ ...defaultTopic, [e.target.name]: e.target.value });
  };
  const handleSubmit = () => {
    axiosClient
      .post('calendar-plan/survey-topic/update', defaultTopic)
      .then((result) => {
        Toast.fire({ icon: 'success', title: `${t('update')} ${t('success')}` });
        setIsEditing(false);
        setIsUpdate(!isUpdate);
      })
      .catch((err) => {
        Toast.fire({ icon: 'error', title: `${t('update')} ${t('unsuccess')}` });
      });
  };
  const handleDelete = () => {
    axiosClient
      .post('calendar-plan/survey-topic/delete', { topic: defaultTopic })
      .then((result) => {
        Toast.fire({ icon: 'success', title: `${t('delete')} ${t('success')}` });
        setIsUpdate(!isUpdate);
      })
      .catch((err) => {
        Toast.fire({ icon: 'error', title: `${t('delete')} ${t('unsuccess')}` });
      });
  };
  useEffect(() => {
    setDefaultTopic(topic);
  }, [topic]);
  return (
    <div className="topic-management__item">
      <div className="topic-name">
        {isEditing ? (
          <div className="topic-edit__group">
            <label htmlFor="">{t('en-version')}</label>{' '}
            <input
              name="topic"
              type="text"
              value={defaultTopic.topic}
              onChange={handleOnChange}
              autoFocus
            />
            <label htmlFor="">{t('no-version')}</label>{' '}
            <input
              name="no_ver"
              type="text"
              value={defaultTopic.no_ver}
              onChange={handleOnChange}
            />
            <label htmlFor="">{t('order')}</label>{' '}
            <input
              name="order"
              type="number"
              value={defaultTopic.order}
              onChange={handleOnChange}
              required
            />
          </div>
        ) : (
          <h5>
            {defaultTopic.order}.{language === 'NO' ? defaultTopic.no_ver : defaultTopic.topic}
          </h5>
        )}
      </div>
      <div className="topic-action">
        {isEditing ? (
          <>
            <CheckOutlined onClick={handleSubmit} />
            <CloseOutlined
              onClick={() => {
                setIsEditing(false);
              }}
            />
          </>
        ) : (
          <>
            <EditOutlined
              onClick={() => {
                setIsEditing(true);
              }}
            />
            <DeleteOutlined onClick={handleDelete} />
          </>
        )}
      </div>
    </div>
  );
}
