import React, { useEffect } from 'react';
import { useState } from 'react';
import './SwitchButton.scss';

export default function SwitchButton({ obj1, obj2, setValue, value }) {
  const [switchTo, setSwitchTo] = useState('left');

  useEffect(() => {
    if (value === obj1.value) {
      setSwitchTo('left');
    } else {
      setSwitchTo('right');
    }
  }, []);
  return (
    <div className="switch__wrap">
      <div
        className={`switch__item ${value == obj1.value ? 'active-side' : ''}`}
        onClick={() => {
          if (value !== obj1.value) {
            setSwitchTo('left');
            setValue(obj1.value);
          }
        }}
      >
        {obj1.name}
      </div>
      <div
        className={`switch__item ${value == obj2.value ? 'active-side' : ''}`}
        onClick={() => {
          if (value !== obj2.value) {
            setSwitchTo('right');
            setValue(obj2.value);
          }
        }}
      >
        {obj2.name}
      </div>
      <span className={`active ${switchTo}`}></span>
    </div>
  );
}
