import { t } from 'i18next';
import moment from 'moment';
import React from 'react';
export const weekdayKey = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

const StatusTable = ({ statusData, type }) => {
  return (
    <>
      {statusData.length ? (
        <table className="status-table">
          <thead>
            <th>{t(type)}</th>
            {type === 'week' ? <th>{t('year')}</th> : null}
          </thead>
          <tbody>
            {statusData.map((item) => {
              const week = moment(item[type]).week();
              const year = moment(item[type]).year();
              let weekday = moment(item[type]).weekday();

              const str = `${t('week')} ${week} - ${year} | ${t(weekdayKey[weekday])}`;
              return (
                <tr key={`${item.employeeId}-${item[type]}-${item.year}-status-check`}>
                  <td>{type === 'week' ? item[type] : str}</td>
                  {type === 'week' ? <td>{item.year}</td> : null}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : null}
    </>
  );
};

export default StatusTable;
