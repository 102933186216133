import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import axiosClient from 'axios/axiosClient';
import { Toast } from 'helper/alert';
import { t } from 'i18next';
import React, { useState } from 'react';

export default function CalendarPlanRoleRowNew({ setActive, isUpdate, setIsUpdate }) {
  const [role, setRole] = useState({ role: '', description: '' });
  const handleOnChange = (e) => {
    setRole({ ...role, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    let isValid = true;
    for (const key of Object.keys(role)) {
      if (role[key].length == 0 && key !== 'description') {
        isValid = false;
      }
    }
    if (isValid) {
      axiosClient
        .post('calendar-plan/role/create', { role })
        .then((_) => {
          Toast.fire({ icon: 'success', title: `${t('create-role')} ${t('success')}` });
          setActive(false);
          setIsUpdate(!isUpdate);
        })
        .catch((err) => {
          Toast.fire({ icon: 'error', title: `${t('create-role')} ${t('unsuccess')}` });
        });
    } else {
      Toast.fire({ icon: 'error', title: `${t('please-input-all')} ` });
    }
  };
  return (
    <tr className="calendar-plan-create-role__item">
      <td className="calendar-plan-create-role__item-label">
        <input
          autoFocus={true}
          name="role"
          type="text"
          value={role.role}
          onChange={handleOnChange}
          required
        />
      </td>
      <td className="calendar-plan-create-role__item-label">
        <input name="key" type="text" value={role.key} onChange={handleOnChange} required />
      </td>
      <td className="calendar-plan-create-role__item-label">
        <input name="rank" type="number" value={role.rank} onChange={handleOnChange} required />
      </td>
      <td className="calendar-plan-create-role__item-description">
        <input name="description" type="text" value={role.description} onChange={handleOnChange} />
      </td>
      <td className="calendar-plan-create-role__item-action">
        <CheckOutlined onClick={handleSubmit} />
        <CloseOutlined
          onClick={() => {
            setActive(false);
          }}
        />
      </td>
    </tr>
  );
}
