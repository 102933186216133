import { configureStore } from '@reduxjs/toolkit';
import authSlice from 'redux/auth/authSlice';
import calendarPlanSlice from 'redux/calendarPlan/calendarPlanSlice';
import globalSlice from 'redux/global/globalSlice';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    global: globalSlice,
    calendarPlan: calendarPlanSlice,
  },
});
