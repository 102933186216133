import React, { useEffect, useState } from 'react';
import './SurveyContentManagerment.scss';
import { t } from 'i18next';
import { CloseOutlined } from '@ant-design/icons';
import { useQuery } from 'react-query';
import axiosClient from 'axios/axiosClient';
import { Toast } from 'helper/alert';

const SurveyContentManagerment = ({ unitSelected, setActive }) => {
  const [intro, setIntro] = useState({});
  const handleOnChange = (e) => {
    const { value, name } = e.target;
    setIntro({ ...intro, [name]: value });
  };
  const handleSubmit = () => {
    axiosClient
      .post(`calendar-plan/survey-intro/${intro.id ? 'update' : 'create'}`, {
        intro,
      })
      .then((res) => {
        setActive(false);
        Toast.fire({
          icon: 'success',
          title: t(`${intro.id ? 'update-intro-success' : 'create-intro-success'}`),
        });
      })
      .catch((err) => {
        Toast.fire({
          icon: 'error',
          title: t(`${intro.id ? 'update-intro-unsuccess' : 'create-intro-unsuccess'}`),
        });
      });
  };

  const introData = useQuery(`${unitSelected}-introData`, () =>
    axiosClient.post('calendar-plan/survey-intro').then((res) => res),
  );

  useEffect(() => {
    if (introData.data) {
      const { content, content_no, id } = introData.data;
      setIntro({ surveyIntro: content, surveyIntroNo: content_no, id } || {});
    } else {
      setIntro({ surveyIntro: '', surveyIntroNo: '' } || {});
    }
  }, [introData.data, unitSelected]);

  return (
    <div className="survey-content-managerment__wrap">
      <div className="survey-content-managerment">
        <div className="survey-content-managerment__group">
          <div className="close">
            <CloseOutlined onClick={() => setActive(false)} />
          </div>
          <div className="survey-content-managerment__item">
            <label htmlFor="">Intro En-ver</label>
            <textarea
              name="surveyIntro"
              id=""
              cols="200"
              rows="10"
              value={intro.surveyIntro}
              onChange={handleOnChange}
            ></textarea>
          </div>
          <div className="survey-content-managerment__item">
            <label htmlFor="">Intro No-ver</label>
            <textarea
              name="surveyIntroNo"
              id=""
              cols="200"
              rows="10"
              value={intro.surveyIntroNo}
              onChange={handleOnChange}
            ></textarea>
          </div>
        </div>
        <button className="custom-btn" onClick={handleSubmit}>
          {t('submit')}
        </button>
      </div>
    </div>
  );
};

export default SurveyContentManagerment;
