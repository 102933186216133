import CalendarPlanWishListSurvey from 'components/CalendarPlan/CalendarPlanEmployee/CalendarPlanWish/WishListSurvey/CalendarPlanWishListSurvey';
import React from 'react';

const WishListSurvey = () => {
  return (
    <div className="wish-list-page">
      <CalendarPlanWishListSurvey />
    </div>
  );
};

export default WishListSurvey;
