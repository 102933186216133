import React, { useEffect, useState } from 'react';
import CalendarStaff from '../CalendarStaff/CalendarStaff';
import KalenderPlanContent from '../KalenderPlan/KalenderPlanContent/KalenderPlanContent';
import CalendarWorkEnvironment from '../CalendarWorkEnvironment/CalendarWorkEnvironment';
import CalendarPlanSurvey from '../CalendarPlanSurvey/CalendarPlanSurvey';
import './CalendarPlanContent.scss';
import SoftRulesTab from '../SoftRulesTab/SoftRulesTab';

const renderContent = (arr, menuActive) => {
  let content = <></>;
  arr.forEach((item) => {
    if (item.key === menuActive) {
      content = item.component;
    }
  });
  return content;
};
export default function CalendarPlanContent({ menuActive, unitSelected }) {
  const contentArr = [
    {
      key: 'employee',
      component: <CalendarPlanSurvey unitSelected={unitSelected} />,
    },

    {
      key: 'working-environment-act',
      component: <CalendarWorkEnvironment unitSelected={unitSelected} />,
    },
    {
      key: 'soft-rules',
      component: <SoftRulesTab unitSelected={unitSelected} />,
    },
    { key: 'staff-plan', component: <CalendarStaff unitSelected={unitSelected} /> },
    {
      key: 'generator',
      component: <KalenderPlanContent unitSelected={unitSelected} />,
    },
  ];

  return <div className="calendar-plan-content__wrap">{renderContent(contentArr, menuActive)}</div>;
}
