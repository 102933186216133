import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import axiosClient from 'axios/axiosClient';
import { Toast } from 'helper/alert';
import { t } from 'i18next';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import CreateStaffPlanForm from '../CreateStaffPlanForm/CreateStaffPlanForm';
import './PlanListRow.scss';
import Loading from 'components/Loading/Loading';
export default function PlanListRow({ planDefault, isUpdated, setIsUpdated, unitSelected }) {
  const [plan, setPlan] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [planEdit, setPlanEdit] = useState({});
  const taskArrExist = [];

  const submitEdit = () => {
    if (!plan.isAvailable) {
      Toast.fire({ icon: 'warning', title: 'Plan is optimizing' });
      return;
    }
    let planDetail = {};
    setIsLoading(true);
    axiosClient.post('calendar-plan/staff-plan-detail', { planId: plan.uuid }).then((result) => {
      const planDetailFilter = result.filter((item) => item.plan_uuid === planDefault.uuid);
      planDetailFilter.forEach((plan) => {
        const taskExist = taskArrExist.find((task) => {
          return (
            task.uuid === plan.task_uuid &&
            task.fromTime === plan.from_time &&
            task.toTime === plan.to_time
          );
        });
        if (!taskExist) {
          taskArrExist.push({
            uuid: plan.task_uuid,
            fromTime: plan.from_time,
            toTime: plan.to_time,
            key: `${plan.task_uuid}-duplicate${Math.random(10)}`,
          });
        }
      });

      planDetailFilter.forEach((plan, index) => {
        const taskExist = taskArrExist.find((task) => {
          return (
            task.uuid === plan.task_uuid &&
            task.fromTime === plan.from_time &&
            task.toTime === plan.to_time
          );
        });
        planDetail = {
          ...planDetail,
          [taskExist.key]: {
            ...planDetail[taskExist.key],
            [plan.date]: { numberPerson: plan.number_person, uuid: plan.uuid },
            planDetailId: plan.uuid,
            from_time: plan.from_time,
            to_time: plan.to_time,
            uuid: plan.task_uuid,
            key: taskExist.key,
            group_id: plan.group_id,
            index,
          },
        };
      });
      const planUpdate = {
        task: planDetail,
        from: moment(plan.from_date).format('YYYY-MM-DD'),
        to: moment(plan.to_date).format('YYYY-MM-DD'),
        plan_name: plan.plan_name,
        plan_uuid: plan.uuid,
        plan_id: plan.id,
      };
      setIsLoading(false);

      setPlanEdit(planUpdate);
      setIsEdit(true);
    });
  };
  const handleDelete = () => {
    axiosClient
      .post('calendar-plan/staff-plan/delete', { planWillDelete: plan })
      .then((result) => {
        setIsUpdated(!isUpdated);
        Toast.fire({ icon: 'success', title: `${t('delete')} ${t('success')}` });
      })
      .catch((err) => {
        Toast.fire({ icon: 'error', title: `${t('delete')} ${t('unsuccess')}` });
      });
  };
  useEffect(() => {
    setPlan({ ...planDefault });
  }, [planDefault]);
  return (
    <>
      <tr className="plan-list-row">
        <td className="record-name">{plan?.plan_name}</td>
        <td>
          <div className="time__wrap">
            <span className="time">{moment(plan?.from_date).format('DD-MM-YYYY')}</span>
            <span className="time">{moment(plan?.to_date).format('DD-MM-YYYY')}</span>
          </div>
        </td>

        <td>
          <div className="calendar-staff-table__action">
            <EditOutlined onClick={submitEdit} />
            <DeleteOutlined onClick={handleDelete} />
          </div>
        </td>
      </tr>
      {isLoading ? (
        <div className="overlay">
          <Loading />
        </div>
      ) : null}
      {isEdit ? (
        <CreateStaffPlanForm
          setActiveCreateForm={setIsEdit}
          isUpdated={isUpdated}
          planTaskDefault={planEdit}
          setIsUpdated={setIsUpdated}
          unitSelected={unitSelected}
        />
      ) : (
        <></>
      )}
    </>
  );
}
