import MiniSwitch from 'components/MiniSwitch/MiniSwitch';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import AnswerItem from './AnswerItem';
import { Toast } from 'helper/alert';
import { LockOutlined, UnlockOutlined } from '@ant-design/icons';

const ExpanedQuestion = ({
  parentQuestionData,
  index,
  language,
  listExpandedQuestionActive,
  setListExpandedQuestionActive,
  surveyRecord,
  setSurveyRecord,
  handleOnChange,
  emp,
  listHardRuleQuestion,
  setListHardRuleQuestion,
}) => {
  const [activeQuestion, setActiveQuestion] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [questionData, setQuestionData] = useState({});
  const typeQuestionParent = parentQuestionData.parent_question_type;

  const parentAnswerType = {
    single: 'radio',
    text: 'text',
    multiple: 'checkbox',
    date: 'date',
    time: 'time',
  }[typeQuestionParent];
  useEffect(() => {
    const questionIndex = listHardRuleQuestion.findIndex(
      (item) => item.question_uuid === parentQuestionData.parent_question_id,
    );
    if (questionIndex > -1 && listHardRuleQuestion[questionIndex].is_hard) {
      setIsLocked(true);
    }
  }, [parentQuestionData, listHardRuleQuestion]);
  useEffect(() => {
    if (parentQuestionData && Object.keys(parentQuestionData).length) {
      const data = {
        questionContent:
          language === 'NO'
            ? parentQuestionData?.parent_question_no_ver
            : parentQuestionData?.parent_question_content,
        isMandatory: !!parentQuestionData?.parent_question_mandatory,
        description:
          language === 'NO'
            ? parentQuestionData.parent_question_description_no_ver
            : parentQuestionData.parent_question_description,
        answerList: parentQuestionData.parent_answer_no_ver,
        questionChoice: parentQuestionData.parent_answer_choice,
        questionType: parentAnswerType,
        child: parentQuestionData.child,
        parentId: parentQuestionData.parent_question_id,
        type: parentQuestionData.type,
      };

      setQuestionData(data);
    }
  }, [parentQuestionData, language]);

  useEffect(() => {
    const expanedQuestion = listExpandedQuestionActive.find(
      (question) => question.questionUUID === parentQuestionData.parent_question_id,
    );
    if (expanedQuestion) setActiveQuestion(!!expanedQuestion.isActive);
  }, [listExpandedQuestionActive]);

  const updateActiveQuestion = (isActive) => {
    setActiveQuestion(isActive);
    if (!isActive) {
      let updateValue = [...listHardRuleQuestion];

      const updateValueIndex = updateValue.findIndex(
        (item) => item.question_uuid === parentQuestionData.parent_question_id,
      );
      if (updateValueIndex > -1) {
        updateValue[updateValueIndex].isHard = 0;
        updateValue[updateValueIndex].is_hard = 0;
        setIsLocked(false);
        setListHardRuleQuestion(updateValue);
      }
    }
    const expanedQuestion = listExpandedQuestionActive.find(
      (question) => question.questionUUID === parentQuestionData.parent_question_id,
    );
    if (!expanedQuestion) {
      setListExpandedQuestionActive([
        ...listExpandedQuestionActive,
        {
          questionUUID: parentQuestionData.parent_question_id,
          questionContentEn: parentQuestionData.parent_question_content,
          questionContentNo: parentQuestionData.parent_question_no_ver,
          isActive,
        },
      ]);
    } else {
      setListExpandedQuestionActive((prevState) => {
        const updateValue = [...prevState];
        const expanedQuestionIndex = updateValue.findIndex(
          (question) => question.questionUUID === parentQuestionData.parent_question_id,
        );

        updateValue[expanedQuestionIndex] = { ...prevState[expanedQuestionIndex], isActive };
        return updateValue;
      });
    }
  };
  return (
    <>
      <div className="calendar-plan-survey-form__question-header">
        <h3 htmlFor="" className="calendar-plan-survey-form__question">
          {index}.{' '}
          {t(
            `${
              language === 'NO'
                ? parentQuestionData.parent_question_no_ver
                : parentQuestionData.parent_question_content
            }`,
          )}
          {activeQuestion ? (
            <span
              className={`toogle-rule ${isLocked && activeQuestion ? 'locked' : 'unlocked'}`}
              onClick={() => {
                let listHardRuleQuestionUpdate = listHardRuleQuestion;
                const foundQuestionIndex = listHardRuleQuestionUpdate.findIndex(
                  (item) => parentQuestionData.parent_question_id === item.question_uuid,
                );
                if (listHardRuleQuestionUpdate[foundQuestionIndex]?.always) {
                  Toast.fire({ icon: 'error', title: t('question-blocked') });
                  return;
                }
                if (isLocked && foundQuestionIndex > -1) {
                  listHardRuleQuestionUpdate[foundQuestionIndex].isHard = 0;

                  setIsLocked(false);
                } else if (foundQuestionIndex > -1) {
                  listHardRuleQuestionUpdate[foundQuestionIndex].isHard = 1;
                  setIsLocked(true);
                } else {
                  listHardRuleQuestionUpdate.push({
                    question_uuid: parentQuestionData.parent_question_id,
                    isHard: 1,
                  });
                  setIsLocked(true);
                }
                setListHardRuleQuestion(listHardRuleQuestionUpdate);
              }}
            >
              {isLocked && activeQuestion ? <LockOutlined /> : <UnlockOutlined />}
            </span>
          ) : null}
        </h3>

        <MiniSwitch
          obj1={{ value: true, name: t('on') }}
          obj2={{ value: false, name: t('off') }}
          width={100}
          height={30}
          value={activeQuestion}
          setValue={updateActiveQuestion}
        />
      </div>
      <div className="calendar-plan-survey-form__answer-group">
        {activeQuestion ? (
          <>
            {questionData?.answerList?.map((answer, index) => {
              const answerKeys = Object.keys(answer);
              return (
                <AnswerItem
                  key={answer[answerKeys[0]]}
                  parentAnswerType={parentAnswerType}
                  answer={answer}
                  answerKeys={answerKeys}
                  surveyRecord={surveyRecord}
                  questionData={questionData}
                  handleOnChange={handleOnChange}
                  index={index}
                  setSurveyRecord={setSurveyRecord}
                />
              );
            })}
          </>
        ) : null}
      </div>
    </>
  );
};

export default ExpanedQuestion;
