import axiosClient from 'axios/axiosClient';
import { t } from 'i18next';
import moment from 'moment';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import listTitleCheck from './kalendarPlanDashboardHeader';
import './KalendarPlanDashboard.scss';
import { enumerateDaysBetweenDates } from 'helper/enumerateDaysBetweenDates';
import { useSelector } from 'react-redux';
import { selectGlobal } from 'redux/global/globalSlice';

import SortCustom from './SortCustom/SortCustom';
import EmployeeRow from './EmployeeRow/EmployeeRow';
import MultipleTab from 'components/MultipleTab/MultipleTab';
export const weekdayKey = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

const RenderTitle = ({ titleList, language, activeTab }) => {
  return titleList.map((title) => {
    if (activeTab === 'soft' && title.level === 'soft')
      return (
        <th key={title.key} className={` sticky`}>
          <div className="dashboard-title">
            {t(title.key)}
            {title['no-description'] ? (
              <span className="dashboard-title__tooltip">
                {language === 'NO' ? title['no-description'] : title['en-description']}
              </span>
            ) : null}
          </div>
        </th>
      );
    if (activeTab === 'hard' && title.level === 'hard')
      return (
        <th key={title.key} className={` sticky`}>
          <div className="dashboard-title">
            {t(title.key)}
            {title['no-description'] ? (
              <span className="dashboard-title__tooltip">
                {language === 'NO' ? title['no-description'] : title['en-description']}
              </span>
            ) : null}
          </div>
        </th>
      );
    if (activeTab === 'survey' && title.level === 'survey')
      return (
        <th key={title.key} className={` sticky`}>
          <div className="dashboard-title">
            {t(title.key)}{' '}
            {title['no-description'] ? (
              <span className="dashboard-title__tooltip">
                {language === 'NO' ? title['no-description'] : title['en-description']}
              </span>
            ) : null}
          </div>
        </th>
      );
  });
};
export default function KalendarPlanDashboard({
  planSelected,
  unitSelected,
  publicHoliday,
  sortObj,
  setSortObj,
  weekSelect,
}) {
  const fromDate = moment(planSelected.from_date).format('YYYY-MM-DD');
  const endDate = moment(planSelected.to_date).format('YYYY-MM-DD');
  const noRef = useRef(null);
  const [leftName, setLeftName] = useState(51);
  const [employAssigneData, setEmployAssigneData] = useState({});
  const [employSurveyCheckList, setEmploySurveyCheckList] = useState([]);
  const [employeeUnAssigneData, setEmployeeUnAssigneData] = useState({});
  const [holidayAndSundayRange, setHolidayAndSundayRange] = useState([]);
  const [maxDate, setMaxDate] = useState(endDate);
  const [activeTab, setActiveTab] = useState('');

  const { language } = useSelector(selectGlobal);

  useEffect(() => {
    axiosClient
      .post('calendar-plan/kalender/dashboard/survey', {
        unitCode: unitSelected,
        planId: planSelected.id,
      })
      .then((res) => {
        if (res) {
          setEmploySurveyCheckList(res);
        }
      });
  }, [unitSelected]);
  useEffect(() => {
    const listAllDateInRange = enumerateDaysBetweenDates(fromDate, endDate);

    const holidayAndSundayRangeSet = new Set();
    for (const date of listAllDateInRange) {
      if (moment(date).weekday() === 0) {
        holidayAndSundayRangeSet.add(date);
      }
    }
    for (const date of publicHoliday) {
      if (listAllDateInRange.includes(date)) {
        holidayAndSundayRangeSet.add(date);
      }
    }

    setHolidayAndSundayRange(Array.from(holidayAndSundayRangeSet));
  }, [employAssigneData, unitSelected]);

  useEffect(() => {
    axiosClient
      .post('calendar-plan/kalender/dashboard', {
        unitCode: unitSelected,
        planId: planSelected.id,
        fromDate,
        endDate,
        sortObj,
      })
      .then((res) => {
        setEmployAssigneData(res);
      }),
      axiosClient
        .post('calendar-plan/kalender/dashboard-vakant', {
          unitCode: unitSelected,
          planId: planSelected.id,
          fromDate,
          endDate,
        })
        .then((res) => {
          setEmployeeUnAssigneData(res);
        });
    axiosClient
      .post('calendar-plan/kalender/get-max-date', { planId: planSelected.id })
      .then((res) => {
        if (res) {
          setMaxDate(res.endDate);
        }
      });
  }, [planSelected.id, unitSelected, sortObj.name, sortObj.role, sortObj.jobPercentage]);
  useEffect(() => {
    const distance = noRef.current?.offsetWidth - 2;
    setLeftName(distance);
  }, [
    noRef.current?.offsetWidth,
    employAssigneData,
    language,
    employeeUnAssigneData,
    planSelected,
  ]);
  const tabs = [{ label: 'hard' }, { label: 'soft' }, { label: 'survey' }];
  return (
    <>
      {Object.keys(employAssigneData)?.length && employAssigneData.employeeData.length ? (
        <Fragment>
          <div className="kalendar-plan-dashboard__feature">
            <MultipleTab objs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
          </div>
          <div className="kalendar-plan-dashboard__wrap">
            <table className="kalendar-plan-dashboard__table">
              <thead>
                <th className="sticky no" ref={noRef}>
                  {t('no-dashboard')}
                </th>
                <th className="sticky name" style={{ left: leftName }}>
                  <div className="sort-wrap">
                    <span>{t('emp-name')}</span>
                    <SortCustom value={sortObj} sortKey={'name'} setValue={setSortObj} />
                  </div>
                </th>

                <th className={` sticky`}>
                  <div className="sort-wrap">
                    <span> {t('role')} </span>
                    <SortCustom value={sortObj} sortKey={'role'} setValue={setSortObj} />
                  </div>
                </th>
                <th className={` sticky`}>{t('role-matched/role-not-match')}</th>

                <th className={` sticky `}>
                  <div className="sort-wrap">
                    <span>{t('job-percentage')}</span>
                    <span className="sort-icon">
                      <SortCustom value={sortObj} sortKey={'jobPercentage'} setValue={setSortObj} />
                    </span>
                  </div>
                </th>
                <th className={`sticky`}>{t('allocated-hours')}</th>
                <th className={`sticky`}>{t('deviation')}</th>

                {activeTab === 'soft' ? (
                  <Fragment>
                    <th className={`sticky`}>{t('weekend-hours')}</th>
                    <th className={`sticky`}>{t('day-shift')}</th>
                    <th className={`sticky`}>{t('evening-shift')}</th>
                    <th className={`sticky`}>{t('night-shift')}</th>
                    <th className={`sticky`}>{t('long-shift')}</th>
                    <th className={`sticky`}>{t('other')}</th>
                  </Fragment>
                ) : null}
                {activeTab === 'hard' ? (
                  <Fragment>
                    <th className={`sticky`}>{t('day-off')}</th>
                    <th className={`sticky`}>{t('holiday')}</th>
                  </Fragment>
                ) : null}

                <RenderTitle titleList={listTitleCheck} activeTab={activeTab} language={language} />
              </thead>
              <tbody>
                <EmployeeRow
                  data={employAssigneData}
                  employSurveyCheckList={employSurveyCheckList}
                  planSelected={planSelected}
                  leftName={leftName}
                  totalHolidayAndSun={holidayAndSundayRange}
                  roleChecking={employAssigneData.checkingEmpRole}
                  weekSelect={weekSelect}
                  fromDate={fromDate}
                  endDate={maxDate}
                  activeTab={activeTab}
                />
                {activeTab !== 'survey' ? (
                  <EmployeeRow
                    data={employeeUnAssigneData}
                    planSelected={planSelected}
                    leftName={leftName}
                    isUnassigned={true}
                    totalAssginedEmp={employAssigneData.employeeData.length}
                    totalHolidayAndSun={holidayAndSundayRange}
                    weekSelect={weekSelect}
                    fromDate={fromDate}
                    endDate={maxDate}
                    employSurveyCheckList={[]}
                    activeTab={activeTab}
                  />
                ) : null}
              </tbody>
            </table>
          </div>
          <div className="table-legend">
            <div className="legend-group">
              <label htmlFor="">{t('passed')}</label>
              <div className="status "></div>
            </div>
            <div className="legend-group">
              <label htmlFor="">{t('wrong')}</label>
              <div className="status wrong"></div>
            </div>
            <div className="legend-group">
              <label htmlFor="">{t('warning')}</label>
              <div className="status warning"></div>
            </div>
            <div className="legend-group">
              <label htmlFor="">{t('not-active')}</label>
              <div className="status not-active"></div>
            </div>
          </div>
        </Fragment>
      ) : null}
    </>
  );
}
