import { createSlice } from '@reduxjs/toolkit';
import { loadState } from 'helper/localStorage';

export const globalSlice = createSlice({
  name: 'global',
  initialState: {
    theme: loadState('theme') || 'dark',
    language: loadState('language') || 'NO',
    role: loadState('role') || 'planner',
    departmentSelectedArr: [],
    unitSelectedArr: [],
    dayNightArr: [],
    departmentNameActive: [],
    unitNameActive: [],
    minDate: '2023-01-01',
    planPatinesTask: {},
  },
  reducers: {
    changeTheme(state, action) {
      state.theme = action.payload.theme;
    },

    changeLang(state, action) {
      state.language = action.payload.language;
    },

    selectDepartment(state, action) {
      const payloadUnit = action.payload.unitCodes;
      const payloadDepartment = action.payload.departmentSelected;
      if (action.payload.ignoreUnitDay) {
        state.departmentSelectedArr = [payloadDepartment];
      } else {
        state.departmentSelectedArr.push(payloadDepartment);
      }
      state.departmentNameActive.push(action.payload.departmentName);
      if (payloadUnit) {
        state.unitSelectedArr = [...state.unitSelectedArr, ...payloadUnit];
      }
    },

    unSelectDepartment(state, action) {
      const payloadUnit = action.payload.unitCodes;
      const payloadDepartment = action.payload.departmentSelected;

      state.departmentSelectedArr = state.departmentSelectedArr.filter(
        (item) => item !== payloadDepartment,
      );
      state.departmentNameActive = [...state.departmentNameActive].filter(
        (item) => item != action.payload.departmentName,
      );

      if (payloadUnit) {
        state.unitSelectedArr = state.unitSelectedArr.filter((item) => !payloadUnit.includes(item));
      }
    },

    selectUnit(state, action) {
      const payloadUnit = action.payload.unitSelected;
      const payloadDepartment = action.payload.departmentCode;
      state.unitSelectedArr = [...state.unitSelectedArr, +payloadUnit];
      state.unitNameActive = [...state.unitNameActive, action.payload.unitName];
      if (!state.departmentSelectedArr.includes(payloadDepartment)) {
        state.departmentSelectedArr = [...state.departmentSelectedArr, payloadDepartment];
      }
    },

    unSelectUnit(state, action) {
      const payloadUnit = action.payload.unitSelected;
      const totalUnitOfDepartment = action.payload.totalUnitOfDepartment;
      const payloadDepartment = action.payload.departmentSelected;
      state.unitSelectedArr = state.unitSelectedArr.filter((item) => item != payloadUnit);
      state.unitNameActive = state.unitNameActive.filter((item) => item != action.payload.unitName);

      if (totalUnitOfDepartment.every((item) => !state.unitSelectedArr.includes(item))) {
        state.departmentSelectedArr = state.departmentSelectedArr.filter(
          (item) => item !== payloadDepartment,
        );
      }
    },
    selectDayNight(state, action) {
      const payloadDayNight = action.payload.dayNightSelected;
      if (!state.dayNightArr.includes(payloadDayNight)) {
        state.dayNightArr = [...state.dayNightArr, payloadDayNight];
      } else {
        state.dayNightArr = state.dayNightArr.filter((item) => item != payloadDayNight);
      }
    },
    getMinDate(state, action) {
      const minDate = action.payload.minDate;
      state.minDate = minDate;
    },
  },
});

export const selectGlobal = (state) => state.global;
export const {
  changeTheme,
  changeLang,
  selectDepartment,
  selectUnit,
  unSelectDepartment,
  unSelectUnit,
  selectDayNight,
  getMinDate,
} = globalSlice.actions;
export default globalSlice.reducer;
