import React, { useRef, useState } from 'react';
import './UploadFileForm.scss';
import { t } from 'i18next';
import { CloseOutlined } from '@ant-design/icons';
import moment from 'moment';
import axiosClient from 'axios/axiosClient';
import { Toast } from 'helper/alert';

const UploadInput = ({
  type,
  uploadFileRef,
  fileName,
  isUploading,
  setIsUploading,
  setFileName,
  handleGetIdSheet,
  handleFileChange,
  planInfo,
  unitSelected,
  setActive,
  setIsUpdated,
  isUpdated,
  handleOnChange,
}) => {
  if (type === 'file') {
    return (
      <div className="upload-file-form__btn">
        <span className="file-name">{fileName}</span>
        <button
          onClick={() => {
            uploadFileRef.current.click();
          }}
          className={`custom-btn`}
          disabled={isUploading}
        >
          {t('upload-new-plan')}
        </button>
        <input
          ref={uploadFileRef}
          type="file"
          name=""
          accept=".xlsx, .xls"
          id=""
          className="hide-element"
          onChange={(e) => {
            const file = e.target.files[0];
            const fileName = file.name || '';
            setIsUploading(true);
            setFileName(fileName);
            handleFileChange(e, planInfo, setIsUploading);
          }}
        />
      </div>
    );
  } else {
    const handlSubmit = () => {
      if (planInfo.googleSheetId) {
        setIsUploading(true);

        axiosClient
          .post('/calendar-plan/kalender/upload-sheet-link', {
            planName: planInfo.planName,
            fileName: planInfo.planName,
            fromDate: planInfo.from,
            endDate: planInfo.to,
            googleSheetId: planInfo.googleSheetId,
            unitCode: unitSelected,
            fileType: planInfo.fileType,
          })
          .then((result) => {
            setActive(false);
            setIsUpdated(!isUpdated);
            setIsUploading(false);

            Toast.fire({ icon: 'success', title: `${t('submit')} ${t('success')}` });
            if (result.status === 400) {
              Toast.fire({ icon: 'error', title: `${t('submit')} ${t('fail')}` });
            }
          })
          .catch((err) => {
            Toast.fire({ icon: 'error', title: `${t('submit')} ${t('fail')}` });
            setIsUploading(false);
          });
      } else {
        Toast.fire({ icon: 'error', title: `${t('submit')} ${t('fail')}` });
      }
    };
    return (
      <div>
        <div className="upload-file-form-group link__wrap ">
          <div className="upload-file-form-group link">
            <label htmlFor="end-date"> Google Sheet {t('link')}</label>
            <input type="text" name="googleSheetId" onChange={handleGetIdSheet} />
          </div>
          <div className="upload-file-form-group link">
            <label htmlFor="end-date"> {t('file-type')}</label>
            <select name="fileType" id="" onChange={handleOnChange}>
              <option value="">{t(`no-group`)}</option>
              <option value="group">{t('have-group')}</option>
            </select>
          </div>

          <div className="submit">
            <button className="custom-btn" disabled={isUploading} onClick={handlSubmit}>
              {t('send')}
            </button>
          </div>
        </div>
      </div>
    );
  }
};
const UploadFileForm = ({
  active,
  setActive,
  handleFileChange,
  unitSelected,
  setIsUpdated,
  isUpdated,
}) => {
  const [planInfo, setPlanInfo] = useState({ importType: 'file' });
  const [fileName, setFileName] = useState();
  const [isUploading, setIsUploading] = useState(false);
  const uploadFileRef = useRef(null);
  const handleOnChange = (e) => {
    const { name, value, type } = e.target;

    setPlanInfo({
      ...planInfo,
      [name]: value,
    });
  };
  const handleGetIdSheet = (e) => {
    const { value, name } = e.target;
    const sheetIdReg = /\/d\/([a-zA-Z0-9_-]+)\//;
    if (value && value.match(sheetIdReg)) {
      setPlanInfo({
        ...planInfo,
        [name]: value.match(sheetIdReg)[1],
      });
    }
  };
  return (
    <div className="overlay">
      <div className="upload-file-form__wrap">
        <div className="upload-file-form__content">
          <div className="upload-file-form__close">
            <button disabled={isUploading}>
              <CloseOutlined
                onClick={() => {
                  setActive(false);
                }}
              />
            </button>
          </div>

          <form className="upload-file-form" onSubmit={(e) => e.preventDefault()}>
            <div className="upload-file-form-group">
              <label htmlFor="planName">{t('plan-name')}</label>
              <input type="text" name="planName" onChange={handleOnChange} />
            </div>
            <div className="date-group">
              <div className="upload-file-form-group">
                <label htmlFor="from-date">{t('from-date')}</label>
                <input type="date" name="from" onChange={handleOnChange} />
              </div>
              <div className="upload-file-form-group">
                <label htmlFor="end-date">{t('to-date')}</label>
                <input type="date" name="to" onChange={handleOnChange} />
              </div>
            </div>
            <div className="upload-file-form-group">
              <label htmlFor="">{t('import-type')}</label>
              <select name="importType" id="" onChange={handleOnChange}>
                <option value="file">File</option>
                <option value="googleSheetLink">Google Sheet link</option>
              </select>
            </div>
          </form>

          {Object.keys(planInfo).length > 3 ? (
            <UploadInput
              type={planInfo.importType}
              handleFileChange={handleFileChange}
              handleGetIdSheet={handleGetIdSheet}
              handleOnChange={handleOnChange}
              uploadFileRef={uploadFileRef}
              fileName={fileName}
              isUploading={isUploading}
              setIsUploading={setIsUploading}
              setFileName={setFileName}
              planInfo={planInfo}
              unitSelected={unitSelected}
              setActive={setActive}
              setIsUpdated={setIsUpdated}
              isUpdated={isUpdated}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default UploadFileForm;
