import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import CalendarPlanWishListTable from 'components/CalendarPlan/CalendarPlanWishList/CalendarPlanWishListTable/CalendarPlanWishListTable';
import axiosClient from 'axios/axiosClient';
import { useParams } from 'react-router-dom';

import './CalendarPlanWishListSurvey.scss';
import { useDispatch, useSelector } from 'react-redux';
import { clearWishList, initWishList } from 'redux/calendarPlan/calendarPlanSlice';
import { Toast } from 'helper/alert';

const CalendarPlanWishListSurvey = () => {
  const dispatch = useDispatch();
  const { wishList: savedData } = useSelector((state) => state.calendarPlan);

  const [isCompleted, setIsCompleted] = useState(false);
  const [empl, setEmpl] = useState(null);
  const [data, setData] = useState([]);
  const [taskArr, setTaskArr] = useState([]);

  const { empId, unitCode, wishListId } = useParams();

  const handleSave = () => {
    axiosClient
      .post('calendar-plan/wish-list/update', {
        fileData: savedData,
        unitCode: unitCode,
        wishListId: wishListId,
      })
      .then((result) => {
        setIsCompleted(true);
        dispatch(clearWishList());
        Toast.fire({ icon: 'success', title: `${t('send-survey')} ${t('success')}` });
      });
  };

  useEffect(() => {
    axiosClient
      .post('calendar-plan/empl-info', {
        unitCode: unitCode,
        empId: empId,
      })
      .then((result) => setEmpl(result));
  }, []);

  useEffect(() => {
    axiosClient
      .post('calendar-plan/wish-list/survey', {
        unitCode: unitCode,
        wishListId,
        name: empl?.name,
      })
      .then((result) => {
        setData(result.data);
      });
  }, [empl, isCompleted]);

  useEffect(() => {
    dispatch(
      initWishList({
        data,
      }),
    );
  }, [data]);

  return (
    <div className="wish-list-survey">
      <div className="wish-list-survey__wrap">
        <h1>{t('survey')}</h1>
        {isCompleted ? (
          <div className="topic__wrap complete-survey">
            <h1>{t('thanks-for-survey')}</h1>
            <span>
              {t('thanks-description')} {new Date().toLocaleString()}
            </span>
            <div className="complete-survey__footer">
              <button
                type="button"
                className="custom-btn"
                onClick={(e) => {
                  e.preventDefault();

                  setIsCompleted(false);
                }}
              >
                {t('return-survey')}
              </button>
            </div>
          </div>
        ) : (
          <div className="wish-list-survey__content">
            <CalendarPlanWishListTable dataTable={data} />
            <div className="submit-btn">
              <button className="custom-btn" onClick={handleSave}>
                {t('submit')}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CalendarPlanWishListSurvey;
