import React, { useState } from 'react';
import './OptimizeProcessDashBoard.scss';
import { CloseOutlined, TableOutlined } from '@ant-design/icons';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import { t } from 'i18next';

const RenderProgress = ({ data }) => {
  return data.map((item) => {
    return (
      <div className="optimize-process-dashboard__item" key={`${item.id}-${item.planName}`}>
        <div className="optimize-process-dashboard__item-title">- {item.planName}</div>
        <div className="optimize-process-dashboard__item-progress">
          <ProgressBar value={item.process} /> <span className="percent">{item.process} %</span>
        </div>
        <div className="optimize-process-dashboard__item-status">
          {t(item.statusKey)} <span className="loading">...</span>{' '}
        </div>
      </div>
    );
  });
};

const OptimizeProcessDashBoard = ({ data }) => {
  return (
    <div className="optimize-process-dashboard__wrap">
      <div className={`optimize-process-dashboard__content`}>
        <RenderProgress data={data} />
      </div>
    </div>
  );
};

export default OptimizeProcessDashBoard;
