import { CloseOutlined } from '@ant-design/icons';
import moment from 'moment';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCalendarPlan } from 'redux/calendarPlan/calendarPlanSlice';
import CalendarPlanFormTableCell from '../CalendarPlanFormTableCell/CalendarPlanFormTableCell';

function CalendarPlanFormTableRow({
  rowData,
  dateRange,
  handleOnChangePlanDetail,
  handleOnChangeAllValueEachTask,
  handleOnChangeTime,
  handleDeleteRow,
  handlePasteWeekStaffPlanDetail,
  tab,
  listGroup,
}) {
  const [allValue, setAllValue] = useState();
  const [data, setData] = useState({});
  const [cellData, setCellData] = useState([]);
  const dateRegExp = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
  const { planStaffTask, planPatinesTask } = useSelector(selectCalendarPlan);

  const RenderCell = useCallback(
    ({ cellData, dateRange }) => {
      return dateRange.map((date) => {
        const cell = cellData.find((el) => el.date === date);
        if (cell) {
          return (
            <CalendarPlanFormTableCell
              key={`${date}-${rowData.key}`}
              data={cell.value.numberPerson}
              date={date}
              dateRange={dateRange}
              taskUUID={rowData.key}
              // handleOnChangeNextValue={handleOnChangeNextValue}
              handleOnChangePlanDetail={handleOnChangePlanDetail}
            />
          );
        }
        return (
          <CalendarPlanFormTableCell
            key={`${date}-${rowData.key}`}
            data={''}
            date={date}
            taskUUID={rowData.key}
            // handleOnChangeNextValue={handleOnChangeNextValue}
            handleOnChangePlanDetail={handleOnChangePlanDetail}
          />
        );
      });
    },
    [cellData],
  );
  const handleChangeTime = (e) => {
    const key = e.target.name;
    const value = `${e.target.value}:00`;
    const newRowData = { ...data, [key]: value };
    setData({ ...data, [key]: e.target.value });

    handleOnChangeTime(newRowData);
  };
  const handleChangeAllValue = (e) => {
    setAllValue(e.target.value);
    const cellDataUpdate = cellData.map((item) => ({
      ...item,
      value: { ...item['value'], numberPerson: e.target.value },
    }));
    setCellData(cellDataUpdate);
    const newRowData = { ...data };
    for (const data of cellDataUpdate) {
      newRowData[data.date] = data.value;
    }

    handleOnChangeAllValueEachTask(newRowData);
  };

  const handleDelete = (e) => {
    handleDeleteRow(data);
  };
  useEffect(() => {
    if (dateRange && rowData) {
      let cellListData = dateRange.map((date) => {
        return { date, value: 0 };
      });
      let defaultData = [];
      if (tab === 'staff') {
        defaultData = Object.entries(planStaffTask.task[rowData.key]).map((data) => {
          if (data) {
            if (dateRegExp.test(data[0])) {
              return { date: data[0], value: data[1] };
            }
          }
        });
      } else {
        defaultData = Object.entries(planPatinesTask.task[rowData.key]).map((data) => {
          if (data) {
            if (dateRegExp.test(data[0])) {
              return { date: data[0], value: data[1] };
            }
          }
        });
      }

      defaultData = defaultData.filter((data) => data != undefined);
      for (let init of cellListData) {
        for (let data of defaultData) {
          if (init.date === data.date) {
            init.value = data.value;
          }
        }
      }
      setCellData(cellListData);

      const groupFound = listGroup.find((group) => group.id === rowData.group_id);
      if (groupFound) rowData['group'] = groupFound.name;
      if (groupFound) rowData['groupKey'] = groupFound.key;
      setData(rowData);
    }
  }, [rowData, dateRange]);
  return (
    <tr>
      <td className="plan-task-table-name stick left">
        <div className="plan-task-table-name__wrap">
          <CloseOutlined onClick={handleDelete} />
          <p>
            {data?.taskName}{' '}
            <span className="role">
              {data?.role ? (
                <span style={{ fontSize: '12px' }}>- {data?.roleKey || data?.role}</span>
              ) : (
                ''
              )}
            </span>{' '}
            <span className="group">
              {data?.groupKey ? (
                <span style={{ fontSize: '12px' }}>- {data?.groupKey || data?.group}</span>
              ) : (
                ''
              )}
            </span>
          </p>
          <div className="fill-all__wrap">
            <input
              value={allValue}
              type="number"
              placeholder="All"
              onChange={handleChangeAllValue}
            />
          </div>
        </div>
      </td>
      <td className="plan-task-table-time__wrap">
        <div className="plan-task-table-time">
          <input
            className="plan-task-table-time__item-input"
            type="time"
            name="from_time"
            value={data?.from_time}
            onChange={handleChangeTime}
          />
          -
          <input
            className="plan-task-table-time__item-input"
            type="time"
            name="to_time"
            value={data?.to_time}
            onChange={handleChangeTime}
          />
        </div>
      </td>
      {cellData.length && dateRange ? (
        <RenderCell cellData={cellData} dateRange={dateRange} />
      ) : null}
    </tr>
  );
}
export default memo(CalendarPlanFormTableRow);
