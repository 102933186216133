import axiosClient from 'axios/axiosClient';
import CalendarFilter from 'components/CalendarPlan/CalendarFilter/CalendarFilter';
import CalendarPlanAdminSideTab from 'components/CalendarPlan/CalendarPlanAdminHeader/CalendarPlanAdminHeader';
import CalendarPlanContent from 'components/CalendarPlan/CalendarPlanContent/CalendarPlanContent';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import './style.scss';
export default function CalendarPlan() {
  const [menuActive, setMenuActive] = useState('employee');
  const [departments, setDepartments] = useState([]);
  const [departmentSelected, setDepartmentSelected] = useState();
  const [unitSelected, setUnitSelected] = useState();
  const [activeFilter, setActiveFilter] = useState(false);

  const locale = useLocation();
  useEffect(() => {
    axiosClient.post('calendar-plan/department').then((result) => {
      if (result) {
        setDepartmentSelected(result[0].departmentCode);
        setUnitSelected(result[0].unit[0].unitCode);
        setDepartments(result);
      }
    });
  }, [locale.key]);
  return (
    <div className="calendar-plan__wrap">
      <CalendarFilter
        departments={departments}
        departmentSelected={departmentSelected}
        setDepartmentSelected={setDepartmentSelected}
        setActive={setActiveFilter}
        active={activeFilter}
        unitSelected={unitSelected}
        setUnitSelected={setUnitSelected}
      />
      <div className={`calendar-plan__content ${activeFilter ? 'active' : ''}`}>
        <CalendarPlanAdminSideTab menuActive={menuActive} setMenuActive={setMenuActive} />
        {unitSelected ? (
          <CalendarPlanContent
            menuActive={menuActive}
            departments={departments}
            unitSelected={unitSelected}
          />
        ) : null}
      </div>
    </div>
  );
}
