import { t } from 'i18next';
import React, { useEffect, useState } from 'react';

import { Toast } from 'helper/alert';
import axiosClient from 'axios/axiosClient';
import { result, debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import './CalendarWorkEnvironment.scss';

const CalendarWorkEnvironment = ({ unitSelected }) => {
  const { i18n } = useTranslation();
  const [currentDepartment, setCurrentDepartment] = useState(unitSelected);
  const [updatedData, setUpdateData] = useState({});
  const [questions, setQuestions] = useState([]);
  const [anwsers, setAnwsers] = useState([]);

  const handleSave = () => {
    const resultIds = Object.keys(updatedData);
    const answersRecord = resultIds.map((key) => {
      return {
        questionUUID: key,
        answer: updatedData[key],
        unitCode: unitSelected,
      };
    });

    axiosClient
      .post('calendar-plan/aml/update', { answersRecord })
      .then((result) => {
        Toast.fire({ icon: 'success', title: t('save') });
      })
      .catch((err) => {
        Toast.fire({ icon: 'error', title: `${t('save')} ${t('fail')}` });
      });
  };
  const handleUpdate = (e) => {
    const { name, value } = e.target;
    const questionFound = questions.find((question) => question.uuid === name);
    if (value !== '') {
      setUpdateData({ ...updatedData, [name]: { value, questionId: questionFound.id } });
    } else {
      setUpdateData({
        ...updatedData,
        questionId: questionFound.id,
        [name]: { value: null, questionId: questionFound.id },
      });
    }
  };

  useEffect(() => {
    axiosClient.post('calendar-plan/aml/questions').then((result) => {
      setQuestions(result.data);
    });
  }, []);

  useEffect(() => {
    axiosClient.post('calendar-plan/aml/anwsers', { unitCode: unitSelected }).then((result) => {
      setUpdateData(result.data);
    });

    setCurrentDepartment(unitSelected);
  }, [unitSelected]);

  return (
    <div className="calendar-work-enviroment">
      <div className="calendar-work-enviroment__wrap">
        <div className="policies">
          <table className="calendar-plan-employee__table" id="employee-table">
            <thead>
              <th>{t('no')}</th>
              <th>{t('Question')}</th>
              <th className="employee-table__value">{t('Answer')}</th>
            </thead>
            <tbody>
              {questions.map((question, index) => {
                return (
                  <tr key={question.uuid}>
                    <td>{index + 1}</td>
                    <td>{i18n.language === 'NO' ? question.question_no : question.question}</td>
                    <td className="number-input">
                      <input
                        type="number"
                        step={0.01}
                        className="policy-input"
                        value={
                          updatedData?.[question.uuid] ? updatedData?.[question.uuid]['value'] : ''
                        }
                        name={question.uuid}
                        onChange={handleUpdate}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="submit-btn">
        <button type="button" onClick={handleSave} className="custom-btn">
          {t('save')}
        </button>
      </div>
    </div>
  );
};

export default CalendarWorkEnvironment;
