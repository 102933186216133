import moment from 'moment';
import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCalendarPlan } from 'redux/calendarPlan/calendarPlanSlice';

function CalendarPlanFormTableCell({
  data,
  date,
  taskUUID,
  handleOnChangePlanDetail,
  dateRange,
  handleOnChangeNextValue,
}) {
  const [cellData, setCellData] = useState();

  useEffect(() => {
    setCellData(data);
  }, [data]);

  return (
    <td className="plan-task-table__number">
      <div className="plan-task-table__item">
        <input
          // onPaste={(e) => {
          //   handleOnChangeNextValue(date, dateRange);
          // }}
          className="number-record"
          value={cellData || ''}
          onChange={(e) => {
            setCellData(e.target.value);

            handleOnChangePlanDetail(e.target.name, e.target.value, taskUUID);
          }}
          name={date}
        />
      </div>
    </td>
  );
}

export default memo(CalendarPlanFormTableCell);
